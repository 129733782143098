import React, {
    createContext,
    useState,
    useContext,
    useReducer,
    Dispatch,
    ChangeEvent,
    useEffect,
} from 'react';
import {
    CPTReportDTO,
    createCPTReport,
    getBenefitsFromClinicalIndication,
    getCPTReportById,
    getRiskFromDetails,
    updateCPTReport,
} from '../services/api/cptService';
import {
    getNestedValue,
    getUpdatedListAndText,
    getUpdatedText,
    setNestedValue,
} from '../services/cptAutofillService';
import AuthContext from './auth-context';
import { getAllInternalProtocols } from '../services/apiService';

type UpdateAction = {
    type: 'UPDATE' | 'RESET' | 'BULK_UPDATE' | 'SET';
    name?: string;
    value?: any;
    payload?: any;
    index?: number;
};

export type ProgressStatus = 'Not Started' | 'In Progress' | 'Finished';

type CPTReportContextType = {
    report: CPTReportDTO;
    dispatchReport: Dispatch<UpdateAction>;
    isLoading: boolean;
    setIsLoading: Dispatch<boolean>;
    error: { [key: string]: string } | null;
    dispatchError: Dispatch<any>;
    isReportModuleOpen: boolean;
    setReportModuleOpen: Dispatch<boolean>;
    getHandleEventChange: (
        field: string
    ) => (
        e: ChangeEvent<
            HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
    ) => void;
    getHandleValueChange: (field: string) => (value: any) => void;
    getHandleSelectChange: (
        field: string,
        genType: 'single' | 'multi',
        genField?: string,
        isRichText?: boolean
    ) => (value: any) => void;
    progress: { [key: string]: ProgressStatus };
    setProgress: Dispatch<{ [key: string]: ProgressStatus }>;
    saveCurrentReport: () => void;
    initNewReport: () => void;
    setReportById: (id: string) => void;
    viewState: string;
    setViewState: (s: string) => void;
    SOPs: any[];
    fetchUserInfo: (isReset?: boolean) => Promise<void>;
    fetchBenefits: () => Promise<void>;
    fetchRisk: () => Promise<void>;
    canFetchRisk: () => boolean;
    canFetchBenefits: () => boolean;
    isFetchingRisk: boolean;
    isFetchingBenefits: boolean;
};

const initialReport: CPTReportDTO = {
    status: 'In Progress',
    info: {
        name: '',
        facilityName: '',
        dateOfAssessment: new Date(),
        dateOfService: new Date(),
        purpose: '',
        sources: [],
        notes: '',
    },
    safetyWork: {
        clinicalIndication: '',
        purpose: '',
        objectives: '',
    },
    mriSafetyDecisionInfo: {
        implantDetails: {
            model: '',
            catalogNumber: '',
            type: '',
            manufacturer: '',
        },
        foreignBodyDetails: {
            type: '',
            location: '',
            description: '',
            material: '',
            dimensions: '',
            hazards: [],
            hazardExplanation: '',
        },
        safetyAnalysis: {
            risk: '',
            mitigation: '',
            limitSar: '',
            sequenceMod: '',
            additionalMod: [],
            staffNeeded: [],
        },
    },
    conditionalsAnalysis: {
        mriStatus: '',
        restrictions: '',
        riskLevel: '',
        benefits: {
            benefits: '',
            conclusion: '',
            explanation: '',
        },
    },
    guidance: {
        sopName: '',
        sopInfo: {
            Title: '',
            Author: '',
            Date: null,
        },
        sources: [],
        explanation: '',
        recommendation: '',
        notes: '',
    },
    scannerSettings: {
        scannerModel: '',
        technique: '',
        patientPosition: '',
    },
    summary: {
        codes: [],
        totalTimeCPTCodes: 0,
        notes: '',
    },
    timeLog: [],
    department_id: '',
};

const defaultContextValue: CPTReportContextType = {
    report: structuredClone(initialReport),
    dispatchReport: () => {},
    isLoading: false,
    setIsLoading: () => {},
    error: null,
    dispatchError: () => {},
    isReportModuleOpen: false,
    setReportModuleOpen: () => {},
    getHandleEventChange: () => () => {},
    getHandleValueChange: () => () => {},
    getHandleSelectChange: () => () => {},
    progress: {
        76014: 'Not Started',
        76015: 'Not Started',
        76016: 'Not Started',
        76017: 'Not Started',
        76018: 'Not Started',
        76019: 'Not Started',
    },
    setProgress: () => {},
    saveCurrentReport: () => {},
    initNewReport: () => {},
    setReportById: () => {},
    viewState: 'new',
    setViewState: () => {},
    SOPs: [],
    fetchUserInfo: async () => {},
    fetchBenefits: async () => {},
    fetchRisk: async () => {},
    canFetchRisk: () => false,
    canFetchBenefits: () => false,
    isFetchingRisk: false,
    isFetchingBenefits: false,
};

export const reportReducer = (state: CPTReportDTO, action: UpdateAction) => {
    switch (action.type) {
        case 'UPDATE':
            const { name, value } = action.payload;
            const updatedReports = { ...state };
            setNestedValue(updatedReports, name, value);
            return updatedReports;
        case 'BULK_UPDATE':
            const bulkUpdatedReports = { ...state, ...action.payload };
            return bulkUpdatedReports;
        case 'RESET':
            return structuredClone(initialReport);
        case 'SET':
            return { ...action.payload };
        default:
            return state;
    }
};

export const errorReducer = (
    state: { [key: string]: string } | null,
    action: {
        type: 'SET_ERROR' | 'CLEAR_ERROR';
        payload?: { name: string; message?: string };
    }
) => {
    switch (action.type) {
        case 'SET_ERROR':
            return {
                ...state,
                [action.payload?.name]: action.payload?.message,
            };
        case 'CLEAR_ERROR':
            delete state[action.payload?.name];
            return state;
        default:
            return state;
    }
};

const CPTReportContext =
    createContext<CPTReportContextType>(defaultContextValue);

export const CPTReportProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [report, dispatchReport] = useReducer<
        (state: CPTReportDTO, action: UpdateAction) => CPTReportDTO
    >(reportReducer, initialReport);
    const [error, dispatchError] = useReducer(errorReducer, null);
    const [isLoading, setIsLoading] = useState(false);
    const [isReportModuleOpen, setReportModuleOpen] = useState(false);
    const [viewState, setViewState] = useState<string>();
    const [progress, setProgress] = useState<{ [key: string]: ProgressStatus }>(
        {
            76014: 'In Progress',
            76015: 'Not Started',
            76016: 'Not Started',
        }
    );
    const [SOPs, setSOPs] = useState([]);
    const [isFetchingRisk, setIsFetchingRisk] = useState(false);
    const [isFetchingBenefits, setIsFetchingBenefits] = useState(false);

    useEffect(() => {
        const fetchSOPs = async () => {
            const response = await getAllInternalProtocols();
            setSOPs(response);
        };
        fetchSOPs();
    }, []);

    const syncProgress = () => {
        const time = report.summary?.totalTimeCPTCodes;
        // if (time) {
        const finishedCodes = Object.keys(progress)
            .filter(
                (key) =>
                    progress[key] === 'Finished' ||
                    progress[key] === 'In Progress'
            )
            .map((key) => key);
        dispatchReport({
            type: 'UPDATE',
            payload: {
                name: 'summary.codes',
                value: finishedCodes,
            },
        });
        // }
    };

    useEffect(() => {
        syncProgress();
    }, [progress]);

    const initNewReport = () => {
        dispatchReport({
            type: 'RESET',
        });
    };

    const { getAllInfo, getContext } = useContext(AuthContext);

    const setReportById = async (id: string, isEdit?: boolean) => {
        const r = await getCPTReportById(id);
        r.info.dateOfService = new Date(r.info.dateOfService);
        r.info.dateOfAssessment = new Date(r.info.dateOfAssessment);

        if (r.info.name !== '') {
            const info = await getAllInfo();
            if (!r.info.name.includes(info.user.name)) {
                r.info.name = `${r.info.name}, ${info.user.name}`;
            }
        }

        dispatchReport({
            type: 'SET',
            payload: r,
        });
    };

    const saveCurrentReport = async () => {
        if (report._id) {
            await updateCPTReport(report._id, report);
        } else {
            const rep = await createCPTReport(report);
            dispatchReport({
                type: 'UPDATE',
                payload: {
                    name: '_id',
                    value: rep._id,
                },
            });

            dispatchReport({
                type: 'UPDATE',
                payload: {
                    name: 'department_id',
                    value: rep.department_id,
                },
            });
        }
    };

    const getHandleEventChange = (field: string, hasError?: boolean) => {
        return (
            e: ChangeEvent<
                HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
            >
        ) => {
            if (hasError) {
                dispatchError({
                    type: 'CLEAR_ERROR',
                    payload: {
                        name: field,
                    },
                });
            }
            const { value } = e.target;
            dispatchReport({
                type: 'UPDATE',
                payload: {
                    name: field,
                    value,
                },
            });
        };
    };

    const getHandleValueChange = (field: string) => {
        return (value: any) => {
            dispatchReport({
                type: 'UPDATE',
                payload: {
                    name: field,
                    value,
                },
            });
        };
    };

    const getHandleSelectChange = (
        field: string,
        genType: 'single' | 'multi',
        genField?: string,
        isRichText?: boolean
    ) => {
        return (value: any) => {
            const val = getNestedValue(report, field);
            const genValue = getNestedValue(report, genField);
            let newText: string;
            let newValue: string | string[] = value;
            if (genType === 'single') {
                newText = getUpdatedText(field, value, genValue);
            } else if (genType === 'multi') {
                const { newlist, newNotes } = getUpdatedListAndText(
                    field,
                    value,
                    val,
                    genValue,
                    isRichText
                );
                newValue = newlist;
                newText = newNotes;
            }
            dispatchReport({
                type: 'UPDATE',
                payload: {
                    name: field,
                    value: newValue,
                },
            });
            if (genField) {
                dispatchReport({
                    type: 'UPDATE',
                    payload: {
                        name: genField,
                        value: newText,
                    },
                });
            }
        };
    };
    const fetchUserInfo = async (isReset?: boolean) => {
        const info = await getAllInfo();
        const ctx = getContext();

        if (report.info.name === '' || isReset) {
            dispatchReport({
                type: 'UPDATE',
                payload: {
                    name: 'info',
                    value: {
                        ...report.info,
                        name: info.user.name,
                        facilityName: ctx.department.name,
                        dateOfAssessment: new Date(),
                    },
                },
            });
        }
    };

    const canFetchBenefits = () => {
        return (
            report.safetyWork?.clinicalIndication &&
            report.conditionalsAnalysis.benefits.benefits === ''
        );
    };

    const fetchBenefits = async () => {
        if (canFetchBenefits()) {
            setIsFetchingBenefits(true);
            const benefits = await getBenefitsFromClinicalIndication(
                report.safetyWork.clinicalIndication
            );

            if (benefits !== '') {
                dispatchReport({
                    type: 'UPDATE',
                    payload: {
                        name: 'conditionalsAnalysis.benefits.benefits',
                        value: benefits,
                    },
                });
            }
            setIsFetchingBenefits(false);
        }
    };

    const canFetchRisk = () => {
        const { type, description, dimensions, hazards } =
            report.mriSafetyDecisionInfo.foreignBodyDetails;
        return (
            report.info.purpose === 'Foreign Body' &&
            type &&
            description &&
            dimensions &&
            hazards &&
            report.mriSafetyDecisionInfo.safetyAnalysis.risk === ''
        );
    };

    const fetchRisk = async () => {
        const { type, description, dimensions, hazards } =
            report.mriSafetyDecisionInfo.foreignBodyDetails;
        if (canFetchRisk()) {
            setIsFetchingRisk(true);
            const risk = await getRiskFromDetails(
                `type: ${type}\n
                explanations: ${description}\n
                dimension: ${dimensions}\n
                indentified hazards: ${hazards}`
            );
            if (risk !== '') {
                dispatchReport({
                    type: 'UPDATE',
                    payload: {
                        name: 'mriSafetyDecisionInfo.safetyAnalysis.risk',
                        value: risk,
                    },
                });
            }
            setIsFetchingRisk(false);
        }
    };

    const value = {
        report,
        dispatchReport,
        isLoading,
        setIsLoading,
        error,
        dispatchError,
        isReportModuleOpen,
        setReportModuleOpen,
        getHandleEventChange,
        getHandleValueChange,
        getHandleSelectChange,
        progress,
        setProgress,
        saveCurrentReport,
        initNewReport,
        setReportById,
        viewState,
        setViewState,
        SOPs,
        fetchUserInfo,
        fetchBenefits,
        fetchRisk,
        canFetchRisk,
        canFetchBenefits,
        isFetchingRisk,
        isFetchingBenefits,
    };

    return (
        <CPTReportContext.Provider value={value}>
            {children}
        </CPTReportContext.Provider>
    );
};

export const useCPTReportContext = () => useContext(CPTReportContext);

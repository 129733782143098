import React, { ChangeEvent, useEffect, useState } from 'react';
import { useCPTReportContext } from '../../../store/cptReport-context';
import FormInput from '../../general-components/FormInput';
import Select from '../../general-components/Select';
import styles from '../ReportWizard.module.css';
import { autofill } from '../../../services/cptAutofillService';
import { getNestedValue } from '../../../services/cptAutofillService';

const foreignBodyOptions = {
    'External Adornments & Body Modifications': [
        'Tattoos and permanent makeup with metallic pigments',
        'Piercings and jewelry',
        'Clothing accessories with hidden metal components (e.g., underwire bras, belt buckles, zippers)',
        'Hair accessories with concealed metal (e.g., bobby pins, hair extensions with metal clips)',
        'Cosmetic products containing metallic particles (eyeliners, powders, creams)',
        'Body art with metallic salts, microblading pigments',
        'Metallic beads or decorative elements inserted into skin or soft tissue',
    ],
    'Retained Foreign Bodies': [
        'Metallic foreign bodies (e.g., bullet fragments, shrapnel, metal splinters)',
        'Embedded splinters or fragments from industrial accidents (metal dust in the skin)',
        'Embedded fragments from explosions (glass, plastic, other debris)',
        'Intraocular foreign bodies (metal fragments in or near the eye)',
        'Metallic fragments within healed scars (e.g., old retained BB pellets)',
        'Non-metallic ingested foreign objects (coins, batteries, small toys)',
    ],
    'Dental & Ocular Items': [
        'Dental work (fillings, crowns, orthodontic wires, non-removable retainers)',
        'Retained dental foreign bodies',
        'Metallic ocular implants or fragments',
    ],
    'Medical/Surgical-Related Objects': [
        'Retained surgical clips or wires (non-implant)',
        'Medical debris from previous procedures (e.g., gauze with metallic markers, non-implantable tools)',
        'Metallic sutures, staples, or wound closure devices left behind',
        'Fragments of surgical mesh or patches containing metallic threads',
        'Temporary implants or markers placed for medical procedures and inadvertently left behind',
    ],
    'Implanted Hardware & Devices (Not official implants but potentially present)':
        [
            'Orthopedic hardware not designated as implants (external fixation devices, temporary pins/wires)',
            'Old-style vascular clips, coils, or stents not known to be MRI-compatible',
            'Embedded RFID tags or microchips used for identification',
        ],
    'Miscellaneous Items': [
        'Drug delivery patches with metallic backing (e.g., nicotine, fentanyl patches)',
        'Certain types of contact lenses containing metallic traces',
    ],
};

const ForeignBodyDetails: React.FC = () => {
    const {
        report,
        dispatchReport,
        getHandleEventChange,
        getHandleValueChange,
        getHandleSelectChange,
    } = useCPTReportContext();

    return (
        <div className={styles.form}>
            <div className={styles.row}>
                <FormInput
                    label="Choose type of foreign body"
                    tag="select"
                    name="type"
                    defaultValue={
                        report.mriSafetyDecisionInfo.foreignBodyDetails.type
                    }
                    onSelect={getHandleEventChange(
                        'mriSafetyDecisionInfo.foreignBodyDetails.type'
                    )}
                >
                    <>
                        <option value="" disabled>
                            Select type of foreign body
                        </option>
                        {Object.entries(foreignBodyOptions).map(
                            ([groupLabel, options]) => (
                                <optgroup label={groupLabel} key={groupLabel}>
                                    {options.map((option) => (
                                        <option value={option} key={option}>
                                            {option}
                                        </option>
                                    ))}
                                </optgroup>
                            )
                        )}
                    </>
                </FormInput>
                <FormInput
                    label="Location "
                    type="text"
                    name="location"
                    defaultValue={
                        report.mriSafetyDecisionInfo.foreignBodyDetails.location
                    }
                    onChange={getHandleEventChange(
                        'mriSafetyDecisionInfo.foreignBodyDetails.location'
                    )}
                />
            </div>
            <div className={styles.row}>
                <FormInput
                    label="Description of foreign body"
                    tag="textArea"
                    name="description"
                    defaultValue={
                        report.mriSafetyDecisionInfo.foreignBodyDetails
                            .description
                    }
                    onChange={getHandleEventChange(
                        'mriSafetyDecisionInfo.foreignBodyDetails.description'
                    )}
                />
            </div>
            <div className={styles.row}>
                <FormInput
                    label="Material of Foreign Body"
                    tag="select"
                    name="material"
                    defaultValue={
                        report.mriSafetyDecisionInfo.foreignBodyDetails.material
                    }
                    onSelect={getHandleEventChange(
                        'mriSafetyDecisionInfo.foreignBodyDetails.material'
                    )}
                >
                    <option value="" disabled>
                        Select material of foreign body
                    </option>
                    {[
                        'Metallic and Alloy-Based Materials',
                        'Organic and Inorganic Non-Metallic Materials',
                        'Pigmented and Ink-Based Materials',
                        'Medical Procedure-Related Materials',
                        'Industrial or Occupational Residues',
                        'Explosive or Shrapnel-Related Debris',
                        'Consumer-Related Items',
                        'Miscellaneous Materials',
                    ].map((material) => (
                        <option value={material}>{material}</option>
                    ))}
                </FormInput>
            </div>
            <div>
                <FormInput
                    label="Estimated dimensions of foreign body"
                    type="text"
                    name="dimensions"
                    defaultValue={
                        report.mriSafetyDecisionInfo.foreignBodyDetails
                            .dimensions
                    }
                    onChange={getHandleEventChange(
                        'mriSafetyDecisionInfo.foreignBodyDetails.dimensions'
                    )}
                />
            </div>
            <div className={styles.row}>
                <div>
                    <h5>Potential Hazards Identified</h5>
                    <Select
                        name="hazards"
                        type="checkbox"
                        options={Object.keys(
                            getNestedValue(
                                autofill,
                                'mriSafetyDecisionInfo.foreignBodyDetails.hazards'
                            )
                        )}
                        defaultChecked={
                            report.mriSafetyDecisionInfo.foreignBodyDetails
                                .hazards
                        }
                        changeSelected={getHandleSelectChange(
                            'mriSafetyDecisionInfo.foreignBodyDetails.hazards',
                            'multi',
                            'mriSafetyDecisionInfo.foreignBodyDetails.hazardExplanation'
                        )}
                    />
                </div>
            </div>
            <div className={styles.row}>
                <FormInput
                    tag="textArea"
                    name="description"
                    defaultValue={
                        report.mriSafetyDecisionInfo.foreignBodyDetails
                            .hazardExplanation
                    }
                    onChange={getHandleEventChange(
                        'mriSafetyDecisionInfo.foreignBodyDetails.hazardExplanation'
                    )}
                />
            </div>
        </div>
    );
};

export default ForeignBodyDetails;

import React, { useState } from 'react';
import Select from '../../general-components/Select';
import SelectItem from '../../general-components/SelectItem';

interface OptionalInputProps {
    label: string;
    children: React.ReactNode;
    onChange: (checked: boolean) => void;
    defaultValue?: boolean;
}

const OptionalInput: React.FC<OptionalInputProps> = ({
    label,
    children,
    defaultValue = false,
    onChange,
}) => {
    const [selectedOption, setSelectedOption] = useState<boolean>(defaultValue);

    const handleSelectChange = (_value: string, checked: boolean) => {
        setSelectedOption(checked);
        onChange(checked);
    };

    return (
        <div>
            <SelectItem
                value={label}
                name="optionalSelect"
                type="checkbox"
                defaultChecked={selectedOption}
                onChange={handleSelectChange}
            />
            {selectedOption && (
                <div style={{ marginLeft: '36px' }}>{children}</div>
            )}
        </div>
    );
};

export default OptionalInput;

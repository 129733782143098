const UltimateGuidePage = () => {
    return (
        <div className="h-full w-full">
            <iframe
                src="https://nordinsight.notion.site/ebd/d6acdee4d90f4d9d9c739558f06205b7"
                width="100%"
                height="100%"
                frameBorder="0"
                allowFullScreen
            />
        </div>
    );
};
export default UltimateGuidePage;

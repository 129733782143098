import { useEffect, useState } from 'react';
import SETTING_KEYS from '../../constants/setting-keys';
import { getHistory } from '../../services/apiService';
import LoadingRing from '../ui-components/LoadingRing';
import styles from './History.module.css';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '../ui/table/table';

const History = () => {
    const [history, setHistory] = useState([]);
    const [historyLoading, setHistoryLoading] = useState(true);

    const getData = async () => {
        const hist = await getHistory();
        setHistory(hist);
        setHistoryLoading(false);
    };

    useEffect(() => {
        getData();
    }, []);

    const formatDate = (str: string) => {
        var months = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ];
        const date = new Date(str);
        return `${date.getDate()}. ${
            months[date.getMonth()]
        } ${date.getFullYear()}  ${date.getHours()}:${date.getMinutes()}`;
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.history}>
                <h2>Search history</h2>
                {!historyLoading && history.length === 0 && (
                    <div className={styles.loading}>
                        <h3>No searches found</h3>
                    </div>
                )}
                {historyLoading && (
                    <div className={styles.loading}>
                        <LoadingRing size="large" />
                    </div>
                )}
                {!historyLoading && history.length > 0 && (
                    <>
                        <Table className="overflow-hidden rounded-2xl">
                            <TableHeader className="bg-neutral-600">
                                <TableHead>Name</TableHead>
                                <TableHead>Date</TableHead>
                            </TableHeader>
                            <TableBody>
                                {history.map((h) => (
                                    <TableRow key={h._id}>
                                        <TableCell>
                                            {h.Implant[0] !== undefined && (
                                                <a
                                                    href={
                                                        '/implant/' +
                                                        h.Implant[0]._id
                                                    }
                                                >
                                                    {
                                                        h.Implant[0][
                                                            SETTING_KEYS.model
                                                        ]
                                                    }
                                                </a>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {formatDate(h.createdAt)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </>
                    // <table>
                    //     <thead>
                    //         <tr>
                    //             <th id={SETTING_KEYS.model}>Name </th>
                    //             <th id="Verified">Date </th>
                    //         </tr>
                    //     </thead>
                    //     <tbody>
                    //         {history.map((h) => (
                    //             <tr key={h._id}>
                    //                 <td>
                    //                     {h.Implant[0] !== undefined && (
                    //                         <a
                    //                             href={
                    //                                 '/implant/' +
                    //                                 h.Implant[0]._id
                    //                             }
                    //                         >
                    //                             {
                    //                                 h.Implant[0][
                    //                                     SETTING_KEYS.model
                    //                                 ]
                    //                             }
                    //                         </a>
                    //                     )}
                    //                 </td>
                    //                 <td>{formatDate(h.createdAt)}</td>
                    //             </tr>
                    //         ))}
                    //     </tbody>
                    // </table>
                )}
            </div>
        </div>
    );
};

export default History;

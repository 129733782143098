import styles from './CardComponent.module.css';

const CardComponent: React.FC<{
    large?: boolean;
    preview: React.ReactNode;
    title: string;
    description: string;
    extra?: React.ReactNode;
    link?: string;
    group?: boolean;
    fixedWidth?: boolean;
    className?: string;
    children?: React.ReactNode;
}> = ({
    large,
    preview,
    title,
    description,
    extra,
    link,
    group,
    className,
    fixedWidth = true,
    children,
}) => {
    return (
        <div
            className={`${styles.wrapper} ${className} ${
                large ? styles.large : ''
            } ${fixedWidth ? styles.fixedWidth : ''}`}
        >
            <a href={link} className={styles.box}>
                <div className={styles.preview}>{preview}</div>
                <div className={styles.info}>
                    <h4
                        className={group ? styles.groupTitle : styles.title}
                        title={title}
                    >
                        {title}
                    </h4>
                    <p className={styles.description}>{description}</p>
                </div>
                {!large && <div className={styles.children}>{children}</div>}
            </a>
            {extra && large && <div className={styles.extra}>{extra}</div>}
        </div>
    );
};

export default CardComponent;

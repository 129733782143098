import { ChangeEvent } from 'react';
import {
    autofill,
    getUpdatedListAndText,
} from '../../../services/cptAutofillService';
import { useCPTReportContext } from '../../../store/cptReport-context';
import Button, { ButtonType } from '../../general-components/Button';
import FormInput from '../../general-components/FormInput';
import styles from '../ReportWizard.module.css';
import OptionalInput from '../shared/OptionalInput';

const RiskAnalysis = () => {
    const { report, getHandleEventChange, getHandleValueChange } =
        useCPTReportContext();

    const addEmptyAdditionalMod = () => {
        const updatedMods = [
            ...report.mriSafetyDecisionInfo.safetyAnalysis.additionalMod,
            '',
        ];
        getHandleValueChange(
            'mriSafetyDecisionInfo.safetyAnalysis.additionalMod'
        )(updatedMods);
    };

    const handleUpdateAdditionalMod = (
        index: number,
        e: ChangeEvent<
            HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
    ) => {
        const updatedMods = [
            ...report.mriSafetyDecisionInfo.safetyAnalysis.additionalMod,
        ];
        const { value } = e.target;
        updatedMods[index] = value;
        getHandleValueChange(
            'mriSafetyDecisionInfo.safetyAnalysis.additionalMod'
        )(updatedMods);
        const { newlist, newNotes } = getUpdatedListAndText(
            'mriSafetyDecisionInfo.safetyAnalysis.additionalMod',
            value,
            report.mriSafetyDecisionInfo.safetyAnalysis.additionalMod,
            report.mriSafetyDecisionInfo.safetyAnalysis.mitigation
        );
        getHandleValueChange('mriSafetyDecisionInfo.safetyAnalysis.mitigation')(
            newNotes
        );
    };

    const addEmptyStaffNeeded = () => {
        const updatedStaff = [
            ...report.mriSafetyDecisionInfo.safetyAnalysis.staffNeeded,
            '',
        ];
        getHandleValueChange(
            'mriSafetyDecisionInfo.safetyAnalysis.staffNeeded'
        )(updatedStaff);
    };

    const handleUpdateStaffNeeded = (
        index: number,
        e: ChangeEvent<
            HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
    ) => {
        const updatedStaff = [
            ...report.mriSafetyDecisionInfo.safetyAnalysis.staffNeeded,
        ];
        const { value } = e.target;
        updatedStaff[index] = value;
        getHandleValueChange(
            'mriSafetyDecisionInfo.safetyAnalysis.staffNeeded'
        )(updatedStaff);
        const { newlist, newNotes } = getUpdatedListAndText(
            'mriSafetyDecisionInfo.safetyAnalysis.staffNeeded',
            value,
            report.mriSafetyDecisionInfo.safetyAnalysis.staffNeeded,
            report.mriSafetyDecisionInfo.safetyAnalysis.mitigation
        );
        getHandleValueChange('mriSafetyDecisionInfo.safetyAnalysis.mitigation')(
            newNotes
        );
    };

    return (
        <div className={styles.form}>
            <div className={styles.row}>
                <OptionalInput
                    label="Limit SAR"
                    defaultValue={
                        !!report.mriSafetyDecisionInfo.safetyAnalysis.limitSar
                    }
                    onChange={(checked) => {
                        if (!checked) {
                            getHandleValueChange(
                                'mriSafetyDecisionInfo.safetyAnalysis.limitSar'
                            )('');
                        }
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                        }}
                    >
                        <FormInput
                            type="number"
                            name="limitSAR"
                            placeholder="Enter SAR value (eg. 2 W/kg)"
                            defaultValue={
                                report.mriSafetyDecisionInfo.safetyAnalysis
                                    .limitSar
                            }
                            onChange={getHandleEventChange(
                                'mriSafetyDecisionInfo.safetyAnalysis.limitSar'
                            )}
                        />{' '}
                        W/kg
                    </div>
                </OptionalInput>
            </div>
            <div className={styles.row}>
                <OptionalInput
                    label="Sequence Modification"
                    defaultValue={
                        report.mriSafetyDecisionInfo.safetyAnalysis
                            .sequenceMod !== ''
                    }
                    onChange={(checked) => {
                        if (!checked) {
                            getHandleValueChange(
                                'mriSafetyDecisionInfo.safetyAnalysis.sequenceMod'
                            )('');
                        }
                    }}
                >
                    <FormInput
                        type="text"
                        name="sequenceModification"
                        placeholder="Enter sequence modification details"
                        defaultValue={
                            report.mriSafetyDecisionInfo.safetyAnalysis
                                .sequenceMod
                        }
                        onChange={getHandleEventChange(
                            'mriSafetyDecisionInfo.safetyAnalysis.sequenceMod'
                        )}
                    />
                </OptionalInput>
            </div>
            <div className={styles.row}>
                <OptionalInput
                    label="Additional Modification"
                    defaultValue={
                        report.mriSafetyDecisionInfo.safetyAnalysis
                            .additionalMod.length > 0
                    }
                    onChange={(checked) => {
                        if (!checked) {
                            getHandleValueChange(
                                'mriSafetyDecisionInfo.safetyAnalysis.additionalMod'
                            )([]);
                        }
                    }}
                >
                    {report.mriSafetyDecisionInfo.safetyAnalysis.additionalMod.map(
                        (modification, index) => (
                            <FormInput
                                key={index}
                                tag="select"
                                name={`additionalModification-${index}`}
                                defaultValue={modification}
                                onSelect={(e) =>
                                    handleUpdateAdditionalMod(index, e)
                                }
                            >
                                <>
                                    <option value="" disabled>
                                        Select additional modification
                                    </option>
                                    {Object.keys(
                                        autofill.mriSafetyDecisionInfo
                                            .safetyAnalysis.additionalMod
                                    ).map((mod) => (
                                        <option value={mod} key={mod}>
                                            {mod}
                                        </option>
                                    ))}
                                </>
                            </FormInput>
                        )
                    )}
                    <Button
                        type={ButtonType.tietary}
                        onClick={addEmptyAdditionalMod}
                    >
                        Add
                    </Button>
                </OptionalInput>
            </div>
            <div className={styles.row}>
                <OptionalInput
                    label="Staff Needed"
                    defaultValue={
                        report.mriSafetyDecisionInfo.safetyAnalysis.staffNeeded
                            .length > 0
                    }
                    onChange={(checked) => {
                        if (!checked) {
                            getHandleValueChange(
                                'mriSafetyDecisionInfo.safetyAnalysis.staffNeeded'
                            )([]);
                        }
                    }}
                >
                    {report.mriSafetyDecisionInfo.safetyAnalysis.staffNeeded.map(
                        (staff, index) => (
                            <FormInput
                                key={index}
                                tag="select"
                                name={`staffNeeded-${index}`}
                                defaultValue={staff}
                                onSelect={(e) =>
                                    handleUpdateStaffNeeded(index, e)
                                }
                            >
                                <>
                                    <option value="" disabled>
                                        Select staff needed
                                    </option>
                                    {Object.keys(
                                        autofill.mriSafetyDecisionInfo
                                            .safetyAnalysis.staffNeeded
                                    ).map((staffOption) => (
                                        <option
                                            value={staffOption}
                                            key={staffOption}
                                        >
                                            {staffOption}
                                        </option>
                                    ))}
                                </>
                            </FormInput>
                        )
                    )}
                    <Button
                        type={ButtonType.tietary}
                        onClick={addEmptyStaffNeeded}
                    >
                        Add
                    </Button>
                </OptionalInput>
            </div>
            <div className={styles.row}>
                <FormInput
                    label="Risk Mitigation Summary"
                    tag="textArea"
                    name="riskMitigationSummary"
                    defaultValue={
                        report.mriSafetyDecisionInfo.safetyAnalysis.mitigation
                    }
                    onChange={getHandleEventChange(
                        'mriSafetyDecisionInfo.safetyAnalysis.mitigation'
                    )}
                />
            </div>
        </div>
    );
};

export default RiskAnalysis;

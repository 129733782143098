import React, { useEffect, useState } from 'react';
import Button, { ButtonType } from '../general-components/Button';
import styles from './BookmarkButton.module.css';
import {
    getIsBookmarked,
    addBookmark,
    deleteBookmark,
} from '../../services/apiService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark as solidBookmark } from '@fortawesome/free-solid-svg-icons';
import { faBookmark as regularBookmark } from '@fortawesome/free-regular-svg-icons';
import { useMediaQuery } from '../../hooks/useMediaQuery';
// import { Button } from '../ui/Button';

interface FavoriteImplant {
    id: string;
}

const BookmarkButton: React.FC<FavoriteImplant> = ({ id }) => {
    const [isFavourite, setIsFavourite] = useState(false);
    const [result, setResult] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const matches = useMediaQuery('(min-width: 968px)');

    const handleChangeFavourite = async () => {
        setIsLoading(true);

        if (isFavourite) {
            const response = await deleteBookmark(id);
            if (response) {
                setIsFavourite(false);
            }
        } else {
            const response = await addBookmark(id);

            if (response) {
                setIsFavourite(true);
            }
        }
        setIsLoading(false);
    };

    useEffect(() => {
        const getBm = async () => {
            const res = await getIsBookmarked(id);
            setIsFavourite(res);
        };
        getBm();
    }, [id, result]);

    return (
        <Button
            onClick={handleChangeFavourite}
            // variant="action"
            // size={matches ? 'default' : 'icon'}
            type={ButtonType.action}
            loading={isLoading}
            className=""
        >
            {isFavourite ? (
                <>
                    <FontAwesomeIcon
                        style={{
                            fontSize: '14px',
                            alignContent: 'center',
                            height: '14px',
                        }}
                        icon={solidBookmark}
                        color="var(--main-white)"
                    />
                    {matches ? 'Remove from Bookmarks' : ''}
                </>
            ) : (
                <>
                    <FontAwesomeIcon
                        style={{
                            fontSize: '14px',
                            alignContent: 'center',
                            height: '14px',
                            alignItems: 'center',
                        }}
                        icon={regularBookmark}
                        color="var(--main-white)"
                    />

                    {matches ? 'Save to Bookmark' : ''}
                </>
            )}
        </Button>
    );
};

export default BookmarkButton;

import React, {
    ChangeEvent,
    useContext,
    useEffect,
    useReducer,
    useState,
} from 'react';
import {
    signupWithInvitation,
    individualSignUp,
    userExists,
} from '../../services/apiService';
import {
    emailValidate,
    nameValidate,
    passwordValidate,
    repeatPasswordValidate,
} from '../../services/formValidationService';
import Button, { ButtonType } from '../general-components/Button';
import SignupLayout from '../ui-components/SignupLayout';
import styles from './SignupForm.module.css';
import sanitizeHtml from 'sanitize-html';
import {
    formReducer,
    errorReducer,
    camelCaseToSentence,
} from '../../utils/utils';
import HospitalInfoForm from './HospitalInfo';
import UserInfoForm from './UserInfo';
import VariantSelect from './VariantSelect';
import PaddleCheckout from '../subscription/PaddleCheckout';
import SignupContext from '../../store/signup-context';
import SelectItem from '../general-components/SelectItem';

export const ENG_DICT = {
    title: 'Sign up',
    description:
        'Please provide your information so we can set everything up for you.',
    name: 'Full name',
    email: 'Email',
    password: 'Password',
    repeatPassword: 'Repeat Password',
    button: 'Sign up',
    help: 'I accept the <a target="__blank" href="https://nordinsight.com/terms">terms of service</a>',
};

const SignupForm: React.FC = () => {
    const {
        formData,
        isIndividual,
        validateFields,
        setCurrentElement,
        id,
        step,
        setStep,
    } = useContext(SignupContext);

    const [signupStatus, setSignupStatus] = useState<string>();

    const [generalError, setGeneralError] = useState('');

    const [submitLoading, setSubmitLoading] = useState(false);

    const [acceptTerms, setAcceptTerms] = useState(false);
    const [termsError, setTermsError] = useState('');
    const [variantId, setVariantId] = useState<string>();
    let dict = ENG_DICT;

    const selectVariant = (v: string) => {
        setVariantId(v);
        setStep(2);
    };

    const handlePaddleClose = (paddleStatus: string) => {
        if (paddleStatus === 'completed') {
            setCurrentElement('confirm');
        }
    };

    const submitIndividualSignup = async () => {
        let canOpen = false;
        let customerId = '';
        let email = '';
        if (!acceptTerms) {
            setTermsError('You must accept the terms of service');
        } else if (validateFields(isIndividual)) {
            setSubmitLoading(true);
            const result = await individualSignUp(formData);
            console.log(result);
            setSignupStatus(result.status);
            if (result.status === 'success') {
                canOpen = true;
                email = formData.email;
            } else if (result.status === 'incomplete_individual') {
                canOpen = true;
                customerId = result.customerId;
            } else {
                setGeneralError('Subscription already exists');
            }
        }
        return {
            canOpen,
            customerId,
            email,
        };
    };

    const submitInvitationSignup = async () => {
        if (validateFields(isIndividual)) {
            setSubmitLoading(true);

            const result = await signupWithInvitation(formData, id);
            if (result.success) {
                setCurrentElement('confirm');
            } else {
                setSubmitLoading(false);
                setGeneralError(result.message);
            }
        }
    };

    return (
        <SignupLayout>
            <React.Fragment>
                {step === 2 && !isIndividual && (
                    <div className={styles.info}>
                        <h3>{dict.title}</h3>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(dict.description),
                            }}
                        ></p>
                    </div>
                )}
                {step === 2 && isIndividual && (
                    <div className={styles.info}>
                        <h1>Create a user</h1>
                        {/* <p>Please setup a password of atleast 8 characters.</p> */}
                    </div>
                )}
                {step === 1 && isIndividual && (
                    <div className={styles.info}>
                        <h1>Select subscription</h1>
                        <p>Please select how you want to be billed.</p>
                    </div>
                )}
                <form className={styles.form} autoComplete="off">
                    <span className={styles.error} aria-live="polite">
                        {generalError}
                    </span>
                    {step === 1 && isIndividual && (
                        <VariantSelect onSelect={selectVariant} />
                    )}
                    {step === 2 && (
                        <>
                            <UserInfoForm />
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <div className={styles.checkbox}>
                                    <SelectItem
                                        labelled={false}
                                        type="checkbox"
                                        value="accept"
                                        error={termsError !== ''}
                                        onChange={() => {
                                            setAcceptTerms(!acceptTerms);
                                            setTermsError('');
                                        }}
                                    />
                                    <label
                                        dangerouslySetInnerHTML={{
                                            __html: sanitizeHtml(dict.help),
                                        }}
                                    ></label>
                                </div>
                                <span
                                    className={styles.error}
                                    aria-live="polite"
                                >
                                    {termsError}
                                </span>
                            </div>
                        </>
                    )}
                    {step === 2 && !isIndividual && (
                        <Button
                            className={styles.button}
                            type={ButtonType.submit}
                            onClick={() => {
                                submitInvitationSignup();
                            }}
                            loading={submitLoading}
                        >
                            {dict.button}
                        </Button>
                    )}
                    {step === 2 && isIndividual && (
                        <PaddleCheckout
                            priceId={variantId}
                            onClose={handlePaddleClose}
                            beforeOpen={submitIndividualSignup}
                        >
                            Sign up
                        </PaddleCheckout>
                    )}
                </form>
            </React.Fragment>
        </SignupLayout>
    );
};

export default SignupForm;

import { ChangeEvent, useContext } from 'react';
import SignupContext from '../../store/signup-context';
import FormInput from '../general-components/FormInput';
import styles from './SignupForm.module.css';
import { ENG_DICT } from './SignupForm';

const UserInfoForm: React.FC = () => {
    const dict = ENG_DICT;

    const {
        isIndividual,
        defaultEmail,
        errorData,
        hasRoleSelect,
        setErrorData,
        setFormData,
    } = useContext(SignupContext);

    const handleInputChange = (
        e: ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
    ) => {
        const { name, value } = e.target;

        setErrorData({
            type: 'update',
            name,
            value: '',
        });

        setFormData({
            type: 'update',
            name,
            value,
        });
    };

    return (
        <>
            <FormInput
                type="text"
                name="name"
                label={dict.name}
                placeholder="Enter your name"
                onChange={handleInputChange}
                errorMsg={errorData.name}
            />

            <FormInput
                type="email"
                name="email"
                defaultValue={defaultEmail}
                label={dict.email}
                placeholder="Enter your email"
                onChange={handleInputChange}
                errorMsg={errorData.email}
            />
            <div className={styles.passwordContainer}>
                <FormInput
                    type="password"
                    name="password"
                    label={dict.password}
                    placeholder="Enter your password"
                    info="Atleast 8 characters, containing atleast 1 number."
                    onChange={handleInputChange}
                    errorMsg={errorData.password}
                />
                {!isIndividual && (
                    <FormInput
                        type="password"
                        name="repeatPassword"
                        label={dict.repeatPassword}
                        placeholder="Enter your password"
                        onChange={handleInputChange}
                        errorMsg={errorData.repeatPassword}
                    />
                )}
            </div>
            {hasRoleSelect && (
                <FormInput
                    tag="selectRole"
                    name="role"
                    defaultValue={'default'}
                    label="Role"
                    onSelect={handleInputChange}
                    errorMsg={errorData.role}
                    isSignup={true}
                />
            )}
        </>
    );
};

export default UserInfoForm;

import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../../store/auth-context';
import ConditionalsTable from '../../implant/conditionals/ConditionalsTable';
import Button, { ButtonType } from '../../general-components/Button';
import { formatTimestamp } from '../../../utils/utils';
import styles from './ProtocolTable.module.css';
import {
    InternalProtocolDTO,
    getInternalProtocolsByDepartment,
    verifyInternalProtocol,
    archiveInternalProtocol,
} from '../../../services/apiService';
import ConfirmActionButton from '../../general/confirmation/ConfirmActionButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArchive,
    faCheck,
    faCopy,
    faEdit,
    faEye,
    faFileSignature,
    faChevronDown,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import SETTING_KEYS from '../../../constants/setting-keys';
import PROTOCOL_KEYS from '../../../constants/protocol-keys';
import { TroubleshootRounded } from '@mui/icons-material';

const ProtocolTable = ({
    canToggle = true,
    defaultOpen = true,
    title,
    protocols,
    fetchProtocols,
    status,
    onEditProtocol,
    onVerify,
}: {
    canToggle?: boolean;
    defaultOpen?: boolean;
    title: string;
    protocols: any[];
    fetchProtocols: () => void;
    status: string;
    onEditProtocol: (id: string) => void;
    onVerify?: (id: string) => void;
}) => {
    const context = useContext(AuthContext);
    const [isOpen, setIsOpen] = useState(defaultOpen);

    const handleArchive = async (id: string) => {
        try {
            await archiveInternalProtocol(id);
            fetchProtocols();
        } catch (error) {
            console.error('Error archiving protocol:', error);
        }
    };

    const renderFields = () => {
        return protocols.map((protocol) => {
            const fields = [
                <>
                    {protocol.protocolId}
                    {protocol.protocolId && (
                        <Button
                            small={true}
                            type={ButtonType.action}
                            onClick={() =>
                                navigator.clipboard.writeText(
                                    protocol.protocolId
                                )
                            }
                        >
                            <FontAwesomeIcon icon={faCopy} />
                        </Button>
                    )}
                </>,
                <>
                    <a
                        className={styles.protocolTitle}
                        href={`/protocols/${protocol._id}`}
                    >
                        {protocol[PROTOCOL_KEYS.title]}
                    </a>
                </>,
                protocol.createdBy,
                // formatTimestamp(protocol.updatedAt),
                protocol['Expiry Date'] &&
                    formatTimestamp(protocol['Expiry Date']),
            ];
            if (context.hasPermission('Manage Protocols')) {
                fields.push(
                    <>
                        {
                            <>
                                {status === 'inProgress' && (
                                    <Button
                                        small={true}
                                        type={ButtonType.dept}
                                        onClick={() =>
                                            onVerify && onVerify(protocol._id)
                                        }
                                    >
                                        <FontAwesomeIcon icon={faCheck} />
                                        Verify
                                    </Button>
                                )}
                                <Button
                                    small={true}
                                    type={ButtonType.action}
                                    onClick={() => onEditProtocol(protocol._id)}
                                >
                                    <FontAwesomeIcon icon={faEdit} />
                                    Edit
                                </Button>
                                {status !== 'archived' && (
                                    <ConfirmActionButton
                                        buttonText="Archive"
                                        buttonType={ButtonType.cancel}
                                        modalInfo={{
                                            title: 'Archive Protocol?',
                                            message:
                                                'Protocol has been archived.',
                                            submitButtonText: 'Archive',
                                            submitButtonDanger: true,
                                        }}
                                        onConfirm={() => {
                                            handleArchive(protocol._id);
                                        }}
                                    >
                                        This action cannot be undone.
                                    </ConfirmActionButton>
                                )}
                            </>
                        }
                    </>
                );
            }
            return fields;
        });
    };

    const renderHeaders = () => {
        const h = ['ID', 'Title', 'Author', 'Expires'];
        if (context.hasPermission('Manage Protocols')) {
            h.push('Actions');
        }
        return h;
    };

    return (
        <div className={styles.tableContainer}>
            <div
                className={styles.tableHeader}
                onClick={() => canToggle && setIsOpen(!isOpen)}
                style={{ cursor: canToggle ? 'pointer' : 'default' }}
            >
                {canToggle && (
                    <FontAwesomeIcon
                        icon={isOpen ? faChevronDown : faChevronRight}
                        style={{ marginRight: '10px' }}
                    />
                )}
                <h3>{title}</h3>
            </div>
            {isOpen && (
                <ConditionalsTable
                    sizes={[1.5, 3, 1.5, 1, 2]}
                    hasSeeMore={false}
                    headers={renderHeaders()}
                    fields={renderFields()}
                />
            )}
        </div>
    );
};

export default ProtocolTable;

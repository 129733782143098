import React, { useContext, useEffect, useState } from 'react';
import {
    getUserDepartments,
    updateCurrentDepartment,
} from '../../services/api/organizationService';
import AuthContext from '../../store/auth-context';
import styles from './OrganizationToggle.module.css';
import FormInput from '../general-components/FormInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

interface Department {
    _id: string;
    name: string;
    color: string;
}

const OrganizationToggle: React.FC = () => {
    const [guestDepartments, setGuestDepartments] = useState<Department[]>([]);
    const [homeDepartment, setHomeDepartment] = useState<Department>();
    const [currentDepartment, setCurrentDepartment] = useState<Department>();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const authContext = useContext(AuthContext);
    const ctx = authContext.getContext();

    const [borderColor, setBorderColor] = useState<string | null>(null);

    useEffect(() => {
        const fetchDepartments = async () => {
            setIsLoading(true);
            const departmentsData = await getUserDepartments();

            setGuestDepartments(departmentsData.guestDepartments);
            setHomeDepartment(departmentsData.homeDepartment);
            setCurrentDepartment(departmentsData.currentDepartment);
            setBorderColor(departmentsData.currentDepartment.color);
            // document.body.style.border = `1px solid ${departmentsData.currentDepartment.color}`;
            setIsLoading(false);
        };

        fetchDepartments();
    }, []);

    const handleDepartmentChange = async (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        const departmentId = event.target.value;

        await updateCurrentDepartment(departmentId);
        await authContext.retrieveContext();
        window.location.reload();
    };

    return (
        <>
            <span
                className={styles.border}
                style={{ backgroundColor: borderColor }}
            ></span>
            <div
                className={styles.container}
                // style={{ borderBottom: `5px solid ${borderColor}` }}
            >
                {isLoading ? (
                    <p>Loading departments...</p>
                ) : error ? (
                    <p className={styles.error}>{error}</p>
                ) : (
                    <FormInput
                        focusColor={borderColor}
                        label="Current Department"
                        tag="select"
                        name="department"
                        defaultValue={currentDepartment?._id || ''}
                        onSelect={handleDepartmentChange}
                    >
                        <optgroup label="Home" key="Home">
                            <option
                                value={homeDepartment?._id}
                                key={homeDepartment?._id}
                            >
                                {homeDepartment?.name}
                            </option>
                        </optgroup>
                        <optgroup label="Guest" key="Guest">
                            {guestDepartments?.map((dept) => (
                                <option value={dept._id} key={dept._id}>
                                    {dept.name}
                                </option>
                            ))}
                        </optgroup>
                    </FormInput>
                )}
            </div>
        </>
    );
};

export default OrganizationToggle;

import { useEffect, useState } from 'react';
import { getAllDepartments } from '../../services/apiService';
import Button, { ButtonType } from '../general-components/Button';
import AddUser from '../manage-user/AddUser';

const InviteAdmin = () => {
    const [departments, setDepartments] = useState([]);
    const [selectedDeptId, setSelectedDeptId] = useState('');
    const [showModal, setShowModal] = useState(false);

    const getDepartments = async () => {
        const depts = await getAllDepartments();
        setDepartments(depts);
    };

    const onDeptChange = (e: any) => {
        setSelectedDeptId(e.target.value);
    };

    const openModal = () => {
        if (selectedDeptId.length > 0) {
            setShowModal(true);
        }
    };

    useEffect(() => {
        getDepartments();
    }, []);

    return (
        <div>
            <select onChange={onDeptChange} defaultValue={'default'}>
                <option value="default" disabled>
                    Choose a Department
                </option>
                {departments.map((dept) => (
                    <option key={dept._id} value={dept._id}>
                        {dept.name}
                    </option>
                ))}
            </select>
            <Button type={ButtonType.primary} onClick={openModal}>
                Choose
            </Button>
            {showModal && (
                <AddUser
                    deptId={selectedDeptId}
                    isAdmin={true}
                    close={() => setShowModal(!showModal)}
                    onSubmit={() => {}}
                />
            )}
        </div>
    );
};

export default InviteAdmin;

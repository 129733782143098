import { useContext } from 'react';
import RIGHTS_KEYS from '../../../constants/rights-keys';
import SETTING_KEYS from '../../../constants/setting-keys';
import AuthContext from '../../../store/auth-context';
import {
    ArtifactDTO,
    SARDTO,
    SpatialGradientDTO,
    TempDTO,
    WaintingPeriodDTO,
} from '../../../models/ConditionalsModels';
import ConditionalsText from './ConditionalsText';
import TimerIcon from '@mui/icons-material/Timer';
import ConditionalsTable from './ConditionalsTable';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLessThanEqual } from '@fortawesome/free-solid-svg-icons';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import styles from './ConditionalsComponent.module.css';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

const renderValue = (
    key: string,
    value:
        | WaintingPeriodDTO
        | SpatialGradientDTO
        | SARDTO[]
        | ArtifactDTO[]
        | TempDTO[]
        | string
        | string[]
        | number,
    isMobile: boolean
) => {
    if (key === SETTING_KEYS.waitingPeriod) {
        const v = value as WaintingPeriodDTO;
        return (
            <ConditionalsText
                title={
                    <>
                        <TimerIcon />
                        {SETTING_KEYS.waitingPeriod}
                    </>
                }
                delimiter=" |"
                text={
                    v.immediateScan
                        ? 'Patient can be scanned IMMEDIATELY post implantation.'
                        : `Patient can be scanned ${v.weeksAfterScan} weeks after implantation`
                }
            />
        );
    } else if (key === SETTING_KEYS.fieldStrength) {
        const fieldStrength = value as string[];
        if (fieldStrength.length !== 0) {
            return (
                <ConditionalsTable
                    title={key}
                    headers={[SETTING_KEYS.fieldStrength]}
                    fields={[
                        (value as string[]).sort().map((f) => (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '5px',
                                }}
                            >
                                <CheckCircleOutlineIcon />
                                {f + ' Tesla'}
                            </div>
                        )),
                    ]}
                />
            );
        }
    } else if (key === SETTING_KEYS.spatialGradient) {
        return (
            <ConditionalsTable
                title={key}
                headers={['Spatial Gradient', 'Condtion']}
                fields={(value as SpatialGradientDTO).gradients.map(
                    (sg: any) => [
                        <div
                            style={{
                                fontWeight: '400',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '5px',
                            }}
                        >
                            <FontAwesomeIcon icon={faLessThanEqual} />
                            {`${sg.value} Gauss/cm (${sg.value / 100} T/m)`}
                        </div>,
                        sg.condition,
                    ]
                )}
            />
        );
    } else if (key === SETTING_KEYS.slewRate) {
        if (typeof value === 'string') {
            return (
                <ConditionalsText
                    title={
                        <>
                            <ReportGmailerrorredIcon />
                            {key}
                        </>
                    }
                    delimiter={' |'}
                    text={value as string}
                />
            );
        } else {
            return (
                <ConditionalsTable
                    title={key}
                    headers={['Slew Rate (Gr)', 'Condition']}
                    fields={[
                        [
                            `${(value as any).rate} T/m/s`,
                            (value as any).condition,
                        ],
                    ]}
                />
            );
        }
    } else if (key === SETTING_KEYS.sar) {
        return (
            <ConditionalsTable
                title={key}
                headers={[
                    'SAR',
                    'Operating Mode',
                    'Scanning time per Sequence',
                    'Condition',
                ]}
                fields={(value as SARDTO[]).map((sar) => [
                    `Max ${sar.value} W/kg`,
                    sar.operatingMode,
                    sar.scanningTime !== undefined
                        ? `Max ${sar.scanningTime} min.`
                        : '',
                    sar.condition,
                ])}
            />
        );
    } else if (
        (key === SETTING_KEYS.brms ||
            key === SETTING_KEYS.coilType ||
            key === SETTING_KEYS.mriArc ||
            key === SETTING_KEYS.prep) &&
        value !== '<p></p>'
    ) {
        return (
            <ConditionalsText
                title={
                    <>
                        <ReportGmailerrorredIcon />
                        {key}
                    </>
                }
                delimiter={' |'}
                text={value as string}
            />
        );
    } else if (key === SETTING_KEYS.artefacts) {
        const arts = value as ArtifactDTO[];
        if (arts[0]?.hasArtifact) {
            return (
                <ConditionalsText
                    title={
                        <>
                            <ReportGmailerrorredIcon />
                            {key}
                        </>
                    }
                    delimiter={' |'}
                    text={'MR Image Quality near Device possibly compromised'}
                />
            );
        } else {
            return (
                <ConditionalsTable
                    title={key}
                    headers={[
                        'Artifact Distance',
                        'Pulse Sequence',
                        'MR system',
                        'Condition',
                    ]}
                    fields={(value as ArtifactDTO[]).map((art) => [
                        art.distance ? `${art.distance}mm` : '',
                        art.pulseSequence,
                        art.mrSystem ? `${art.mrSystem}T` : '',
                        art.condition,
                    ])}
                />
            );
        }
    } else if (key === SETTING_KEYS.temp) {
        const temp = value as TempDTO[];
        return (
            <ConditionalsTable
                title={key}
                headers={['Max temperature rise', 'Condition']}
                sizes={isMobile ? [2, 2] : [1, 3]}
                fields={temp.map((t) => [`${t.maxRise} C°`, t.condition])}
            />
        );
    }
    return '';
};

const ConditionalsComponent: React.FC<{ conditionals: any }> = ({
    conditionals,
}) => {
    const ctx = useContext(AuthContext);
    const isMobile = useMediaQuery('(max-width: 768px)');

    const conditionalIsEmpty = (key: string) => {
        return (
            conditionals[key] === null ||
            conditionals[key] === undefined ||
            conditionals[key] === '' ||
            (key === SETTING_KEYS.sar && conditionals[key].length === 0) ||
            (key === SETTING_KEYS.spatialGradient &&
                conditionals[key].gradients.length === 0) ||
            (key === SETTING_KEYS.temp && conditionals[key].length === 0) ||
            (key === SETTING_KEYS.artefacts &&
                !conditionals[key][0]?.hasArtifact &&
                (conditionals[key].length === 0 ||
                    conditionals[key][0].distance === undefined)) ||
            (key === SETTING_KEYS.waitingPeriod &&
                !conditionals[key].immediateScan &&
                conditionals[key].weeksAfterScan === undefined) ||
            (key === SETTING_KEYS.slewRate &&
                conditionals[key].rate === undefined)
        );
    };

    const settings = [
        SETTING_KEYS.waitingPeriod,
        SETTING_KEYS.fieldStrength,
        SETTING_KEYS.mriArc,
        SETTING_KEYS.spatialGradient,
        SETTING_KEYS.slewRate,
        SETTING_KEYS.sar,
        SETTING_KEYS.brms,
        SETTING_KEYS.coilType,
        SETTING_KEYS.artefacts,
        SETTING_KEYS.temp,
        SETTING_KEYS.prep,
        SETTING_KEYS.RFExc,
    ];

    return (
        <div className={styles.wrapper}>
            {ctx.orRights([RIGHTS_KEYS.detailedInfo]) &&
                Object.entries(settings).map(([index, key]) => {
                    if (conditionalIsEmpty(key)) {
                        return '';
                    }
                    return (
                        <>
                            {conditionals[key] && (
                                <div key={key}>
                                    {renderValue(
                                        key,
                                        conditionals[key],
                                        isMobile
                                    )}
                                </div>
                            )}
                        </>
                    );
                })}
        </div>
    );
};

export default ConditionalsComponent;

import { useEffect } from 'react';
import { useCPTReportContext } from '../../../store/cptReport-context';
import FormInput from '../../general-components/FormInput';
import styles from '../ReportWizard.module.css';
import {
    getAllMRIMachinesByDepartment,
    MRIMachineDTO,
} from '../../../services/apiService';
import { useState } from 'react';

const ScannerSettings = () => {
    const { report, getHandleEventChange, SOPs } = useCPTReportContext();
    const [scannerModels, setScannerModels] = useState([]);

    useEffect(() => {
        const fetchScannerModels = async () => {
            const res = await getAllMRIMachinesByDepartment();
            setScannerModels(res);
        };
        fetchScannerModels();
    }, []);

    return (
        <>
            <div className={styles.row}>
                <FormInput
                    label="SOP"
                    tag="select"
                    name="sopName"
                    defaultValue={
                        report.guidance.sopName ? report.guidance.sopName : ''
                    }
                    onSelect={getHandleEventChange('guidance.sopName')}
                >
                    <option value="" disabled>
                        Select SOP
                    </option>
                    {SOPs.map((sop) => (
                        <option value={sop.Title}>{sop.Title}</option>
                    ))}
                </FormInput>
            </div>
            <div className={styles.row}>
                <FormInput
                    label="Scanner Model"
                    tag="select"
                    name="scannerModel"
                    defaultValue={
                        report.scannerSettings.scannerModel
                            ? report.scannerSettings.scannerModel
                            : ''
                    }
                    onSelect={getHandleEventChange(
                        'scannerSettings.scannerModel'
                    )}
                >
                    <option value="" disabled>
                        Select Scanner Model
                    </option>
                    {scannerModels.map((model: MRIMachineDTO) => (
                        <option value={model.Model}>{model.Model}</option>
                    ))}
                </FormInput>
            </div>
            {report.mriSafetyDecisionInfo.safetyAnalysis.limitSar !== '' && (
                <div className={styles.row}>
                    <FormInput
                        type="text"
                        label="Limit SAR"
                        name="limitSAR"
                        placeholder="Enter SAR value (eg. 2 W/kg)"
                        defaultValue={
                            report.mriSafetyDecisionInfo.safetyAnalysis.limitSar
                        }
                        onChange={getHandleEventChange(
                            'mriSafetyDecisionInfo.safetyAnalysis.limitSar'
                        )}
                    />
                </div>
            )}
            <div className={styles.row}>
                <FormInput
                    label="Technique"
                    tag="textArea"
                    name="technique"
                    defaultValue={report.scannerSettings.technique}
                    onChange={getHandleEventChange('scannerSettings.technique')}
                />
            </div>
            <div className={styles.row}>
                <FormInput
                    label="Patient Position"
                    tag="textArea"
                    name="patientPosition"
                    defaultValue={report.scannerSettings.patientPosition}
                    onChange={getHandleEventChange(
                        'scannerSettings.patientPosition'
                    )}
                />
            </div>
        </>
    );
};

export default ScannerSettings;

import { useCPTReportContext } from '../../../store/cptReport-context';
import FormInput from '../../general-components/FormInput';
import Select from '../../general-components/Select';
import styles from '../ReportWizard.module.css';
import {
    autofill,
    getNestedValue,
    getUpdatedListAndText,
} from '../../../services/cptAutofillService';
import { ChangeEvent, useEffect } from 'react';
import { convert } from 'html-to-text';
import RichText from '../../general-components/RichText';

const Guidance = () => {
    const {
        report,
        getHandleSelectChange,
        getHandleEventChange,
        getHandleValueChange,
        SOPs,
    } = useCPTReportContext();

    const handleUpdateSources = async (value: string) => {
        if (
            value === 'Internal SOP' &&
            report.guidance.sources.includes('Internal SOP')
        ) {
            const { ne } = clearSOP(report.guidance.explanation);
            await getHandleValueChange('guidance.explanation')(ne);

            getHandleValueChange('guidance.sopName')('');
            getHandleValueChange('guidance.sopInfo')([]);
        } else {
        }
        getHandleSelectChange(
            'guidance.sources',
            'multi',
            'guidance.explanation',
            true
        )(value);
    };

    const clearSOP = (ne: string) => {
        const tempStr =
            'We have followed an internal SOP with the following description:\n';
        const regex = new RegExp(`${tempStr}.*\\n\\n`);

        if (regex.test(ne)) {
            ne = ne.replace(regex, '');
        }

        return { ne, tempStr };
    };

    const handleUpdateSOP = async (
        e: ChangeEvent<
            HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
    ) => {
        const { value } = e.target;
        getHandleValueChange('guidance.sopName')(value);

        const sop = SOPs.find((sop) => sop.Title === value);
        const sopDescription = sop?.Description;

        getHandleValueChange('guidance.sopInfo')({
            Title: value,
            Author: sop.editLogProtocol[0]?.name,
            Date: sop.editLogProtocol[0]?.date.split('T')[0],
        });

        let nex = report.guidance.explanation.replace(
            autofill.guidance.sources['Internal SOP'] + '\n\n',
            ''
        );

        const { ne, tempStr } = await clearSOP(nex);
        await getHandleValueChange('guidance.explanation')(
            ne + tempStr + sopDescription + '\n\n'
        );
    };

    const handleUpdateSOPInfo = (key: string) => {
        return async (
            e: ChangeEvent<
                HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
            >
        ) => {
            const { value } = e.target;
            getHandleValueChange('guidance.sopInfo')({
                ...report.guidance.sopInfo,
                [key]: value,
            });
        };
    };

    useEffect(() => {
        console.log(report.guidance);
    }, [report.guidance.explanation]);

    return (
        <div className={styles.form}>
            <div className={styles.row}>
                <div>
                    <h5>Which source was used to determine the guidance?</h5>
                    <Select
                        name="sources"
                        type="checkbox"
                        defaultChecked={report.guidance.sources}
                        options={Object.keys(
                            getNestedValue(autofill, 'guidance.sources')
                        )}
                        changeSelected={handleUpdateSources}
                    />
                    {report.guidance.sources.includes('Internal SOP') &&
                        'Internal SOP' && (
                            <div className={styles.row}>
                                <FormInput
                                    label="SOP"
                                    tag="select"
                                    name="sopName"
                                    defaultValue={
                                        report.guidance.sopName
                                            ? report.guidance.sopName
                                            : ''
                                    }
                                    onSelect={handleUpdateSOP}
                                >
                                    <option value="" disabled>
                                        Select SOP
                                    </option>
                                    {SOPs.map((sop) => (
                                        <option value={sop.Title}>
                                            {sop.Title}
                                        </option>
                                    ))}
                                </FormInput>
                            </div>
                        )}
                    {report.guidance.sources.includes('Internal SOP') &&
                        report.guidance.sopName && (
                            <>
                                {Object.entries(report.guidance.sopInfo).map(
                                    ([key, value]) => {
                                        if (key === 'Date' && value) {
                                            return (
                                                <FormInput
                                                    label={key}
                                                    type="date"
                                                    name={key}
                                                    defaultValue={
                                                        (value as string).split(
                                                            'T'
                                                        )[0]
                                                    }
                                                    onChange={handleUpdateSOPInfo(
                                                        key
                                                    )}
                                                />
                                            );
                                        }
                                        return (
                                            <div>
                                                <FormInput
                                                    label={key}
                                                    type="text"
                                                    name={key}
                                                    defaultValue={value}
                                                    onChange={handleUpdateSOPInfo(
                                                        key
                                                    )}
                                                />
                                            </div>
                                        );
                                    }
                                )}
                            </>
                        )}
                    <RichText
                        label="Explanation"
                        description={report.guidance.explanation}
                        onChange={getHandleValueChange('guidance.explanation')}
                    />
                </div>
            </div>

            <div className={styles.row}>
                <div>
                    <h5>Recommendations for MRI</h5>
                    <Select
                        name="recommendations"
                        type="radio"
                        defaultChecked={[report.guidance.recommendation]}
                        options={Object.keys(
                            getNestedValue(autofill, 'guidance.recommendation')
                        )}
                        changeSelected={getHandleSelectChange(
                            'guidance.recommendation',
                            'single',
                            'guidance.notes'
                        )}
                    />
                    <FormInput
                        label="Notes"
                        tag="textArea"
                        name="notes"
                        defaultValue={report.guidance.notes}
                        onChange={getHandleEventChange('guidance.notes')}
                    />
                </div>
            </div>
        </div>
    );
};

export default Guidance;

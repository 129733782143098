import { useEffect, useState } from 'react';
import { getBookmarks } from '../../services/apiService';
import LoadingRing from '../ui-components/LoadingRing';
import styles from './Bookmarks.module.css';
import Results from '../search/Results';
import CardComponent from '../general/Cards/CardComponent';
import Status from '../general-components/Status';
import SETTING_KEYS from '../../constants/setting-keys';
import { useMediaQuery } from '../../hooks/useMediaQuery';

const Bookmarks = () => {
    const [favorites, setFavorite] = useState([]);
    const [favoritesLoading, setFavoritesLoading] = useState(true);
    const matches = useMediaQuery('(min-width: 768px)');

    const getBookmarkedData = async () => {
        const bookmarkList = await getBookmarks();
        setFavorite(bookmarkList);
        setFavoritesLoading(false);
    };

    useEffect(() => {
        getBookmarkedData();
    }, []);

    return (
        <div className={styles.wrapper}>
            {!favoritesLoading && favorites.length === 0 && (
                <div className={styles.loading}>
                    <h3>No bookmarked implants</h3>
                </div>
            )}
            {favoritesLoading && (
                <div className={styles.loading}>
                    <LoadingRing size="large" />
                </div>
            )}
            {!favoritesLoading && favorites.length > 0 && (
                <>
                    {matches ? (
                        <Results
                            hasSearch={true}
                            title="Bookmarks"
                            term=""
                            defaultResults={favorites}
                        />
                    ) : (
                        <div className=" mt-20">
                            <h2>Bookmarks</h2>
                            {favorites.map((imp) => (
                                <div className="pb-2">
                                    <CardComponent
                                        fixedWidth={false}
                                        link={'/implant/' + imp.info._id}
                                        preview={
                                            <div
                                                style={{
                                                    transform:
                                                        'translateX(10px)',
                                                }}
                                            >
                                                <Status
                                                    status={
                                                        imp.info[
                                                            SETTING_KEYS
                                                                .mriStatus
                                                        ]
                                                    }
                                                />
                                            </div>
                                        }
                                        title={imp.info[SETTING_KEYS.model]}
                                        description={
                                            imp[SETTING_KEYS.manufacturer]
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default Bookmarks;

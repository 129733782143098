import { useEffect, useState, useContext, FC } from 'react';
import Button, { ButtonType } from '../../general-components/Button';
import styles from './RoomTable.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import LoadingRing from '../../ui-components/LoadingRing';
import DeleteMRIMachine from '../../room-overview/DeleteMRIMachine';
import AddMRIMachine from '../../room-overview/AddMRIMachine';
import OverlayWindow from '../../ui-components/OverlayWindow';
import RIGHTS_KEYS from '../../../constants/rights-keys';
import AuthContext from '../../../store/auth-context';
import { getAllMRIMachinesByDepartment } from '../../../services/apiService';
import ConfirmActionButton from '../../../components/general/confirmation/ConfirmActionButton';

interface RoomTableProps {
    machine: any;
    setMachineIdToEdit: any;
    onDelete: any;
}
const RoomTable: FC<RoomTableProps> = ({
    machine,
    setMachineIdToEdit,
    onDelete,
}) => {
    const context = useContext(AuthContext);
    return (
        <div className="pt-4 px-2 rounded-xl">
            <table className={styles.table}>
                <caption>
                    <div className={styles.tableHeadingWrapper}>
                        <div className={styles.tableHeading}>
                            <h4>{machine?.Name}</h4>
                            {context.orRights([
                                RIGHTS_KEYS.manageMRIOverview,
                            ]) && (
                                <div
                                    className={
                                        styles.tableHeadingButtonsWrapper
                                    }
                                >
                                    <Button
                                        small
                                        className={styles.editBtn}
                                        type={ButtonType.tietary}
                                        onClick={() => {
                                            setMachineIdToEdit(machine._id);
                                        }}
                                    >
                                        Edit
                                    </Button>
                                    <ConfirmActionButton
                                        buttonText="Delete"
                                        buttonType={ButtonType.cancel}
                                        modalInfo={{
                                            title: 'Delete MRI Machine',
                                            message: 'Deleted',
                                            submitButtonText: 'Delete',
                                            submitButtonDanger: true,
                                        }}
                                        onConfirm={() => {
                                            onDelete(machine._id);
                                        }}
                                    >
                                        This Cannot be undone
                                    </ConfirmActionButton>
                                </div>
                            )}
                        </div>
                        <h4>{machine.Model}</h4>
                    </div>
                </caption>
                <tbody>
                    <tr>
                        <td>Static magnetic field</td>
                        <td>{machine.FieldStrength} Tesla</td>
                    </tr>
                    {machine.SpatialGradient.map(
                        (gradient: any, index: number) => (
                            <tr>
                                <td>
                                    Spatial gradient - Diameter:{' '}
                                    {gradient.diameter} cm
                                </td>
                                <td>
                                    {gradient.value} T/m ({gradient.value * 100}{' '}
                                    Gauss/cm)
                                </td>
                            </tr>
                        )
                    )}

                    <tr>
                        <td>Maximun slew rate {'(per axis)'}</td>
                        <td>{machine.SlewRatePerAxis} T/m/s</td>
                    </tr>
                    <tr>
                        <td>Maximum effective slew rate</td>
                        <td>{machine.MaxSlewRate} T/m/s</td>
                    </tr>
                    <tr>
                        <td>Maximum SAR - RF energy disposal</td>{' '}
                        <td>
                            {machine.MaxSAR.map((sar: any, i: number) => (
                                <li>
                                    Level {sar.level} - WholeBody{' '}
                                    {sar.wholeBody} W/kg - Head {sar.head} W/kg
                                </li>
                            ))}
                        </td>
                    </tr>
                    <tr>
                        <td>Maximum B1+rms</td>
                        <td>{machine.MaxB1rms} micro T</td>
                    </tr>
                    <tr>
                        <td>T/R Coils</td>
                        <td>{machine.TRCoils}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default RoomTable;

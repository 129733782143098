import React, {
    useState,
    useEffect,
    useContext,
    MouseEventHandler,
} from 'react';
import DatePicker from './DatePicker';
import ManageUserContext, {
    ContextUser,
} from '../../store/manage-user-context';
import EditUserInfo from './EditUserInfo';
import AuthContext from '../../store/auth-context';
import Modal from './Modal';
import RIGHTS_KEYS from '../../constants/rights-keys';
import styles from './ExtendedUserInfo.module.css';
import Button, { ButtonType } from '../general-components/Button';
import DeleteUser from './DeleteUser';
import DepartmentContext from '../../store/department-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import RoleSelector from './RoleSelector';
import { updateDeptRole } from '../../services/apiService';
import editStyles from './EditUserInfo.module.css';
import FormInput from '../general-components/FormInput';

const ExtendedInfoComponent: React.FC<{
    isActive: (user: ContextUser) => boolean;
    onChange: (obj: any, remove?: boolean) => void;
    user: any;
    deptLicenses: number;
}> = ({ isActive, onChange, user, deptLicenses }) => {
    const userInfoContext = useContext(ManageUserContext);
    const userContext = useContext(AuthContext);
    const departmentContext = useContext(DepartmentContext);
    const [openedModal, setOpenedModal] = useState('');

    const toggleModal = (id?: string) => {
        setOpenedModal('');

        if (
            new Date(user.expiryDate) > new Date() &&
            departmentContext.activeUsers.every((ele) => ele.id !== user.id)
        ) {
            departmentContext.updateActiveUsers([
                ...departmentContext.activeUsers,
                user,
            ]);
        } else if (
            new Date(user.expiryDate) < new Date() &&
            departmentContext.activeUsers.filter((ele) => ele.id === user.id)
                .length !== 0
        ) {
            departmentContext.updateActiveUsers(
                departmentContext.activeUsers.filter(
                    (ele) => ele.id !== user.id
                )
            );
        }
    };

    const close = () => {
        toggleModal(userInfoContext.user.id);
    };

    const deactivateUser = () => {
        const date = new Date();
        date.setDate(date.getDate() - 1);
        userInfoContext.setExpiry(date.toDateString());
        userInfoContext.setExpiryDate(date);
        onChange({ ...userInfoContext.user, expiryDate: date }, false);
        toggleModal(userInfoContext.user.id);

        departmentContext.updateActiveUsers(
            departmentContext.activeUsers.filter((ele) => ele.id !== user.id)
        );
    };

    const yesButton = (
        <Button type={ButtonType.primary} onClick={deactivateUser}>
            Yes
        </Button>
    );

    const noButton = (
        <Button type={ButtonType.secondary} onClick={close}>
            No
        </Button>
    );

    const onEditSave = async (user: ContextUser) => {
        await userInfoContext.updateUser(user);
        toggleModal(user.id);
    };

    const [editedRole, setEditedRole] = useState(userInfoContext.user.role);
    const [savedRole, setSavedRole] = useState(false);

    const handleRoleChange = (e: any) => {
        setEditedRole(e.target.value);
    };

    const editModalObj = {
        title: 'Edit User info',
        message: 'Update the user info',
        userId: userInfoContext.user.id,
        className: 'edit-modal',
        children: [
            <EditUserInfo
                key="modal"
                user={userInfoContext.user}
                onSave={onEditSave}
                hasDelete={userContext.orRights([RIGHTS_KEYS.manageAllUsers])}
            />,
        ],
    };

    const editRoleModal = {
        title: 'Edit User role',
        message: 'Update role of ' + userInfoContext.user.name,
        userId: userInfoContext.user.id,
        className: 'edit-modal',
        children: [
            <div className={editStyles.info}>
                {savedRole && <h3>User has been saved</h3>}
                {!savedRole && (
                    <form>
                        <label>Role</label>
                        <FormInput
                            tag="selectRole"
                            defaultValue={userInfoContext.user.role}
                            onChange={handleRoleChange}
                        />
                        <div className={styles.buttons}>
                            <Button
                                type={ButtonType.primary}
                                onClick={() => {
                                    updateDeptRole(
                                        userInfoContext.user.id,
                                        editedRole
                                    );
                                    setSavedRole(true);
                                    onChange(
                                        {
                                            ...userInfoContext.user,
                                            role: editedRole,
                                        },
                                        false
                                    );
                                    setTimeout(() => {
                                        setSavedRole(false);
                                        setOpenedModal('');
                                    }, 2000);
                                }}
                            >
                                Save
                            </Button>
                        </div>
                    </form>
                )}
            </div>,
        ],
    };

    const expiryModalObj = {
        title: 'Change expiry',
        message: 'Please choose a date for the license expiry',
        userId: userInfoContext.user.id,
        className: 'expiry-modal',
        children: [<DatePicker onSubmit={toggleModal} />],
    };

    const deactivateModalObj = {
        title: 'Deactivate user',
        message: 'Deactivate license for user ' + userInfoContext.user.email,
        userId: userInfoContext.user.id,
        className: 'deactivate-modal',
        children: [
            <div>
                {yesButton} {noButton}
            </div>,
        ],
    };

    const noLicenseReminder = {
        title: 'No Licenses',
        message:
            'Active users from your department have reached the maximum Licenses',
        userId: userInfoContext.user.id,
        className: 'Warning-modal',
        children: [<></>],
    };

    const openModal = (event: React.MouseEvent<HTMLElement>, type: string) => {
        setOpenedModal(type);
    };

    return (
        <React.Fragment>
            <td>
                {openedModal === 'edit' && (
                    <Modal info={editModalObj} updateView={toggleModal}>
                        {editModalObj.children}
                    </Modal>
                )}

                {openedModal === 'edit-role' && (
                    <Modal info={editRoleModal} updateView={toggleModal}>
                        {editRoleModal.children}
                    </Modal>
                )}

                {openedModal === 'expiry' ? (
                    isActive(userInfoContext.user) ||
                    deptLicenses > departmentContext.activeUsers.length ? (
                        <Modal info={expiryModalObj} updateView={toggleModal}>
                            {expiryModalObj.children}
                        </Modal>
                    ) : (
                        <Modal
                            info={noLicenseReminder}
                            updateView={toggleModal}
                        >
                            {noLicenseReminder.children}
                        </Modal>
                    )
                ) : null}
                {openedModal === 'deactivate' && (
                    <Modal info={deactivateModalObj} updateView={toggleModal}>
                        {deactivateModalObj.children}
                    </Modal>
                )}
                {openedModal === 'delete' && (
                    <DeleteUser onChange={onChange} onClose={toggleModal} />
                )}
                {new Date(userInfoContext.getExpiry()).toLocaleDateString(
                    'en-UK'
                )}
                <Button
                    key="expBtn"
                    onClick={openModal}
                    value={'expiry'}
                    className={styles['expiry']}
                    type={ButtonType.tietary}
                >
                    <FontAwesomeIcon icon={faPenToSquare} />
                </Button>
            </td>
            {userContext.orRights([RIGHTS_KEYS.manageAllUsers]) && (
                <td>
                    {userInfoContext.user.department?.name}
                    <div className={styles.buttons}>
                        <Button
                            key="editBtn"
                            onClick={openModal}
                            value={'edit'}
                            className={styles['edit-button']}
                            type={ButtonType.secondary}
                        >
                            Edit User info
                        </Button>
                    </div>
                </td>
            )}
            {userContext.orRights([RIGHTS_KEYS.manageEmployees]) && (
                <td>
                    <div className={styles.buttons}>
                        <Button
                            key="editRoleBtn"
                            onClick={openModal}
                            value={'edit-role'}
                            className={styles['edit-button']}
                            type={ButtonType.secondary}
                        >
                            Edit User info
                        </Button>
                    </div>
                </td>
            )}
            <td>
                {isActive(userInfoContext.user) && (
                    <Button
                        key="deact"
                        onClick={openModal}
                        value={'deactivate'}
                        className={styles['deactivate']}
                        type={ButtonType.tietary}
                    >
                        <FontAwesomeIcon
                            icon={faTrash}
                            color="var(--main-red)"
                        />
                    </Button>
                )}
                {!isActive(userInfoContext.user) &&
                    userContext.orRights([RIGHTS_KEYS.manageAllUsers]) && (
                        <Button
                            key="del"
                            onClick={openModal}
                            value={'delete'}
                            className={styles['delete']}
                            type={ButtonType.cancel}
                        >
                            Delete user
                        </Button>
                    )}
            </td>
        </React.Fragment>
    );
};

export default ExtendedInfoComponent;

import React, { useState } from 'react';
import Button, { ButtonType } from '../general-components/Button';
import styles from './ApprovalNote.module.css';

const ApprovalNote: React.FC<{
    defaultNote: string;
    onSubmit: Function;
    title: string;
    description?: string;
    placeholder?: string;
}> = ({ defaultNote, onSubmit, title, description, placeholder }) => {
    const [note, setNote] = useState(defaultNote);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async () => {
        setIsLoading(true);
        await onSubmit(note);
        setIsLoading(false);
    };

    return (
        <div className={styles.approvalBox}>
            <h4>{title}</h4>
            <p>{description}</p>
            <textarea
                value={note}
                onChange={(e) => setNote(e.target.value)}
                placeholder={placeholder}
            />
            <Button
                loading={isLoading}
                type={ButtonType.submit}
                onClick={handleSubmit}
            >
                Approve
            </Button>
        </div>
    );
};

export default ApprovalNote;

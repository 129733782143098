import React, { useEffect, useState } from 'react';
import styles from './Select.module.css';
import SelectItem from './SelectItem';

const Select: React.FC<{
    type: string;
    name: string;
    options: string[];
    defaultChecked?: string[];
    changeSelected?: Function;
    isRequired?: boolean;
    errorMessage?: string;
}> = ({
    type,
    name,
    options,
    changeSelected,
    defaultChecked,
    isRequired,
    errorMessage,
}) => {
    const [selectOccured, setSelectOccured] = useState(false);
    const [showError, setShowError] = useState(false);

    const handleChange = (value: string, _checked: boolean) => {
        changeSelected(value);
        setSelectOccured(true);
        setShowError(false);
    };

    useEffect(() => {
        if (errorMessage && isRequired && !selectOccured) {
            setShowError(true);
        }
    }, [errorMessage]);

    return (
        <>
            {options && (
                <div className={styles.wrapper}>
                    {options.map((o) => (
                        <SelectItem
                            error={showError}
                            key={o}
                            checked={defaultChecked?.includes(o)}
                            className={styles.item}
                            onChange={handleChange}
                            value={o}
                            type={type}
                        />
                    ))}
                    {showError && (
                        <span className={styles.errorMessage}>
                            {errorMessage}
                        </span>
                    )}
                </div>
            )}
        </>
    );
};

export default Select;

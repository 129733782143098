import { ReactNode } from 'react';
import ModalLayout from './ModalLayout';
import styles from './VerificationModal.module.css';
import Button, { ButtonType } from '../../general-components/Button';

const VerificationModal: React.FC<{
    comment?: ReactNode;
    children?: ReactNode;
    onVerify: () => void;
    onClose: () => void;
}> = ({ children, onVerify, comment, onClose }) => {
    return (
        <ModalLayout hasCloseButton size="large" onClose={onClose}>
            <div className={styles.wrapper}>
                <div className={styles.content}>{children}</div>
                <div className={styles.bar}>
                    <div className={styles.comment}>{comment}</div>
                    <Button
                        className={styles.button}
                        type={ButtonType.submit}
                        onClick={onVerify}
                    >
                        Verify
                    </Button>
                </div>
            </div>
        </ModalLayout>
    );
};

export default VerificationModal;

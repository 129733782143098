import { ReactNode } from 'react';
import styles from './ModalLayout.module.css';
import { Close } from '@mui/icons-material';

interface ModalLayoutProps {
    children: ReactNode;
    hasCloseButton?: boolean;
    onClose: () => void;
    size?: 'small' | 'medium' | 'large' | 'xl';
    important?: boolean;
    bgBlur?: boolean;
}

const ModalLayout: React.FC<ModalLayoutProps> = ({
    hasCloseButton,
    children,
    onClose,
    size = 'small',
    important = false,
    bgBlur = false,
}) => {
    return (
        <div
            className={`${styles.wrapper}  ${styles[size]} ${
                important ? styles.important : ''
            } ${bgBlur ? styles.bgBlur : ''}`}
        >
            <div className={styles.box}>
                {hasCloseButton && (
                    <div className={styles.close} onClick={onClose}>
                        <Close />
                    </div>
                )}
                <div className={styles.content}>{children}</div>
            </div>
        </div>
    );
};

export default ModalLayout;

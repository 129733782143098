import { useState } from 'react';
import {
    loadAllGudidImplants,
    loadLatestGudidImplants,
    startGudidWorker,
    stopGudidWorker,
} from '../../services/apiService';
import Button, { ButtonType } from '../general-components/Button';
import RecurringJobProgress from '../ui-components/RecurringJobProgress';

const GudidLoad = () => {
    const [showProg, setShowProg] = useState(false);
    const [msg, setMsg] = useState('');

    const getLatestData = async () => {
        const res = await loadLatestGudidImplants();

        setMsg(res);
    };

    const getAllData = async () => {
        const res = await loadAllGudidImplants();

        setMsg(res);
    };

    const startWorker = async () => {
        const res = await startGudidWorker();

        console.log(res);

        setMsg(res);
    };

    const stopWorker = async () => {
        const res = await stopGudidWorker();

        console.log(res);

        setMsg(res);
    };

    return (
        <div>
            <Button type={ButtonType.primary} onClick={getLatestData}>
                Load Latest GUDID
            </Button>
            <br></br>
            <Button type={ButtonType.primary} onClick={getAllData}>
                Load ALL GUDID
            </Button>
            <br></br>
            <Button type={ButtonType.submit} onClick={startWorker}>
                Run Gudid
            </Button>
            <br></br>
            <Button type={ButtonType.cancel} onClick={stopWorker}>
                Stop Gudid
            </Button>
            <br></br>
            <Button type={ButtonType.primary} onClick={() => setShowProg(true)}>
                Get Progress
            </Button>
            <h2>{msg}</h2>
            {showProg && (
                <>
                    <RecurringJobProgress name="GUDID" />
                </>
            )}
        </div>
    );
};

export default GudidLoad;

import React, { useEffect, useState } from 'react';
import {
    ProgressStatus,
    useCPTReportContext,
} from '../../store/cptReport-context';
import Assessment from './report-flow/Assessment';
import Button, { ButtonType } from '../general-components/Button';
import ImplantDetails from './report-flow/ImplantDetails';
import Guidance from './report-flow/Guidance';
import styles from './ReportWizard.module.css';
import SETTING_KEYS from '../../constants/setting-keys';
import ForeignBodyDetails from './report-flow/ForeignBodyDetails';
import PurposeOfSafetyWork from './report-flow/PurposOfSafetyWork';
import RiskAnalysis from './report-flow/RiskAnalysis';
import Benefits from './report-flow/Benefits';
import {
    getBenefitsFromClinicalIndication,
    getRiskFromDetails,
} from '../../services/api/cptService';
import ReportProgress from './progress/ReportProgress';
import ScannerSettings from './report-flow/ScannerSettings';
import Preview, { formatConditionals, formatMRIParameters } from './Preview';
import { ReactComponent as DoneIcon } from '../../icons/form-submitted.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faNotesMedical } from '@fortawesome/free-solid-svg-icons';
import ConfirmActionButton from '../general/confirmation/ConfirmActionButton';

const ReportWizard: React.FC<{
    implant?: any;
}> = ({ implant }) => {
    const {
        dispatchReport,
        report,
        dispatchError,
        progress,
        setProgress,
        saveCurrentReport,
        viewState,
        setReportModuleOpen,
        fetchUserInfo,
        isReportModuleOpen,
    } = useCPTReportContext();
    const [currentStep, setCurrentStep] = useState<string>('assessment');
    const [previousSteps, setPreviousSteps] = useState<string[]>([]);
    const [isSaving, setIsSaving] = useState(false);

    const [code, setCode] = useState(report.info.purpose);
    const [recommendation, setRecommendation] = useState(
        report.guidance.recommendation
    );
    useEffect(() => {
        setRecommendation(report.guidance.recommendation);
        setCode(report.info.purpose);
    }, [report]);

    useEffect(() => {
        if (['preview', 'approval'].includes(viewState)) {
            setCurrentStep('preview');
        } else {
            setCurrentStep('assessment');
        }
    }, [viewState]);

    useEffect(() => {
        if (
            recommendation ==
            'Protocol Modifications and Risk/Benefit Analysis needed (CPT 76016)'
        ) {
            setProgress({
                ...progress,
                '76016': 'In Progress',
            });
        } else {
            setProgress({
                ...progress,
                '76016': 'Not Started',
            });
        }
    }, [recommendation]);

    useEffect(() => {
        if (currentStep === 'preview') {
            const updatedProgress = Object.keys(progress).reduce((acc, key) => {
                if (progress[key] === 'In Progress') {
                    acc[key] = 'Finished';
                } else {
                    acc[key] = progress[key];
                }
                return acc;
            }, {} as { [key: string]: ProgressStatus });
            setProgress(updatedProgress);
        } else {
            const updatedProgress = Object.keys(progress).reduce((acc, key) => {
                if (progress[key] === 'Finished') {
                    acc[key] = 'In Progress';
                } else {
                    acc[key] = progress[key];
                }
                return acc;
            }, {} as { [key: string]: ProgressStatus });
            setProgress(updatedProgress);
        }
    }, [currentStep]);

    useEffect(() => {
        if (implant) {
            if (report.info.purpose === '') {
                dispatchReport({
                    type: 'UPDATE',
                    payload: {
                        name: 'info.purpose',
                        value: 'Implant',
                    },
                });
            }
            if (report.mriSafetyDecisionInfo.implantDetails.model === '') {
                dispatchReport({
                    type: 'UPDATE',
                    payload: {
                        name: 'mriSafetyDecisionInfo.implantDetails',
                        value: {
                            model: implant.info.Model,
                            type: implant.info.Type,
                            manufacturer: implant.info.Manufacturer,
                        },
                    },
                });
            }
            if (report.conditionalsAnalysis.mriStatus === '') {
                dispatchReport({
                    type: 'UPDATE',
                    payload: {
                        name: 'conditionalsAnalysis',
                        value: {
                            mriStatus: implant.info[SETTING_KEYS.mriStatus],
                            restrictions: formatConditionals(
                                {
                                    ...formatMRIParameters(
                                        implant.conditionals
                                    ),
                                    ...(implant.info['Custom fields']?.reduce(
                                        (acc: any, field: any) => {
                                            acc[field.heading] = field.value;
                                            return acc;
                                        },
                                        {}
                                    ) || {}),
                                    Notes: implant.info.Notes || '',
                                },
                                2
                            ),
                        },
                    },
                });
            }
        }
    }, [implant]);

    const renderStep = () => {
        switch (currentStep) {
            case 'assessment':
                return (
                    <div className={styles.step}>
                        <h3 className={styles.stepTitle}>
                            Written Report Details
                        </h3>
                        <Assessment implant={implant} />
                    </div>
                );
            case 'implantDetails':
                return (
                    <div className={styles.step}>
                        <h3 className={styles.stepTitle}>Implant Details</h3>
                        <ImplantDetails implant={implant} />
                    </div>
                );
            case 'foreignBodyDetails':
                return (
                    <div className={styles.step}>
                        <h3 className={styles.stepTitle}>
                            Foreign Body Details
                        </h3>
                        <ForeignBodyDetails />
                    </div>
                );
            case 'purposeOfSafetyWork':
                return (
                    <div className={styles.step}>
                        <h3 className={styles.stepTitle}>
                            Purpose of Safety Work
                        </h3>
                        <PurposeOfSafetyWork />
                    </div>
                );
            case 'riskAnalysis':
                return (
                    <div className={styles.step}>
                        <h3 className={styles.stepTitle}>Risk Analysis</h3>
                        <RiskAnalysis />
                    </div>
                );
            case 'benefits':
                return (
                    <div className={styles.step}>
                        <h3 className={styles.stepTitle}>Benefits</h3>
                        <Benefits />
                    </div>
                );
            case 'scannerSettings':
                return (
                    <div className={styles.step}>
                        <h3 className={styles.stepTitle}>Scanner Settings</h3>
                        <ScannerSettings />
                    </div>
                );
            case 'guidance':
                return (
                    <div className={styles.step}>
                        <h3 className={styles.stepTitle}>
                            Professional Guidance & Recommendations
                        </h3>
                        <Guidance />
                    </div>
                );
            case 'preview':
                return (
                    <Preview
                        report={report}
                        isApprove={viewState === 'approval'}
                    />
                );
            case 'confirm':
                return (
                    <div className={styles.success}>
                        <h2>Report has successfully been created</h2>
                        <DoneIcon />
                        <div className={styles.buttons}>
                            <Button
                                type={ButtonType.primary}
                                onClick={() =>
                                    (window.location.href =
                                        '/cpt-report/manage')
                                }
                            >
                                <FontAwesomeIcon
                                    icon={faArrowLeft}
                                    color="black"
                                />{' '}
                                Go to Overview
                            </Button>
                            <Button
                                type={ButtonType.submit}
                                onClick={() =>
                                    (window.location.href = '/cpt-report/new')
                                }
                            >
                                <FontAwesomeIcon
                                    icon={faNotesMedical}
                                    color="var(--main-white)"
                                />{' '}
                                New Report
                            </Button>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    const validateStep = () => {
        if (report.info.purpose === '') {
            dispatchError({
                type: 'SET_ERROR',
                payload: {
                    name: 'mriSafetyDecisionInfo.assessment.details.purpose',
                    message: 'Please select a purpose',
                },
            });
            return false;
        }
        return true;
    };

    const handleNext = () => {
        if (validateStep()) {
            setPreviousSteps([...previousSteps, currentStep]);
            if (currentStep === 'assessment') {
                if (code === 'Implant') {
                    setCurrentStep('implantDetails');
                } else if (code === 'Foreign Body') {
                    setCurrentStep('foreignBodyDetails');
                } else {
                    dispatchError({
                        type: 'SET_ERROR',
                        payload: {
                            name: 'mriSafetyDecisionInfo.assessment.details.purpose',
                            message: 'Please select a purpose',
                        },
                    });
                }
            }
            if (
                currentStep === 'implantDetails' ||
                currentStep === 'foreignBodyDetails'
            ) {
                // fetchRisk();
                setCurrentStep('guidance');
            }
            if (currentStep === 'guidance') {
                if (
                    recommendation ===
                    'Protocol Modifications and Risk/Benefit Analysis needed (CPT 76016)'
                ) {
                    setCurrentStep('purposeOfSafetyWork');
                } else {
                    setCurrentStep('preview');
                }
            }
            if (currentStep === 'purposeOfSafetyWork') {
                // fetchBenefits();
                setCurrentStep('riskAnalysis');
            }
            if (currentStep === 'riskAnalysis') {
                setCurrentStep('scannerSettings');
            }
            if (currentStep === 'scannerSettings') {
                setCurrentStep('benefits');
            }
            if (currentStep === 'benefits') {
                setCurrentStep('preview');
            }
            // if (currentStep === 'preview' && viewState === 'new') {
            //     setCurrentStep('confirm');
            // }
        }
    };

    const handlePrevious = () => {
        setCurrentStep(previousSteps[previousSteps.length - 1]);
        setPreviousSteps(previousSteps.slice(0, -1));
    };

    const showNextButton = () => {
        return (
            !['preview', 'benefits', 'guidance'].includes(currentStep) ||
            (currentStep === 'guidance' &&
                recommendation ===
                    'Protocol Modifications and Risk/Benefit Analysis needed (CPT 76016)')
        );
    };

    const showPreviewButton = () => {
        return (
            (currentStep === 'guidance' &&
                recommendation !==
                    'Protocol Modifications and Risk/Benefit Analysis needed (CPT 76016)') ||
            currentStep === 'benefits'
        );
    };

    const showPreviousButton = () => {
        return previousSteps.length > 0;
    };

    return (
        <div
            className={`${styles.container} ${
                ['preview', 'approval'].includes(viewState)
                    ? styles.preview
                    : ''
            } ${viewState === 'approval' ? styles.approval : ''} ${
                !isReportModuleOpen ? styles.fullscreen : ''
            }`}
        >
            {['edit', 'new'].includes(viewState) &&
                currentStep !== 'confirm' && (
                    <aside className={styles.aside}>
                        <ReportProgress />
                        <div className={styles.actions}>
                            {currentStep !== 'preview' && (
                                <ConfirmActionButton
                                    buttonText="Reset"
                                    buttonType={ButtonType.action}
                                    modalInfo={{
                                        title: 'Reset CPT report?',
                                        message:
                                            'Report has been reset. Please fill in the details again.',
                                        submitButtonText: 'Reset',
                                        submitButtonDanger: true,
                                    }}
                                    onConfirm={() => {
                                        dispatchReport({
                                            type: 'RESET',
                                        });
                                        // setProgress({});
                                        fetchUserInfo(true);
                                        setPreviousSteps([]);
                                        setCurrentStep('assessment');
                                    }}
                                >
                                    This action cannot be undone.
                                </ConfirmActionButton>
                            )}
                            {currentStep === 'preview' && (
                                <>
                                    <Button
                                        small
                                        type={ButtonType.primary}
                                        onClick={() =>
                                            (window.location.href =
                                                '/cpt-report/manage')
                                        }
                                    >
                                        <FontAwesomeIcon
                                            icon={faArrowLeft}
                                            color="black"
                                        />{' '}
                                        Go to Overview
                                    </Button>
                                    {viewState === 'new' && (
                                        <Button
                                            small
                                            type={ButtonType.submit}
                                            onClick={() =>
                                                (window.location.href =
                                                    '/cpt-report/new')
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faNotesMedical}
                                                color="var(--main-white)"
                                            />{' '}
                                            New Report
                                        </Button>
                                    )}
                                </>
                            )}
                        </div>
                    </aside>
                )}
            {renderStep()}
            <div
                className={`${styles.nav} ${
                    currentStep === 'assessment' ? styles.front : ''
                }`}
            >
                <div className={styles.prevBtn}>
                    {showPreviousButton() && (
                        <Button
                            onClick={handlePrevious}
                            type={ButtonType.primary}
                        >
                            Previous
                        </Button>
                    )}
                </div>
                <div className={styles.endBtn}>
                    {showNextButton() && (
                        <Button onClick={handleNext} type={ButtonType.primary}>
                            Next
                        </Button>
                    )}
                    {/* {showPreviewButton() && (
                        <Button
                            onClick={handleNext}
                            type={ButtonType.secondary}
                        >
                            Preview Report
                        </Button>
                    )} */}
                    {((showPreviewButton() && viewState === 'new') ||
                        viewState === 'edit') && (
                        <Button
                            loading={isSaving}
                            onClick={async () => {
                                setIsSaving(true);
                                await saveCurrentReport();
                                setIsSaving(false);
                                if (viewState === 'new') {
                                    handleNext();
                                } else {
                                    setReportModuleOpen(false);
                                }
                            }}
                            type={ButtonType.submit}
                        >
                            {viewState === 'new' && !report._id
                                ? 'Create Report'
                                : 'Save Edits'}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ReportWizard;

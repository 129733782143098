import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../../store/auth-context';
import styles from './Comment.module.css';
import {
    createComment,
    deleteComment,
    editComment,
    getActorsWithCommentPermission,
    getImplantComments,
} from '../../../services/apiService';
import CommentItem from './CommentItem';
import CommentForm from './CommentForm';
import { sanitizeAndFormatTextareaInput } from '../../../utils/utils';
import { IComment } from './CommentModel';

const Comment: React.FC<{
    implantId: string;
    isDesktop: boolean;
}> = ({ implantId, isDesktop }) => {
    const [authUser, setAuthUser] = useState<any>([]);
    const [commentPermissions, setCommentPermissions] = useState([]);
    const [canComment, setCanComment] = useState<boolean>(false);

    const [comments, setComments] = useState<IComment[]>([]);

    const [isLoading, setIsLoading] = useState(true);

    const ctx = useContext(AuthContext);
    const context = ctx.getContext();

    const getComments = async () => {
        const user = await ctx?.getAllInfo();
        const fetchedComments = await getImplantComments(implantId);
        setAuthUser(user.user);
        setComments(fetchedComments);
        setIsLoading(false);
    };

    const getPermission = async () => {
        const resposne = await getActorsWithCommentPermission();
        setCommentPermissions(resposne);
    };

    const getAuthUser = async () => {
        const authUser = await ctx?.getAllInfo();
        const { actor_id } = authUser?.user;
        setIsLoading(false);
        const permissionIds = commentPermissions.map(
            (permission) => permission._id
        );
        if (permissionIds.includes(actor_id)) {
            setCanComment(true);
        } else {
            setCanComment(false);
        }
    };

    const onSubmitNewComment = async (commentText: string) => {
        if (!commentText.trim()) {
            return;
        }
        const formattedCommentInput =
            sanitizeAndFormatTextareaInput(commentText);
        const newCommentObj = await createComment(
            implantId,
            formattedCommentInput
        );
        setComments((prevComments) => [...prevComments, newCommentObj]);
    };

    const onSubmitEditedComment = async (
        commentText: string,
        id: string
    ): Promise<void> => {
        if (commentText.trim() === '' || commentText.length <= 0) {
            return;
        }
        const formattedCommentInput =
            sanitizeAndFormatTextareaInput(commentText);
        const newEditCommentObj = await editComment(id, formattedCommentInput);
        setComments((prevComments) =>
            prevComments.map((c) =>
                c._id === id ? { ...c, comment: newEditCommentObj.comment } : c
            )
        );
    };

    const onDeleteComment = async (commentId: string) => {
        await deleteComment(commentId);
        const updatedCommentList = comments.filter(
            (comment) => comment._id !== commentId
        );
        setComments(updatedCommentList);
    };

    useEffect(() => {
        getComments();
    }, []);

    useEffect(() => {
        getPermission();
    }, []);

    useEffect(() => {
        getAuthUser();
    }, [commentPermissions]);

    return (
        <>
            {(isDesktop || comments.length > 0) && (
                <section className={styles['comment-section']}>
                    <h3 className={styles['comment-title']}>
                        {context.department.name} Notes
                    </h3>
                    <p className={styles['comment-section-prompt']}>
                        Comments here are only visible to you and your
                        colleagues
                    </p>
                    {!isLoading && comments.length > 0 && (
                        <div className={styles['comments-container']}>
                            {comments.map((comment) => (
                                <div key={comment._id}>
                                    <CommentItem
                                        comment={comment}
                                        authUser={authUser}
                                        onDelete={onDeleteComment}
                                        onSave={onSubmitEditedComment}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                    {!isLoading && comments.length === 0 && (
                        <h4 className={styles.nocomments}>No comments yet.</h4>
                    )}
                    {canComment ? (
                        <CommentForm
                            placeholder="Add new comment"
                            onSubmit={onSubmitNewComment}
                        />
                    ) : (
                        <h4 className={styles.nocomments}>
                            You do not have access to adding comments for your
                            department.
                        </h4>
                    )}
                </section>
            )}
        </>
    );
};

export default Comment;

import FormInput from '../../general-components/FormInput';
import Select from '../../general-components/Select';
import { useCPTReportContext } from '../../../store/cptReport-context';
import styles from '../ReportWizard.module.css';
import { autofill, getNestedValue } from '../../../services/cptAutofillService';
import { useEffect } from 'react';
import Button, { ButtonType } from '../../general-components/Button';

const PurposeOfSafetyWork = () => {
    const {
        dispatchReport,
        report,
        getHandleEventChange,
        fetchRisk,
        canFetchRisk,
        isFetchingRisk,
    } = useCPTReportContext();

    useEffect(() => {
        if (report.safetyWork?.purpose === '') {
            const purposeFills = getNestedValue(autofill, 'safetyWork.purpose');
            dispatchReport({
                type: 'UPDATE',
                payload: {
                    name: 'safetyWork.purpose',
                    value: purposeFills[report.safetyWork?.purpose],
                },
            });
        }
    }, []);

    return (
        <div className={styles.form}>
            <div className={styles.row}>
                <div>
                    <h5>Purpose of Pre-Scan Safety Work</h5>
                    <FormInput
                        label="Purpose of Pre-Scan Safety Work"
                        tag="textArea"
                        name="purpose"
                        defaultValue={report.safetyWork?.purpose}
                        onChange={getHandleEventChange('safetyWork.purpose')}
                    />
                </div>
            </div>
            <div className={styles.row}>
                <FormInput
                    label="Description of Safety Objectives"
                    tag="textArea"
                    name="objectives"
                    defaultValue={report.safetyWork?.objectives}
                    onChange={getHandleEventChange('safetyWork.objectives')}
                />
            </div>
            <div className={styles.row}>
                <FormInput
                    label="Clinical Indication"
                    tag="textArea"
                    name="clinicalIndication"
                    defaultValue={report.safetyWork?.clinicalIndication}
                    onChange={getHandleEventChange(
                        'safetyWork.clinicalIndication'
                    )}
                />
            </div>
            <div className={styles.row}>
                <div className={styles.formField}>
                    <div className={styles.formFieldHeader}>
                        <label>Identified Risks</label>
                        <Button
                            disabled={!canFetchRisk()}
                            small
                            type={ButtonType.primary}
                            onClick={fetchRisk}
                            loading={isFetchingRisk}
                        >
                            Generate with AI
                        </Button>
                        {!canFetchRisk() &&
                            report.mriSafetyDecisionInfo?.safetyAnalysis
                                ?.risk === '' && (
                                <span
                                    style={{
                                        fontSize: '12px',
                                        opacity: 0.8,
                                    }}
                                >
                                    {report.info.purpose === 'Implant'
                                        ? '(Only available for Foreign Body)'
                                        : '(Need to fill all Foreign Body details on previous page)'}
                                </span>
                            )}
                    </div>
                    <FormInput
                        tag="textArea"
                        name="identifiedRisks"
                        defaultValue={
                            report.mriSafetyDecisionInfo?.safetyAnalysis?.risk
                        }
                        onChange={getHandleEventChange(
                            'mriSafetyDecisionInfo.safetyAnalysis.risk'
                        )}
                    />
                </div>
            </div>
        </div>
    );
};

export default PurposeOfSafetyWork;

import { useEffect } from 'react';
import { useCPTReportContext } from '../../store/cptReport-context';
import ReportWizard from './ReportWizard';
import styles from './ReportWizardPage.module.css';
const ReportWizardPage = () => {
    const { setViewState } = useCPTReportContext();

    useEffect(() => {
        setViewState('new');
    }, []);

    return (
        <div className={styles.container}>
            <ReportWizard />
        </div>
    );
};

export default ReportWizardPage;

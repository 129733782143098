import { useContext, useEffect, useState } from 'react';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '../../../components/ui/select';
import { Label } from '../../../components/ui/label';
import { useCPTReportContext } from '../../../store/cptReport-context';
import { Button } from '../../ui/button';
import { Input } from '../../../components/ui/input';
import { v4 as uuidv4 } from 'uuid';

import styles from './TimeLog.module.css';
import { faAdd, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TimeLog } from '../../../services/api/cptService';

const tasks = [
    'Reviewed EHR report',
    'Called hospital',
    'Consulted radiologist',
    'Checked implant database',
    'Performed or ordered additional',
    'Other',
];

const TimeLogItem = ({
    id,
    task,
    time,
}: {
    id: string;
    task: string;
    time: number;
}) => {
    const { report, dispatchReport } = useCPTReportContext();

    const [isCustom, setIsCustom] = useState(false);

    useEffect(() => {
        if (!tasks.includes(task) && task !== '') {
            setIsCustom(true);
        }
    }, [task]);

    const sumTimelog = (log: TimeLog[]) => {
        dispatchReport({
            type: 'UPDATE',
            payload: {
                name: 'summary.totalTimeCPTCodes',
                value: log.reduce((acc, t) => acc + t.time, 0),
            },
        });
    };

    const handleTaskChange = async (
        listSelection: boolean,
        id: string,
        task: string,
        time: number
    ) => {
        if (listSelection) {
            if (task === 'Other') {
                setIsCustom(true);
                task = '';
            } else if (tasks.filter((t) => t !== 'Other').includes(task)) {
                setIsCustom(false);
            }
        }

        const newLog = [
            ...report.timeLog.map((t) =>
                t.id === id ? { ...t, task, time } : t
            ),
        ];
        await dispatchReport({
            type: 'UPDATE',
            payload: {
                name: 'timeLog',
                value: newLog,
            },
        });
        sumTimelog(newLog);
    };

    const handleTaskDelete = async () => {
        const newLog = report.timeLog.filter((t) => t.id !== id);
        await dispatchReport({
            type: 'UPDATE',
            payload: {
                name: 'timeLog',
                value: newLog,
            },
        });
        sumTimelog(newLog);
    };

    return (
        <div key={id} className={styles.action}>
            <div className={styles.select}>
                <Select
                    defaultValue={
                        !tasks.includes(task) && task !== '' ? 'Other' : task
                    }
                    onValueChange={(value) =>
                        handleTaskChange(true, id, value, time)
                    }
                >
                    <SelectTrigger className="w-[180px]">
                        <SelectValue placeholder="Task" />
                    </SelectTrigger>
                    <SelectContent>
                        {tasks.map((task) => (
                            <SelectItem value={task}>{task}</SelectItem>
                        ))}
                    </SelectContent>
                </Select>
                {isCustom && (
                    <Input
                        type="text"
                        value={task}
                        onChange={(e) =>
                            handleTaskChange(false, id, e.target.value, time)
                        }
                    />
                )}
            </div>
            <Input
                type="number"
                value={time}
                onChange={(e) =>
                    handleTaskChange(false, id, task, parseInt(e.target.value))
                }
            />
            Minutes
            <button onClick={handleTaskDelete}>
                <FontAwesomeIcon icon={faXmark} color="red" />
            </button>
        </div>
    );
};

export default TimeLogItem;

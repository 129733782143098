import { useState } from 'react';
import EditInternalProtocol from './EditInternalProtocol';
import styles from './AddInternalProtocol.module.css';
import { ReactComponent as DoneIcon } from '../../icons/form-submitted.svg';
import Button, { ButtonType } from '../general-components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faNotesMedical } from '@fortawesome/free-solid-svg-icons';

const AddInternalProtocol = () => {
    const [showSuccess, seteShowSuccess] = useState(false);
    return (
        <div className={styles.wrapper}>
            {showSuccess && (
                <div className={styles.success}>
                    <h2>Protocol has successfully been saved</h2>
                    <DoneIcon />
                    <div className={styles.buttons}>
                        <Button
                            type={ButtonType.primary}
                            onClick={() =>
                                (window.location.href = '/protocols')
                            }
                        >
                            <FontAwesomeIcon icon={faArrowLeft} color="black" />{' '}
                            Go to Overview
                        </Button>
                        <Button
                            type={ButtonType.submit}
                            onClick={() =>
                                (window.location.href = '/protocols/new')
                            }
                        >
                            <FontAwesomeIcon
                                icon={faNotesMedical}
                                color="var(--main-white)"
                            />{' '}
                            New Protocol
                        </Button>
                    </div>
                </div>
            )}
            {!showSuccess && (
                <EditInternalProtocol
                    isNew={true}
                    onSubmit={() => seteShowSuccess(true)}
                />
            )}
        </div>
    );
};

export default AddInternalProtocol;

import React, { useContext, useEffect, useState } from 'react';
import { useCPTReportContext } from '../../../store/cptReport-context';
import ConditionalsTable from '../../implant/conditionals/ConditionalsTable';
import Button, { ButtonType } from '../../general-components/Button';
import styles from './ManageCPTReportsPage.module.css';
import {
    CPTReportDTO,
    deleteCPTReport,
    getDeptCPTReports,
} from '../../../services/api/cptService';
import ReportModal from '../ReportModal';
import CPTReportButton from '../CPTReportButton';
import ConfirmActionButton from '../../general/confirmation/ConfirmActionButton';
import AuthContext from '../../../store/auth-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

const ManageCPTReportsPage = () => {
    const context = useContext(AuthContext);

    const [reports, setReports] = useState<CPTReportDTO[]>([]);

    const fetchReports = async () => {
        const res = await getDeptCPTReports();
        console.log(res);
        setReports(res);
    };

    useEffect(() => {
        fetchReports();
    }, []);

    const handleDelete = async (id: string) => {
        await deleteCPTReport(id);
        fetchReports();
    };

    return (
        <div className={styles.container}>
            <ReportModal />
            <div className={styles.header}>
                <h2>Manage CPT Reports</h2>
                <div className={styles.cptReportButton}>
                    <CPTReportButton type="new" isPage />
                </div>
            </div>
            {reports.filter((report) => report.status === 'In Progress')
                .length > 0 && (
                <div className={styles.tableContainer}>
                    <h3>In Progress</h3>
                    <ConditionalsTable
                        sizes={[1, 1, 1, 1, 1, 2]}
                        hasSeeMore={false}
                        headers={[
                            'Report ID',
                            'Codes',
                            'Purpose',
                            'Author',
                            'Date of Assessment',
                            'Actions',
                        ]}
                        fields={reports
                            .filter((report) => report.status === 'In Progress')
                            .map((report, index) => [
                                <>
                                    <CPTReportButton
                                        type="preview"
                                        id={report._id}
                                        reportId={report.reportId}
                                    />
                                    <Button
                                        small={true}
                                        type={ButtonType.action}
                                        onClick={() =>
                                            navigator.clipboard.writeText(
                                                report.reportId
                                            )
                                        }
                                    >
                                        <FontAwesomeIcon icon={faCopy} />
                                    </Button>
                                </>,
                                <>{report.summary?.codes?.join(', ')}</>,
                                report.info.purpose,
                                report.info.name,
                                new Date(
                                    report.info.dateOfAssessment
                                ).toLocaleDateString(),
                                <>
                                    {!report.approval ||
                                    report.approval.approved === false ? (
                                        <>
                                            {context.hasPermission(
                                                'Approve CPT Report'
                                            ) && (
                                                <CPTReportButton
                                                    type="approval"
                                                    id={report._id}
                                                />
                                            )}
                                            <CPTReportButton
                                                type="edit"
                                                id={report._id}
                                            />
                                            <ConfirmActionButton
                                                buttonText="Delete"
                                                buttonType={ButtonType.cancel}
                                                modalInfo={{
                                                    title: 'Delete CPT report?',
                                                    message:
                                                        'Report has been deleted.',
                                                    submitButtonText: 'Delete',
                                                    submitButtonDanger: true,
                                                }}
                                                onConfirm={() => {
                                                    handleDelete(report._id);
                                                }}
                                            >
                                                This action cannot be undone.
                                            </ConfirmActionButton>
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </>,
                            ])}
                    />
                </div>
            )}
            {reports.filter((report) => report.status === 'Approved').length >
                0 && (
                <div className={styles.tableContainer}>
                    <h3>Approved</h3>
                    <ConditionalsTable
                        hasSeeMore={false}
                        headers={[
                            'Report ID',
                            'Codes',
                            'Purpose',
                            'Author',
                            'Date of Assessment',
                            'Approved by',
                            'Comments',
                        ]}
                        fields={reports
                            .filter((report) => report.status === 'Approved')
                            .map((report, index) => [
                                <>
                                    <CPTReportButton
                                        type="preview"
                                        id={report._id}
                                        reportId={report.reportId}
                                    />
                                    <Button
                                        small={true}
                                        type={ButtonType.action}
                                        onClick={() =>
                                            navigator.clipboard.writeText(
                                                report.reportId
                                            )
                                        }
                                    >
                                        <FontAwesomeIcon icon={faCopy} />
                                    </Button>
                                </>,
                                <>{report.summary?.codes?.join(', ')}</>,
                                report.info.purpose,
                                report.info.name,
                                new Date(
                                    report.info.dateOfAssessment
                                ).toLocaleDateString(),
                                <>
                                    {report.approval
                                        ? report.approval.by?.name
                                        : ''}
                                </>,
                                report.approval?.notes,
                            ])}
                    />
                </div>
            )}
        </div>
    );
};

export default ManageCPTReportsPage;

import {
    faArrowRight,
    faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CancelIcon from '@mui/icons-material/Cancel';
import styles from './SearchField.module.css';

const SearchField = ({
    searchTerm,
    setSearchTerm,
    onChange,
    onKeyDown,
    search,
    placeholder,
    hasSubmitButton = true,
}: {
    searchTerm: string;
    setSearchTerm: (searchTerm: string) => void;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    search: () => void;
    placeholder?: string;
    hasSubmitButton?: boolean;
}) => {
    return (
        <div className={styles.search}>
            <div className={styles.icon}>
                <FontAwesomeIcon icon={faMagnifyingGlass} />{' '}
            </div>
            <input
                value={searchTerm}
                autoComplete="off"
                placeholder={placeholder ?? 'Search'}
                onChange={onChange}
                onKeyDown={onKeyDown}
            />
            {searchTerm.length > 0 && (
                <button
                    className={`${styles.cancel} ${
                        hasSubmitButton ? '' : styles.cancelWithoutSubmit
                    }`}
                    onClick={() => setSearchTerm('')}
                >
                    <CancelIcon />
                </button>
            )}
            {hasSubmitButton && (
                <button
                    id="search-button"
                    className={styles.submit}
                    onClick={search}
                >
                    <FontAwesomeIcon icon={faArrowRight} color="white" />{' '}
                </button>
            )}
        </div>
    );
};

export default SearchField;

import { ReactNode, useState } from 'react';
import styles from './ComponentSelectList.module.css';
import SelectItem from '../../general-components/SelectItem';
import CardComponent from '../Cards/CardComponent';

type SelectListItem = {
    title: string;
    description: string;
    preview: ReactNode;
    id: string;
    selected: boolean;
};

const SelectListItem: React.FC<{
    item: SelectListItem;
    onChange: (id: string, selected: boolean) => void;
    checkType: 'checkbox' | 'radio';
}> = ({ item, onChange, checkType }) => {
    const [defaultChecked, setDefaultChecked] = useState(item.selected);

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        if ((e.target as HTMLElement).tagName !== 'INPUT') {
            onChange(item.id, !defaultChecked);
            setDefaultChecked(!defaultChecked);
        }
    };

    return (
        <li key={item.id} onClick={handleClick}>
            <CardComponent
                title={item.title}
                description={item.description}
                preview={item.preview}
            >
                <SelectItem
                    checked={defaultChecked}
                    labelled={false}
                    type={checkType}
                    value={item.id}
                    // onChange={onChange}
                />
            </CardComponent>
        </li>
    );
};

const ComponentSelectList: React.FC<{
    checkType?: 'checkbox' | 'radio';
    items: SelectListItem[];
    onChange: (id: string, selected: boolean) => void;
}> = ({ items, onChange, checkType = 'checkbox' }) => {
    return (
        <div className={styles.wrapper}>
            <ul>
                {items.map((item) => (
                    <SelectListItem
                        key={item.id}
                        item={item}
                        onChange={onChange}
                        checkType={checkType}
                    />
                ))}
            </ul>
        </div>
    );
};

export default ComponentSelectList;

import { instance } from '../apiService';
import { GETREQUESTOPTIONS } from '../apiService';

export const getImplantsLinkedToProtocol = async (protocolId: string) => {
    const response = await instance.get(
        `/api/internal-protocols/${protocolId}/linked-implants`,
        GETREQUESTOPTIONS()
    );
    return response.data;
};

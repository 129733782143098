import React, { useState, useEffect } from 'react';
import { getDeparimentInfo } from '../services/apiService';
const DepartmentContext = React.createContext({
    activeUsers: [],
    updateActiveUsers: (arr: any) => {},
    deptLicenses: 0,
    endDate: new Date(),
});

export const DepartmentContextProvider = (props: any) => {
    let [activeUsers, setActiveUsers] = useState([]);
    let [deptLicenses, setdeptLicenses] = useState();
    let [endDate, setEndDate] = useState(new Date());

    const getLicenses = async () => {
        const dept = await getDeparimentInfo();
        if (dept) {
            setdeptLicenses(dept.licenses);
            setEndDate(new Date(dept.end_date));
        }
    };

    const updateActiveUsers = (arr: any) => {
        const activeUsersArray = arr.filter(
            (ele: any) => new Date(ele.expiryDate) > new Date()
        );

        setActiveUsers(activeUsersArray);
    };

    const contextValue = {
        activeUsers: activeUsers,
        updateActiveUsers: updateActiveUsers,
        deptLicenses: deptLicenses,
        endDate: endDate,
    };
    useEffect(() => {
        getLicenses();
    }, []);

    return (
        <DepartmentContext.Provider value={contextValue}>
            {props.children}
        </DepartmentContext.Provider>
    );
};

export default DepartmentContext;

import React, { useContext, useEffect, useMemo, useState } from 'react';
import AuthContext from '../../../store/auth-context';
import Button, { ButtonType } from '../../general-components/Button';
import styles from './ProtocolOverview.module.css';
import {
    InternalProtocolDTO,
    getInternalManagedProtocols,
    getInternalProtocolsByDepartment,
    verifyInternalProtocol,
} from '../../../services/apiService';
import { useNavigate } from 'react-router-dom';
import ProtocolTable from './ProtocolTable';
import EditInternalProtocol from '../EditInternalProtocol';
import OverlayWindow from '../../ui-components/OverlayWindow';
import ProtocolPage from '../../../components/protocols/ProtocolPage';
import VerificationModal from '../../../components/general/modal/VerificationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import SearchField from '../../search/suggestion/SearchField';

const ProtocolOverview = () => {
    const context = useContext(AuthContext);
    const navigate = useNavigate();

    const [inProgressProtocols, setInProgressProtocols] = useState<
        InternalProtocolDTO[]
    >([]);
    const [verifiedProtocols, setVerifiedProtocols] = useState<
        InternalProtocolDTO[]
    >([]);
    const [archivedProtocols, setArchivedProtocols] = useState<
        InternalProtocolDTO[]
    >([]);

    const [filteredInProgressProtocols, setFilteredInProgressProtocols] =
        useState<InternalProtocolDTO[]>([]);
    const [filteredVerifiedProtocols, setFilteredVerifiedProtocols] = useState<
        InternalProtocolDTO[]
    >([]);
    const [filteredArchivedProtocols, setFilteredArchivedProtocols] = useState<
        InternalProtocolDTO[]
    >([]);
    const [isLoading, setIsLoading] = useState(false);
    const [protocolToEdit, setProtocolToEdit] = useState<string | null>(null);

    const [searchTerm, setSearchTerm] = useState('');

    const filteredProtocols = () => {
        const filteredInProgress = inProgressProtocols.filter(
            (protocol) =>
                protocol.Title.toLowerCase().includes(
                    searchTerm.toLowerCase()
                ) ||
                protocol.createdBy
                    ?.toLowerCase()
                    .includes(searchTerm.toLowerCase()) ||
                protocol.protocolId
                    ?.toLowerCase()
                    .includes(searchTerm.toLowerCase())
        );
        const filteredVerified = verifiedProtocols.filter(
            (protocol) =>
                protocol.Title.toLowerCase().includes(
                    searchTerm.toLowerCase()
                ) ||
                protocol.createdBy
                    ?.toLowerCase()
                    .includes(searchTerm.toLowerCase()) ||
                protocol.protocolId
                    ?.toLowerCase()
                    .includes(searchTerm.toLowerCase())
        );
        const filteredArchived = archivedProtocols.filter(
            (protocol) =>
                protocol.Title.toLowerCase().includes(
                    searchTerm.toLowerCase()
                ) ||
                protocol.createdBy
                    ?.toLowerCase()
                    .includes(searchTerm.toLowerCase()) ||
                protocol.protocolId
                    ?.toLowerCase()
                    .includes(searchTerm.toLowerCase())
        );
        setFilteredInProgressProtocols(filteredInProgress);
        setFilteredVerifiedProtocols(filteredVerified);
        setFilteredArchivedProtocols(filteredArchived);
    };

    useEffect(() => {
        filteredProtocols();
    }, [searchTerm]);

    const fetchProtocols = async () => {
        setIsLoading(true);
        try {
            const res = await getInternalManagedProtocols();
            setVerifiedProtocols(res.verified);
            setArchivedProtocols(res.archived);
            setInProgressProtocols(res.inProgress);
            setFilteredInProgressProtocols(res.inProgress);
            setFilteredVerifiedProtocols(res.verified);
            setFilteredArchivedProtocols(res.archived);
        } catch (error) {
            console.error('Error fetching protocols:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleEditProtocol = (id: string) => {
        setProtocolToEdit(id);
    };

    useEffect(() => {
        fetchProtocols();
    }, []);

    const [verifyWindow, setVerfiyWindow] = useState('');

    const verifyProtocol = async (id: string) => {
        await verifyInternalProtocol(id);
        setVerfiyWindow('');
        fetchProtocols();
    };

    const getCommentFromId = (id: string) => {
        const comment = inProgressProtocols.filter(
            (p: any) => p._id.toString() === id
        )[0]?.latestComment;
        return comment && comment !== 'Protocol Creation' ? (
            <>
                <p style={{ fontSize: '16px' }}>Latest Edit note:</p>
                <b>{comment}</b>
            </>
        ) : (
            <i style={{ fontSize: '16px' }}>No note for most recent edit</i>
        );
    };

    return (
        <div className={styles.container}>
            {protocolToEdit && (
                <OverlayWindow close={() => setProtocolToEdit(null)}>
                    <EditInternalProtocol
                        id={protocolToEdit}
                        isNew={false}
                        onSubmit={() => {
                            setProtocolToEdit(null);
                            fetchProtocols();
                        }}
                    />
                </OverlayWindow>
            )}
            {verifyWindow !== '' && (
                <VerificationModal
                    onClose={() => setVerfiyWindow('')}
                    onVerify={() => {
                        verifyProtocol(verifyWindow);
                    }}
                    comment={getCommentFromId(verifyWindow)}
                >
                    <div className={styles.preview}>
                        <ProtocolPage isPreview={true} id={verifyWindow} />
                    </div>
                </VerificationModal>
            )}
            <div className={styles.header}>
                <h2>Internal Protocols</h2>
                {context.hasPermission('Manage Protocols') && (
                    <Button
                        small={true}
                        type={ButtonType.primary}
                        onClick={() => navigate('/protocols/new')}
                    >
                        <FontAwesomeIcon icon={faPlus} /> Create New Protocol
                    </Button>
                )}
            </div>
            <div className={styles.search}>
                <SearchField
                    hasSubmitButton={false}
                    placeholder="Filter protocols"
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyDown={(e) => {}}
                    search={() => {}}
                />
            </div>

            {isLoading ? (
                <p>Loading protocols...</p>
            ) : (
                <>
                    {context.hasPermission('Manage Protocols') &&
                        filteredInProgressProtocols.length > 0 && (
                            <ProtocolTable
                                onEditProtocol={handleEditProtocol}
                                onVerify={setVerfiyWindow}
                                status="inProgress"
                                title="In Progress"
                                protocols={filteredInProgressProtocols}
                                fetchProtocols={fetchProtocols}
                            />
                        )}
                    {filteredVerifiedProtocols.length > 0 && (
                        <ProtocolTable
                            canToggle={context.hasPermission(
                                'Manage Protocols'
                            )}
                            onEditProtocol={handleEditProtocol}
                            status="verified"
                            title="Verified"
                            protocols={filteredVerifiedProtocols}
                            fetchProtocols={fetchProtocols}
                        />
                    )}
                    {context.hasPermission('Manage Protocols') &&
                        filteredArchivedProtocols.length > 0 && (
                            <ProtocolTable
                                defaultOpen={false}
                                onEditProtocol={handleEditProtocol}
                                status="archived"
                                title="Archived"
                                protocols={filteredArchivedProtocols}
                                fetchProtocols={fetchProtocols}
                            />
                        )}

                    {filteredVerifiedProtocols.length === 0 ||
                        (filteredInProgressProtocols.length === 0 &&
                            filteredArchivedProtocols.length === 0 &&
                            context.hasPermission('Manage Protocols') && (
                                <p>No protocols found.</p>
                            ))}
                </>
            )}
        </div>
    );
};

export default ProtocolOverview;

import React, { ReactNode, useEffect } from 'react';
import styles from './Modal.module.css';
import { ReactComponent as Close } from '../../icons/close-modal-icon.svg';
import { ReactComponent as DoneIcon } from '../../icons/form-submitted.svg';

export interface ModalInfo {
    title: string;
    message?: string;
    userId?: string;
    submitButtonText?: string;
    submitButtonDanger?: boolean;
    isSubmitted?: boolean;
    className?: string;
    children?: JSX.Element[];
}

const Modal: React.FC<{
    info: ModalInfo;
    updateView: Function;
    submitModal?: Function;
    children: ReactNode;
}> = ({ info, updateView, submitModal, children }) => {
    useEffect(() => {
        if (info.isSubmitted) {
            setTimeout(() => {
                updateView();
            }, 1500);
        }
    }, [info.isSubmitted]);

    return (
        <div
            id={info.userId + '_popup'}
            className={`${styles.wrapper} ${styles[info.className]}`}
        >
            {!info.isSubmitted && (
                <div className={styles['popup-box']}>
                    <div className={styles['popup-box-header']}>
                        <h2>{info.title}</h2>
                        <div
                            className={styles['modal-close']}
                            onClick={() => updateView()}
                        >
                            <Close />
                        </div>
                    </div>
                    <form className={styles['popup-box-body']}>{children}</form>
                    <div className={styles['popup-box-footer']}>
                        <button
                            className={styles['modal-button-cancel']}
                            onClick={() => updateView()}
                        >
                            Cancel
                        </button>
                        <button
                            className={
                                info.submitButtonDanger
                                    ? styles['modal-button-danger']
                                    : styles['modal-button-regular']
                            }
                            onClick={() => submitModal()}
                        >
                            {info.submitButtonText}
                        </button>
                    </div>
                </div>
            )}
            {info.isSubmitted && (
                <>
                    <div className={styles['popup-box-submitted']}>
                        <DoneIcon />
                        <p>{info.message}</p>
                    </div>
                </>
            )}
        </div>
    );
};

export default Modal;

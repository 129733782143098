import { approveCPTReport } from '../../services/api/cptService';
import { useCPTReportContext } from '../../store/cptReport-context';
import ApprovalNote from '../approval/ApprovalNote';
import ModalLayout from '../general/modal/ModalLayout';
import ReportWizard from './ReportWizard';

const ReportModal: React.FC<{ implant?: any }> = ({ implant }) => {
    const { isReportModuleOpen, setReportModuleOpen, report, viewState } =
        useCPTReportContext();

    const handleApproval = async (note: string) => {
        const res = await approveCPTReport(report._id, note);
        console.log(res);
        setReportModuleOpen(false);
    };

    if (!isReportModuleOpen) {
        return null;
    }

    return (
        <>
            <ModalLayout
                size="xl"
                onClose={() => setReportModuleOpen(false)}
                hasCloseButton={true}
                bgBlur={true}
            >
                <ReportWizard implant={implant} />
                {viewState === 'approval' && (
                    <ApprovalNote
                        title="Approve Report"
                        placeholder="(Optional) Add comments"
                        defaultNote={report?.approval?.notes}
                        onSubmit={handleApproval}
                    />
                )}
            </ModalLayout>
        </>
    );
};

export default ReportModal;

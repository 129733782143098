import React, { FC, useState } from 'react';
import { HoverCard, HoverCardContent, HoverCardTrigger } from './hover-card';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

const SeeMoreHoverCard: FC<{
    title: string;
    content: { [key: string]: any };
}> = ({ title, content }) => {
    const [openCard, setOpenCard] = useState(false);
    const handleOpen = () => {
        setOpenCard(true);
    };
    const matches = useMediaQuery('(min-width: 768px)');
    const toggleCard = () => setOpenCard((prev) => !prev);
    return (
        <HoverCard
            open={openCard}
            onOpenChange={(isOpen) => setOpenCard(isOpen)}
        >
            <HoverCardTrigger
                className="text-[#51a6d9] font-medium underline"
                onMouseEnter={!matches ? () => setOpenCard(true) : undefined}
                onMouseLeave={!matches ? () => setOpenCard(false) : undefined}
                onClick={matches ? toggleCard : undefined}
            >
                See more
            </HoverCardTrigger>
            <HoverCardContent className="text-white bg-[#808080] h-72 overflow-auto">
                <h4>{title}</h4>
                <div>
                    {Object.keys(content).map((key) => (
                        <>
                            {content[key] !== undefined &&
                                content[key] !== null &&
                                content[key] !== '' && (
                                    <div>
                                        <h5>{key}</h5>
                                        <p>{content[key]}</p>
                                    </div>
                                )}
                        </>
                    ))}
                </div>
            </HoverCardContent>
        </HoverCard>
    );
};

export default SeeMoreHoverCard;

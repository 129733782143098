import { useState, useEffect, useReducer } from 'react';
import {
    getActiveDepartments,
    addDepartment,
    createAdminInvitation,
    migrateGuestTrialToDept,
} from '../../services/apiService';
import { emailValidate } from '../../services/formValidationService';
import Modal from './Modal';
import LoadingRing from '../ui-components/LoadingRing';
import OrganizationRow from './OrganizationRow';
import PendingInvitations from './PendingInvitations';
import Button, { ButtonType } from '../general-components/Button';
import styles from './Organizations.module.css';
import FormInput from '../general-components/FormInput';
import {
    formReducer,
    errorReducer,
    camelCaseToSentence,
} from '../../utils/utils';
import Select from '../general-components/Select';
import FEATURE_KEYS from '../../constants/feature-keys';

const initialFormData = {
    name: '',
    email: '',
    startDate: new Date().toLocaleDateString('en-US'),
    licenses: '',
    endDate: '',
};

// initialErrorData includes only required fields
const initialErrorData = { name: '', email: '', licenses: '', endDate: '' };

const ActiveOrganizations = () => {
    const [departments, setDepartments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isAddOrganizationModal, setIsAddOrganizationModal] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [newOrganization, setNewOrganization] = useReducer(
        formReducer,
        initialFormData
    );
    const [errorData, setErrorData] = useReducer(
        errorReducer,
        initialErrorData
    );

    // Define a list of required fields in the form
    const requiredFields = Object.keys(initialErrorData);

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;

        setNewOrganization({
            type: 'update',
            name,
            value,
        });
        requiredFields.includes(name) &&
            setErrorData({ type: 'update', name, value: '' });
    };

    const getData = async () => {
        const departments = await getActiveDepartments();
        setDepartments(departments);
        setIsLoading(false);
    };

    const inviteOrganization = async () => {
        let allChecksPassed = false;
        const ev = emailValidate(newOrganization.email);
        if (!ev.valid) {
            setErrorData({
                type: 'update',
                name: 'email',
                value: 'invalid email',
            });
        }
        Object.entries(newOrganization).forEach(([key, value]) => {
            if (requiredFields.includes(key) && value === '') {
                setErrorData({
                    type: 'update',
                    name: key,
                    value: `Please fill ${camelCaseToSentence(key)}`,
                });
            }
        });

        allChecksPassed =
            Object.entries(newOrganization)
                .filter(([key]) => requiredFields.includes(key))
                .every(([key, value]) => value !== '') && ev.valid;
        if (allChecksPassed) {
            const result = await addDepartment(newOrganization);
            if (result) {
                const inviteResult = await createAdminInvitation(
                    [newOrganization.email],
                    result.data._id
                );
                if (!inviteResult) {
                    const migrateSuccess = await migrateGuestTrialToDept(
                        newOrganization.email,
                        result.data._id,
                        newOrganization.endDate,
                        true
                    );
                }
                setIsSaved(true);
                setTimeout(() => {
                    setIsSaved(false);
                }, 2000);
            }
        }
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            <h2>Active Organizations</h2>
            <Button
                type={ButtonType.submit}
                onClick={() => {
                    setIsAddOrganizationModal(true);
                }}
            >
                Invite Organization
            </Button>
            {isLoading && (
                <div className={styles.loading}>
                    <LoadingRing size="large" />
                </div>
            )}
            {!isLoading && departments.length === 0 && (
                <p className={styles.noInfoMessage}>No organizations found</p>
            )}
            {!isLoading && departments.length > 0 && (
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Licenses</th>
                            <th>Active</th>
                            <th>Administrator</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Days left</th>
                            <th>Deactivate</th>
                        </tr>
                    </thead>
                    <tbody>
                        {departments.map((department) => (
                            <OrganizationRow
                                department={department}
                                refreshData={getData}
                                key={department._id}
                            />
                        ))}
                    </tbody>
                </table>
            )}
            {isAddOrganizationModal && (
                <Modal
                    info={{
                        title: 'Add Organization',
                        submitButtonText: 'Add Organization',
                        message: 'Organization Added',
                        submitButtonDanger: false,
                        isSubmitted: isSaved,
                        className: 'add-orgnization-modal',
                    }}
                    updateView={() => {
                        setIsAddOrganizationModal(false);
                        setNewOrganization({
                            type: 'reset',
                            initialFormData,
                        });
                        setErrorData({ type: 'reset', initialErrorData });
                    }}
                    submitModal={inviteOrganization}
                >
                    <FormInput
                        label="Name"
                        type="text"
                        name="name"
                        defaultValue={newOrganization.name}
                        placeholder="Organization name"
                        errorMsg={errorData.name}
                        onChange={handleInputChange}
                    />
                    <FormInput
                        label="Email"
                        type="email"
                        name="email"
                        defaultValue={newOrganization.email}
                        placeholder="Administrator email"
                        errorMsg={errorData.email}
                        onChange={handleInputChange}
                    />
                    <FormInput
                        label="Amount of Licenses"
                        type="number"
                        name="licenses"
                        placeholder="Number"
                        defaultValue={newOrganization.licenses}
                        errorMsg={errorData.licenses}
                        onChange={handleInputChange}
                    />

                    <div>
                        <FormInput
                            label="Start Date"
                            type="text"
                            name="startDate"
                            placeholder={newOrganization.startDate}
                            disabled={true}
                            onChange={() => {}}
                        />
                        <FormInput
                            label="End Date"
                            type="date"
                            name="endDate"
                            defaultValue={newOrganization.endDate}
                            errorMsg={errorData.endDate}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div
                        style={{
                            marginTop: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '5px',
                        }}
                    >
                        <h4>Feature Access</h4>
                        <Select
                            type="checkbox"
                            name="features"
                            options={Object.values(FEATURE_KEYS)}
                            changeSelected={(val: string) => {
                                handleInputChange({
                                    target: {
                                        name: 'featureAccess',
                                        value: val,
                                    },
                                });
                            }}
                        />
                    </div>
                </Modal>
            )}
            <PendingInvitations refreshData={isSaved} />
        </>
    );
};

export default ActiveOrganizations;

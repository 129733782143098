import * as React from 'react';

import {
    Drawer,
    DrawerClose,
    DrawerContent,
    DrawerDescription,
    DrawerFooter,
    DrawerHeader,
    DrawerTitle,
    DrawerTrigger,
} from '../drawer';
import ResultFilter from '../../search/ResultFilters';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { X } from 'lucide-react';

type FilterSearchDrawerProps = {
    results: any;
    currentTerm: string;
    defaultActiveFilters: { [key: string]: string[] };
    setActiveFilters: (filter: { [key: string]: string[] }) => void;
    search: (
        term: string,
        fullview?: boolean,
        filters?: { [key: string]: string[] }
    ) => void;
};

export function FilterSearchDrawer({
    results,
    currentTerm,
    defaultActiveFilters,
    setActiveFilters,
    search,
}: FilterSearchDrawerProps) {
    return (
        <Drawer>
            <DrawerTrigger asChild>
                <button className="border border-white px-2 rounded-md">
                    Filter Search
                    <KeyboardArrowDownIcon fontSize="large" className="ml-2" />
                </button>
            </DrawerTrigger>
            <DrawerContent className="h-4/5 text-white bg-[#212121]">
                <div className="mx-auto w-full max-w-sm overflow-scroll">
                    <div className="">
                        {results.length > 0 && (
                            <div className="flex justify-center">
                                <div>
                                    <DrawerClose className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-secondary">
                                        <X className="h-6 w-6" />
                                        <span className="sr-only">Close</span>
                                    </DrawerClose>
                                    <ResultFilter
                                        term={currentTerm}
                                        defaultActiveFilters={
                                            defaultActiveFilters
                                        }
                                        onChange={(filter) => {
                                            setActiveFilters(filter);
                                            search(currentTerm, false, filter);
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </DrawerContent>
        </Drawer>
    );
}

import { useEffect, useState } from 'react';
import { getAllVariants } from '../../services/api/subscriptionService';
import styles from './VariantSelect.module.css';
import Markdown from 'markdown-to-jsx';
import Button, { ButtonType } from '../general-components/Button';

const desc = `
### Features
- Access to over 3000 Implants
- AI-Powered Search Assistant
- Filter by Type, Manufacturer, Body part
- Full Conditionals View
`;

const Variant = ({ v }: { v: any }) => {
    return (
        <div className={styles.info}>
            <div className={styles.price}>
                <h1>
                    $
                    {(v.price / 100) % 1 > 0
                        ? (v.price / 100).toFixed(2)
                        : (v.price / 100).toFixed(0)}
                </h1>{' '}
                per {v.interval}
            </div>
            {v.interval === 'year' && (
                <span className={styles.discount}>
                    Save 17% with yearly billing
                </span>
            )}
            <Markdown className={styles.description}>{desc}</Markdown>
        </div>
    );
};

const VariantSelect = ({
    onSelect,
}: {
    onSelect: (variantId: string) => void;
}) => {
    const [products, setProducts] = useState([]);
    const [selectedVariant, setSelectedVariant] = useState();

    const updateVariant = (v: any) => {
        setSelectedVariant(v);
        // onSelect(v);
    };

    const fetchVariants = async () => {
        const p = await getAllVariants();
        updateVariant(p.filter((v: any) => v.name === 'Yearly')[0]?.id);
        setProducts(p.reverse());
    };

    useEffect(() => {
        fetchVariants();
    }, []);

    return (
        <>
            {products.length > 0 && (
                <>
                    <div className={styles.toggleContainer}>
                        <div className={styles.toggle}>
                            {products.map((p) => (
                                <span
                                    onClick={() => updateVariant(p.id)}
                                    className={`${styles.button} ${
                                        selectedVariant === p.id
                                            ? styles.selected
                                            : ''
                                    }`}
                                >
                                    {p.name}
                                </span>
                            ))}
                        </div>
                        {selectedVariant ===
                            products.filter((v) => v.name === 'Yearly')[0]
                                .id && (
                            <span className={styles.badge}>Save 17%</span>
                        )}
                    </div>
                    <div className={styles.container}>
                        <h3 className={styles.title}>Individual</h3>
                        {products
                            .filter((v) => v.id === selectedVariant)
                            .map((v) => (
                                <>
                                    <Variant v={v} />
                                    <div className={styles.select}>
                                        <Button
                                            small
                                            onClick={() => onSelect(v.id)}
                                            type={ButtonType.primary}
                                        >
                                            {' '}
                                            Select
                                        </Button>
                                    </div>
                                </>
                            ))}
                    </div>
                </>
            )}
            {/* <div className={styles.container}>
                <h2>Department</h2>
                <div className={styles.info}>
                    <div className={styles.price}>
                        <h1>500 $/Scanner/year</h1>
                    </div>
                    <div className={styles.description}>
                        <Markdown>
                            ### Features - Search - Filter - View Conditionals
                        </Markdown>
                    </div>
                </div>
            </div> */}
        </>
    );
};

export default VariantSelect;

import React, { useState, useEffect, useReducer } from 'react';
import { errorReducer, formReducer } from '../utils/utils';
import {
    emailValidate,
    nameValidate,
    passwordValidate,
    repeatPasswordValidate,
} from '../services/formValidationService';
import { useNavigate } from 'react-router-dom';
import {
    userExists,
    validateAndGetInvitationData,
} from '../services/apiService';
import { createContact } from '../services/api/subscriptionService';

const initialFormData = {
    name: '',
    email: '',
    password: '',
    repeatPassword: '',
    actor: '',
};

const initialErrorData = {
    name: '',
    email: '',
    password: '',
    repeatPassword: '',
    actor: '',
    hospital: '',
    scanners: '',
    phone: '',
};

const SignupContext = React.createContext<any>({});

export const SignupContextProvider = (props: any) => {
    const navigate = useNavigate();

    const [formData, setFormData] = useReducer(formReducer, initialFormData);
    const [errorData, setErrorData] = useReducer(
        errorReducer,
        initialErrorData
    );

    const [isIndividual, setIsIndividual] = useState(false);

    const [defaultEmail, setDefaultEmail] = useState('');
    const [currentElement, setCurrentElement] = useState('signup');
    const [step, setStep] = useState(1);

    const [id, setId] = useState('');
    const [hasRoleSelect, setHasRoleSelect] = useState(false);

    const validateFields = (trial: boolean) => {
        let validated = true;
        const nameResult = nameValidate(formData.name);
        if (!isIndividual && !nameResult.valid) {
            setErrorData({
                type: 'update',
                name: 'name',
                value: nameResult.message,
            });
            validated = false;
        }
        const emailResult = emailValidate(formData.email);
        if (!emailResult.valid) {
            setErrorData({
                type: 'update',
                name: 'email',
                value: emailResult.message,
            });
            validated = false;
        }
        const passwordResult = passwordValidate(formData.password);
        if (!passwordResult.valid) {
            setErrorData({
                type: 'update',
                name: 'password',
                value: passwordResult.message,
            });
            validated = false;
        }
        const pwRepeatResult = repeatPasswordValidate(
            formData.password,
            formData.repeatPassword
        );
        if (!isIndividual && !pwRepeatResult.valid) {
            setErrorData({
                type: 'update',
                name: 'repeatPassword',
                value: pwRepeatResult.message,
            });
            validated = false;
        }
        if (hasRoleSelect && !isIndividual && formData.role === '') {
            setErrorData({
                type: 'update',
                name: 'role',
                value: 'Choose your role',
            });
            validated = false;
        }
        return validated;
    };

    const fetchSignupData = async () => {
        if (defaultEmail) {
            const exists = await userExists(defaultEmail);
            if (exists) {
                navigate('/login?email=' + defaultEmail);
            } else {
                createContact(defaultEmail);
            }
        }
        if (id === 'individual') {
            setIsIndividual(true);
            setCurrentElement('signup');
            return;
        }
        const invdata = await validateAndGetInvitationData(id);
        if (
            invdata.type === 'Not found' ||
            invdata.accepted ||
            invdata === ''
        ) {
            setCurrentElement('wrong');
        } else {
            if (id === undefined || id === null) {
                setCurrentElement('disabled');
            }
        }
        if (invdata.type === 'Manufacturer') {
            setCurrentElement('signup');
        } else if (invdata.type === 'DepartmentInvite') {
            if (invdata.actor === undefined) {
                setHasRoleSelect(true);
            }
            setCurrentElement('signup');
            setIsIndividual(false);
            setStep(2);
        }
    };

    const contextValue = {
        formData,
        setFormData,
        errorData,
        setErrorData,
        isIndividual,
        setIsIndividual,
        validateFields,
        fetchSignupData,
        defaultEmail,
        setDefaultEmail,
        currentElement,
        setCurrentElement,
        id,
        setId,
        step,
        setStep,
        hasRoleSelect,
    };

    return (
        <SignupContext.Provider value={contextValue}>
            {props.children}
        </SignupContext.Provider>
    );
};

export default SignupContext;

import { useEffect, useState } from 'react';
import styles from './PlanAndBilling.module.css';
import {
    getSubscriptionInfo,
    SubscriptionInfo,
} from '../../services/api/subscriptionService';
import Button, { ButtonType } from '../general-components/Button';
import LaunchIcon from '@mui/icons-material/Launch';
import BackgroundContainer from '../ui-components/BackgroundContainer';
import Badge from '../ui-components/Badge';
import LoadingRing from '../ui-components/LoadingRing';

const PlanAndBilling = () => {
    const [subscriptionInfo, setSubscriptionInfo] =
        useState<SubscriptionInfo>();

    const fetchSubscriptionInfo = async () => {
        const subscriptionInfo = await getSubscriptionInfo();
        setSubscriptionInfo(subscriptionInfo);
    };

    useEffect(() => {
        fetchSubscriptionInfo();
    }, []);

    return (
        <BackgroundContainer>
            {subscriptionInfo && (
                <>
                    <div className={styles.header}>
                        <h3>Your plan and billing</h3>
                        <Button
                            className={styles.manageSubscriptionButton}
                            type={ButtonType.primary}
                            onClick={() => {
                                window.open(
                                    subscriptionInfo?.portalUrl,
                                    '_blank'
                                );
                            }}
                        >
                            Manage subscription <LaunchIcon />
                        </Button>
                    </div>
                    <div className={styles.infoContainer}>
                        <BackgroundContainer>
                            <div className={styles.header}>
                                <h4>{subscriptionInfo?.variantName} plan</h4>
                                <Badge
                                    color={
                                        subscriptionInfo?.status === 'active'
                                            ? 'green'
                                            : 'red'
                                    }
                                >
                                    {subscriptionInfo?.status}
                                </Badge>
                            </div>

                            <div className={styles.price}>
                                <span>
                                    <h1 style={{ display: 'inline' }}>
                                        ${subscriptionInfo?.variantPrice / 100}
                                    </h1>{' '}
                                    / {subscriptionInfo?.variantInterval}
                                </span>
                            </div>
                        </BackgroundContainer>
                        {subscriptionInfo?.status === 'active' && (
                            <BackgroundContainer>
                                {subscriptionInfo?.billingDate && (
                                    <h4>Renews at</h4>
                                )}
                                {subscriptionInfo?.endDate && <h4>Ends at</h4>}
                                <h3>
                                    {subscriptionInfo?.billingDate
                                        ? new Date(
                                              subscriptionInfo.billingDate
                                          ).toLocaleDateString()
                                        : subscriptionInfo?.endDate
                                        ? new Date(
                                              subscriptionInfo.endDate
                                          ).toLocaleDateString()
                                        : ''}
                                </h3>
                            </BackgroundContainer>
                        )}
                    </div>
                </>
            )}
            {subscriptionInfo === undefined && <LoadingRing size="large" />}
        </BackgroundContainer>
    );
};

export default PlanAndBilling;

import { CPTReportDTO } from '../../services/api/cptService';
import styles from './Preview.module.css';
import { useReactToPrint } from 'react-to-print';
import { useRef } from 'react';
import Button, { ButtonType } from '../general-components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPrint } from '@fortawesome/free-solid-svg-icons';
import SETTING_KEYS from '../../constants/setting-keys';

export function formatMRIParameters(data: any) {
    const result: any = {
        Precautions: data[SETTING_KEYS.addInfo] || '',
        'Static Magnetic Field':
            data['Static magnetic field']?.join(', ') || '',
        'Spatial Gradient':
            data[SETTING_KEYS.spatialGradient]?.gradients[0]?.condition || '',
        SAR:
            data[SETTING_KEYS.sar]?.length > 0
                ? `${data[SETTING_KEYS.sar][0].condition}` // Assumes only one SAR entry is needed
                : '',
        Artefacts:
            data[SETTING_KEYS.artefacts]?.length > 0 &&
            data[SETTING_KEYS.artefacts][0].distance !== undefined
                ? `${data[SETTING_KEYS.artefacts]
                      .map((artefact: any) => {
                          const list = [];
                          if (artefact.distance !== undefined) {
                              list.push(
                                  `<li>Distance: ${artefact.distance}mm</li>`
                              );
                          }
                          if (artefact.pulseSequence !== undefined) {
                              list.push(
                                  `<li>Pulse Sequence: ${artefact.pulseSequence}</li>`
                              );
                          }
                          if (artefact.mrSystem !== undefined) {
                              list.push(
                                  `<li>MR System: ${artefact.mrSystem}T</li>`
                              );
                          }
                          if (artefact.condition !== undefined) {
                              list.push(
                                  `<li>Condition: ${artefact.condition}</li>`
                              );
                          }
                          if (list.length > 0) {
                              return `<ul>${list.join('')}</ul>`;
                          }
                          return '';
                      })
                      .join('')}`
                : '',
        'Temperature Rise':
            data[SETTING_KEYS.temp]?.length > 0
                ? `${data[SETTING_KEYS.temp][0].condition}`
                : '',
        'Waiting Period': data[SETTING_KEYS.waitingPeriod]?.immediateScan
            ? 'Immediate Scan'
            : data[SETTING_KEYS.waitingPeriod]?.weeksAfterScan !== undefined
            ? `Patient can be scanned ${
                  data[SETTING_KEYS.waitingPeriod].weeksAfterScan
              } weeks after implantation`
            : '',
        'MRI Architecture': data[SETTING_KEYS.mriArc] || '',
        'Slew Rate': data[SETTING_KEYS.slewRate]
            ? `${data[SETTING_KEYS.slewRate].rate} (${
                  data[SETTING_KEYS.slewRate].condition
              })`
            : '',
        'B1+rms': data[SETTING_KEYS.brms] || '',
        'Coil Type': data[SETTING_KEYS.coilType] || '',
        Preparation: data[SETTING_KEYS.prep] || '',
        Image: data[SETTING_KEYS.image] || '',
    };

    return result;
}

export function formatAssessmentReport(data: CPTReportDTO) {
    const info = data.info;
    const mriSafetyDecisionInfo = data.mriSafetyDecisionInfo;
    const conditionalsAnalysis = data.conditionalsAnalysis;
    const guidance = data.guidance;
    const scannerSettings = data.scannerSettings;

    console.log(guidance.sources);

    const result = {
        'Name of Author': info.name,
        'Facility Name': info.facilityName,
        'Date of Assessment': info.dateOfAssessment.toLocaleDateString(),
        'Date of Service': info.dateOfService.toLocaleDateString(),
        Purpose: `To Evaluate ${
            info.purpose === 'Foreign Body' ? 'a Foreign Body' : 'an Implant'
        }`,
        Sources:
            info.sources?.length > 0
                ? `<ul>${info.sources
                      .map((source: string) => `<li>${source}</li>`)
                      .join('')}</ul>`
                : '',
        Notes: info.notes || '',
        Summary: {
            ...data.summary.codes.reduce((codes: any, c: string) => {
                if (c === '76015') {
                    codes[c + ' Count'] = data.summary.count15;
                } else {
                    codes[c + ' Compliance'] = 'Yes';
                }
                return codes;
            }, {}),
            'Total Time Spend':
                data.summary.totalTimeCPTCodes > 0
                    ? data.summary.totalTimeCPTCodes + ' minutes'
                    : '',
            'Time Log':
                data.timeLog?.length > 0
                    ? `<ul>${data.timeLog
                          .map(
                              (t: any) =>
                                  `<li>${t.task} - ${t.time} minutes</li>`
                          )
                          .join('')}</ul>`
                    : '',
        },
        'Safety Work': {
            'Clinical Indication': data.safetyWork?.clinicalIndication || '',
            Purpose: data.safetyWork?.purpose,
            Objectives: data.safetyWork?.objectives,
        },
        'MRI Safety Decision Information': {
            'Implant Details': {
                Model: mriSafetyDecisionInfo.implantDetails.model,
                'Catalog Number':
                    mriSafetyDecisionInfo.implantDetails.catalogNumber,
                Type: mriSafetyDecisionInfo.implantDetails.type,
                Manufacturer: mriSafetyDecisionInfo.implantDetails.manufacturer,
            },
            'Foreign Body Details': {
                Type: mriSafetyDecisionInfo.foreignBodyDetails.type,
                Location: mriSafetyDecisionInfo.foreignBodyDetails.location,
                Description:
                    mriSafetyDecisionInfo.foreignBodyDetails.description,
                Material: mriSafetyDecisionInfo.foreignBodyDetails.material,
                Dimensions: mriSafetyDecisionInfo.foreignBodyDetails.dimensions,
                Hazards:
                    mriSafetyDecisionInfo.foreignBodyDetails?.hazards?.length >
                    0
                        ? `<ul>${mriSafetyDecisionInfo.foreignBodyDetails.hazards
                              .map((hazard: string) => `<li>${hazard}</li>`)
                              .join('')}</ul>`
                        : '',
                'Hazard Explanation':
                    mriSafetyDecisionInfo.foreignBodyDetails.hazardExplanation,
            },
            'Safety Analysis': {
                Risk: mriSafetyDecisionInfo.safetyAnalysis.risk,
                'Limit SAR':
                    mriSafetyDecisionInfo.safetyAnalysis.limitSar ?? +' W/kg',
                'Sequence Modifications':
                    mriSafetyDecisionInfo.safetyAnalysis.sequenceMod,
                'Additional Modifications':
                    mriSafetyDecisionInfo.safetyAnalysis.additionalMod.join(
                        ', '
                    ),
                'Staff Needed':
                    mriSafetyDecisionInfo.safetyAnalysis.staffNeeded.join(', '),
                Mitigation: mriSafetyDecisionInfo.safetyAnalysis.mitigation,
            },
        },
        Guidance: {
            Sources:
                guidance.sources?.length > 0
                    ? `<ul>${guidance.sources
                          .map((source: string) => `<li>${source}</li>`)
                          .join('')}</ul>`
                    : '',
            'SOP Information':
                guidance.sources?.includes('Internal SOP') &&
                guidance.sopInfo &&
                Object.keys(guidance.sopInfo).length > 0
                    ? `<ul>${Object.entries(guidance.sopInfo)
                          .map(
                              ([key, value]) =>
                                  `<li>${key}: ${
                                      key === 'Date'
                                          ? new Date(value).toLocaleDateString()
                                          : value
                                  }</li>`
                          )
                          .join('')}</ul>`
                    : '',
            Explanation: guidance.explanation,
            Recommendation: guidance.recommendation,
            Notes: guidance.notes,
        },
        'Scanner Settings': {
            'Scanner Model': scannerSettings.scannerModel,
            Technique: scannerSettings.technique,
            'Patient Position': scannerSettings.patientPosition,
        },
        'Conditionals Analysis': {
            'MRI Status': convertMriStatus(conditionalsAnalysis.mriStatus),
            Restrictions: conditionalsAnalysis.restrictions,
            'Risk Level': conditionalsAnalysis.riskLevel,
        },
        'Benefits Analysis': {
            Benefits: conditionalsAnalysis.benefits?.benefits || '',
            'Risk/Benefit Conclusion':
                conditionalsAnalysis.benefits?.conclusion || '',
            'Explanation of Conclusion':
                conditionalsAnalysis.benefits?.explanation || '',
        },
    };

    return result;
}

const convertMriStatus = (value: string | number) => {
    if (value == 'Unsafe') {
        return 1;
    } else if (value == 2) {
        return 'Conditional';
    } else if (value == 3) {
        return 'Safe';
    }
};

// const spCases = ['MRI Status'];
// const getSpecialCase = (key: string, value: any) => {

// };

const checkChildEmpty = (value: any): boolean => {
    if (typeof value === 'object') {
        return Object.keys(value).every((k) => checkChildEmpty(value[k]));
    }
    return value === undefined || value === '' || value === null;
};

export function formatConditionals(
    json: any,
    depth = 0,
    parentIndex?: number
): any {
    if (Array.isArray(json)) {
        return `<p>${json
            .map<any>((item) => formatConditionals(item, depth + 1))
            .join(', ')}</p>`;
    }

    const entries = Object.entries(json).filter(
        ([key, value]) => value !== '' && value !== null && !key.includes('_id')
    );

    let index = 0;

    return `<div>${entries
        .map<any>(([key, value]: any) => {
            const normalCaseKey = key;
            if (checkChildEmpty(value)) {
                return '';
            }

            if (typeof value !== 'object') {
                return `<p><strong>${normalCaseKey}</strong>: ${value}</p>`;
            }
            if (depth === 0) {
                index++;
                return `<br><h3><strong>${index} ${normalCaseKey}</strong></h3><br>${formatConditionals(
                    value,
                    depth + 1,
                    index
                )}`;
            }
            if (depth > 0) {
                index++;
                return `${index > 1 ? '<br>' : ''}<h4><strong>${
                    parentIndex ? parentIndex + '.' : ''
                }${index} ${normalCaseKey}</strong></h4><br>${formatConditionals(
                    value,
                    depth + 1
                )}`;
            }
            // if (spCases.includes(normalCaseKey)) {
            //     return getSpecialCase(normalCaseKey, value);
            // }
        })
        .join('')}</div>`;
}

const Preview = ({
    report,
    isApprove = false,
}: {
    report: CPTReportDTO;
    isApprove?: boolean;
}) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const reactToPrintFn = useReactToPrint({ contentRef });
    const print = async () => {
        if (contentRef.current) {
            contentRef.current.style.transform = 'scale(1)';
        }
        await reactToPrintFn();
        if (contentRef.current) {
            contentRef.current.style.transform = 'scale(0.9)';
        }
    };
    const handleCopy = () => {
        const htmlContent = contentRef.current?.innerHTML || '';

        // Create a temporary container for the HTML content
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlContent;
        document.body.appendChild(tempDiv);

        // Select the content
        const range = document.createRange();
        range.selectNodeContents(tempDiv);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);

        // Execute copy command
        try {
            document.execCommand('copy');
            alert('Content copied to clipboard!');
        } catch (err) {
            console.error('Failed to copy: ', err);
        }

        // Cleanup
        selection.removeAllRanges();
        document.body.removeChild(tempDiv);
    };

    return (
        <>
            <div
                ref={contentRef}
                className={`${styles.doc} ${isApprove ? styles.approval : ''}`}
            >
                <h2>
                    <strong>Assessment Report</strong>
                </h2>
                <br></br>
                <div
                    dangerouslySetInnerHTML={{
                        __html: formatConditionals(
                            formatAssessmentReport(report)
                        ),
                    }}
                ></div>
            </div>
            {!isApprove && (
                <div className={styles.buttons}>
                    <Button type={ButtonType.action} onClick={print}>
                        <FontAwesomeIcon icon={faPrint} /> Print
                    </Button>
                    <Button type={ButtonType.action} onClick={handleCopy}>
                        <FontAwesomeIcon icon={faCopy} /> Copy
                    </Button>
                </div>
            )}
        </>
    );
};

export default Preview;

import SETTING_KEYS from '../../constants/setting-keys';
import SelectItem from '../general-components/SelectItem';
import styles from './Category.module.css';

const Category = ({
    isStent,
    info,
    onActivePassiveToggle,
    onUpdateInfoField,
    required = true,
}: {
    isStent: boolean;
    info: any;
    onActivePassiveToggle: Function;
    onUpdateInfoField: Function;
    required?: boolean;
}) => {
    const renderStent = () => {
        return (
            <div className={styles.category}>
                <label className={styles.infoLabel}>
                    Maximum Stent length (in mm)
                </label>
                <input
                    name={SETTING_KEYS.maxStentLength}
                    value={info[SETTING_KEYS.maxStentLength]}
                    type="number"
                    placeholder="Enter maximum stent length (mm)"
                    onChange={(e) =>
                        onUpdateInfoField(
                            SETTING_KEYS.maxStentLength,
                            e.target.value
                        )
                    }
                />
                <label className={styles.infoLabel}>Drug Type</label>
                <input
                    name={SETTING_KEYS.drugType}
                    value={info[SETTING_KEYS.drugType]}
                    type="text"
                    placeholder="Enter drug type"
                    onChange={(e) =>
                        onUpdateInfoField(SETTING_KEYS.drugType, e.target.value)
                    }
                />
            </div>
        );
    };

    return (
        <>
            <label
                className={`${styles.infoLabel} ${
                    required ? styles.required : ''
                }`}
            >
                Category
            </label>
            <div className={styles.inputWrapper}>
                <SelectItem
                    checked={info[SETTING_KEYS.category] === 'Active'}
                    type="radio"
                    value="Active"
                    onChange={() => onActivePassiveToggle('Active')}
                />
                <SelectItem
                    checked={info[SETTING_KEYS.category] === 'Passive'}
                    type="radio"
                    value="Passive"
                    onChange={() => onActivePassiveToggle('Passive')}
                />
                <SelectItem
                    checked={info[SETTING_KEYS.category] === 'Stent'}
                    type="radio"
                    value="Stent"
                    onChange={() => onActivePassiveToggle('Stent')}
                />
            </div>
            {isStent && renderStent()}
        </>
    );
};

export default Category;

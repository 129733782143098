import React, { useEffect, useContext, useState, useRef } from 'react';
import { highlighter } from '../../tools/searchFilter';
import SortIcon from './../ui-components/SortIcon';
import SETTING_KEYS from '../../constants/setting-keys';
import styles from './Results.module.css';
import AuthContext, { StoredContext } from '../../store/auth-context';
import OverlayWindow from '../ui-components/OverlayWindow';
import RIGHTS_KEYS from '../../constants/rights-keys';
import Implant from '../implant/Implant';
import Button, { ButtonType } from '../general-components/Button';
import Modal from '../manage-user/Modal';
import { archiveImplant, verifyImplant } from '../../services/apiService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleCheck,
    faPen,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import Status from '../general-components/Status';
import { Search } from 'lucide-react';

const Results: React.FC<{
    title?: string;
    term?: string;
    defaultResults: any[];
    isManufacturer?: boolean;
    isDept?: boolean;
    editStatus?: number;
    onChange?: Function;
    hasSearch?: boolean;
}> = ({
    title,
    term,
    defaultResults,
    isManufacturer = false,
    isDept = false,
    editStatus = -1,
    onChange,
    hasSearch = false,
}) => {
    const userContext = useContext(AuthContext);

    const [results, setResults] = useState<any[]>([]);
    const [sortStatus, setSortStatus] = useState(['none', '']);
    const [editId, setEditId] = useState('');
    const [verifyId, setVerifyId] = useState('');
    const [archiveId, setArchiveId] = useState('');
    const [verified, setVerified] = useState(false);
    const [saved, setSaved] = useState(false);
    const inputRef = useRef(null);
    const timeoutRef = useRef(null);

    const [smallScreen, setSmallScreen] = useState(false);
    //Search functionality
    const [isSearchVisible, setSearchVisible] = useState(false);

    const openSearch = () => {
        setSearchVisible(true);
    };

    const closeSearch = () => {
        setSearchVisible(false);
    };

    useEffect(() => {
        sortResults(sortStatus[0]);
    }, [sortStatus, defaultResults]);

    const sortResults = (v: string) => {
        let arr = [];

        if (sortStatus[1] === 'asc') {
            arr = ([] as any[]).concat(results).sort((x, y) => {
                if (x[v] < y[v] || x.info[v] < y.info[v]) {
                    return -1;
                }
                if (x[v] > y[v] || x.info[v] > y.info[v]) {
                    return 1;
                }
                return 0;
            });
        } else if (sortStatus[1] === 'desc') {
            arr = ([] as any[]).concat(results).sort((x, y) => {
                if (x[v] < y[v] || x.info[v] < y.info[v]) {
                    return 1;
                }
                if (x[v] > y[v] || x.info[v] > y.info[v]) {
                    return -1;
                }
                return 0;
            });
        } else {
            arr = defaultResults;
        }
        setResults(arr);
    };

    const sort = (event: React.MouseEvent) => {
        let parent = event.target as HTMLElement;
        while (parent.tagName !== 'TH') {
            parent = parent.parentElement as HTMLElement;
        }
        const v = parent.id;
        parent.style['fontWeight'] = 'bold';
        if (sortStatus[0] !== 'none' && sortStatus[0] !== v) {
            (document.getElementById(sortStatus[0]) as HTMLElement).style[
                'fontWeight'
            ] = 'normal';
        }

        if (sortStatus[0] === v) {
            if (sortStatus[1] === '') {
                setSortStatus([v, 'asc']);
            } else if (sortStatus[1] === 'asc') {
                setSortStatus([v, 'desc']);
            } else if (sortStatus[1] === 'desc') {
                (document.getElementById(sortStatus[0]) as HTMLElement).style[
                    'fontWeight'
                ] = 'normal';
                setSortStatus(['none', '']);
            }
        } else {
            setSortStatus([v, 'asc']);
        }
    };

    const submitVerify = async () => {
        await verifyImplant(verifyId);
        setVerifyId('');
        setVerified(true);
        setTimeout(() => {
            setVerified(false);
        }, 2000);
    };

    const handleBlur = () => {
        timeoutRef.current = setTimeout(() => {
            setSearchVisible(false);
        }, 5000);
    };
    const handleFocus = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    };

    useEffect(() => {
        if (onChange && ((verifyId === '' && archiveId === '') || saved)) {
            onChange();
            setSaved(false);
        }
    }, [verifyId, archiveId, saved]);

    useEffect(() => {
        const handler = (e: any) => {
            console.log(e.matches);
            setSmallScreen(e.matches);
        };
        window
            .matchMedia('(max-width: 1400px)')
            .addEventListener('change', handler);
    }, []);
    useEffect(() => {
        if (isSearchVisible) {
            inputRef.current?.focus(); // Focus the input element
        }
    }, [isSearchVisible]);

    return (
        <div className={styles.results}>
            <div className="flex flex-row justify-between items-center">
                {title && <h2>{title}</h2>}
                {hasSearch && (
                    <div className="flex items-center justify-center">
                        {/* Search Container */}
                        {isSearchVisible ? (
                            <div
                                className={`border border-gray-300 rounded-3xl flex flex-row justify-center items-center px-3 
          transition-transform transform
          animate-[fadeIn_0.3s_ease-out,scaleUp_0.3s_ease-out]`}
                            >
                                <input
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    ref={inputRef}
                                    type="text"
                                    className="bg-transparent text-white text-2xl py-0 px-3"
                                    placeholder="Search..."
                                />
                                <Search />
                            </div>
                        ) : (
                            <Search
                                onClick={openSearch}
                                className="animate-[fadeIn_0.3s_ease-out] text-white"
                            />
                        )}
                    </div>
                )}
            </div>

            <table
                className={`${styles.list} rounded-2xl overflow-hidden border border-gray-300 ease-in-out duration-150`}
            >
                <thead>
                    <tr>
                        <th id={SETTING_KEYS.model} onClick={(e) => sort(e)}>
                            Model{' '}
                            <SortIcon
                                sortStatus={sortStatus}
                                headerId={SETTING_KEYS.model}
                            />
                        </th>
                        {!isManufacturer && (
                            <th
                                id={SETTING_KEYS.manufacturer}
                                onClick={(e) => sort(e)}
                            >
                                Brand{' '}
                                <SortIcon
                                    sortStatus={sortStatus}
                                    headerId={SETTING_KEYS.manufacturer}
                                />
                            </th>
                        )}
                        {!smallScreen && !isDept && (
                            <th id="_id" onClick={(e) => sort(e)}>
                                ID{' '}
                                <SortIcon
                                    sortStatus={sortStatus}
                                    headerId="_id"
                                />
                            </th>
                        )}
                        <th id={SETTING_KEYS.type} onClick={(e) => sort(e)}>
                            Type{' '}
                            <SortIcon
                                sortStatus={sortStatus}
                                headerId={SETTING_KEYS.type}
                            />
                        </th>
                        {isDept && (
                            <th id="_id" onClick={(e) => sort(e)}>
                                MRI Status
                                <SortIcon
                                    sortStatus={sortStatus}
                                    headerId="_id"
                                />
                            </th>
                        )}
                        {userContext.orRights([
                            RIGHTS_KEYS.manageAllImplants,
                            RIGHTS_KEYS.manageImplant,
                        ]) && (
                            <>
                                <th id={SETTING_KEYS.type}>Edit </th>
                            </>
                        )}
                        {[2, 3].includes(editStatus) && (
                            <>
                                <th id={SETTING_KEYS.type}>Verification </th>

                                <th id={SETTING_KEYS.type}>Archive</th>
                            </>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {results.map((imp) => (
                        <React.Fragment key={imp.info._id + '_button'}>
                            {/* {userContext.orRights([
                                RIGHTS_KEYS.manageImplant,
                                RIGHTS_KEYS.manageAllImplants,
                            ]) && (
                                <tr className={styles['edit-button']}>
                                    <td>
                                        <Button
                                            key={imp.info._id + '_button'}
                                            onClick={() => {
                                                setEditId(imp.info._id);
                                            }}
                                            type={ButtonType.tietary}
                                            small={true}
                                        >
                                            Edit
                                        </Button>
                                    </td>
                                </tr>
                            )} */}
                            <tr
                                // onClick={() =>
                                //     (window.location.href =
                                //         '/implant/' + imp.info._id)
                                // }
                                key={imp.info._id}
                            >
                                <td>
                                    <a href={'/implant/' + imp.info._id}>
                                        {imp.info[SETTING_KEYS.model]}
                                    </a>
                                </td>
                                {!isManufacturer && (
                                    <td>{imp[SETTING_KEYS.manufacturer]}</td>
                                )}
                                {!smallScreen && !isDept && (
                                    <td>{imp.info._id}</td>
                                )}
                                <td>{imp.info[SETTING_KEYS.type]}</td>
                                {isDept && (
                                    <td>
                                        {
                                            <Status
                                                status={
                                                    imp.info[
                                                        SETTING_KEYS.mriStatus
                                                    ]
                                                }
                                            />
                                        }
                                    </td>
                                )}
                                {userContext.orRights([
                                    RIGHTS_KEYS.manageAllImplants,
                                    RIGHTS_KEYS.manageImplant,
                                ]) && (
                                    <td>
                                        <Button
                                            className={styles['inline-button']}
                                            onClick={() => {
                                                setEditId(imp.info._id);
                                            }}
                                            type={ButtonType.primary}
                                            small={true}
                                        >
                                            <FontAwesomeIcon icon={faPen} />
                                            Edit
                                        </Button>
                                    </td>
                                )}
                                {[2, 3].includes(editStatus) && (
                                    <td>
                                        {userContext.orRights([
                                            RIGHTS_KEYS.manageImplant,
                                        ]) &&
                                            imp.info[
                                                SETTING_KEYS
                                                    .verifiedByManufacturer
                                            ] &&
                                            userContext.orRights([
                                                RIGHTS_KEYS.manageAllImplants,
                                            ]) &&
                                            imp.info[
                                                SETTING_KEYS.verifiedByNI
                                            ] && (
                                                <span
                                                    style={{ color: 'green' }}
                                                >
                                                    Verified
                                                </span>
                                            )}
                                        {userContext.orRights([
                                            RIGHTS_KEYS.manageImplant,
                                        ]) &&
                                            imp.info[
                                                SETTING_KEYS
                                                    .verifiedByManufacturer
                                            ] &&
                                            !imp.info[
                                                SETTING_KEYS.verifiedByNI
                                            ] && (
                                                <span
                                                    style={{ color: 'orange' }}
                                                >
                                                    Awaiting NI
                                                </span>
                                            )}
                                        {imp.info[
                                            SETTING_KEYS.verifiedByManufacturer
                                        ] &&
                                            imp.info[
                                                SETTING_KEYS.verifiedByNI
                                            ] && (
                                                <span style={{ color: '#4d5' }}>
                                                    Verified
                                                </span>
                                            )}
                                        {userContext.orRights([
                                            RIGHTS_KEYS.manageAllImplants,
                                        ]) &&
                                            imp.info[
                                                SETTING_KEYS.verifiedByNI
                                            ] &&
                                            !imp.info[
                                                SETTING_KEYS
                                                    .verifiedByManufacturer
                                            ] && (
                                                <span
                                                    style={{ color: 'orange' }}
                                                >
                                                    Awaiting Manufacturer
                                                </span>
                                            )}
                                        {((userContext.orRights([
                                            RIGHTS_KEYS.manageImplant,
                                        ]) &&
                                            !imp.info[
                                                SETTING_KEYS
                                                    .verifiedByManufacturer
                                            ]) ||
                                            (userContext.orRights([
                                                RIGHTS_KEYS.verifyImplants,
                                            ]) &&
                                                !imp.info[
                                                    SETTING_KEYS.verifiedByNI
                                                ])) &&
                                            [2].includes(editStatus) && (
                                                <Button
                                                    className={
                                                        styles['inline-button']
                                                    }
                                                    key={
                                                        imp.info._id + '_button'
                                                    }
                                                    onClick={() => {
                                                        setVerifyId(
                                                            imp.info._id
                                                        );
                                                    }}
                                                    type={ButtonType.submit}
                                                    small={true}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faCircleCheck}
                                                    />
                                                    Verify
                                                </Button>
                                            )}
                                    </td>
                                )}

                                {[2, 3].includes(editStatus) && (
                                    <td
                                        className={styles.archiveButton}
                                        onClick={() => {
                                            setArchiveId(imp.info._id);
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faTrash}
                                            color={'#f55'}
                                        />
                                    </td>
                                )}
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
            {editId.length > 0 &&
                userContext.orRights([
                    RIGHTS_KEYS.manageImplant,
                    RIGHTS_KEYS.manageAllImplants,
                ]) && (
                    <OverlayWindow
                        close={() => {
                            setEditId('');
                        }}
                    >
                        <Implant
                            isEdit={true}
                            id={editId}
                            onSubmit={() => {
                                setSaved(true);
                            }}
                        />
                    </OverlayWindow>
                )}
            {verifyId.length > 0 &&
                userContext.orRights([
                    RIGHTS_KEYS.manageImplant,
                    RIGHTS_KEYS.verifyImplants,
                ]) && (
                    <Modal
                        scrollable={true}
                        info={{
                            title: '',
                            message: '',
                        }}
                        updateView={() => {
                            setVerifyId('');
                        }}
                    >
                        <div className={styles.wrapper}>
                            <Implant isPreview={true} id={verifyId} />
                            <form>
                                <Button
                                    key={'Save'}
                                    type={ButtonType.submit}
                                    onClick={submitVerify}
                                >
                                    Verify
                                </Button>
                                <Button
                                    key={'Cancel'}
                                    type={ButtonType.cancel}
                                    onClick={() => {
                                        setVerifyId('');
                                    }}
                                >
                                    Cancel
                                </Button>
                            </form>
                        </div>
                    </Modal>
                )}
            {archiveId.length > 0 &&
                userContext.orRights([
                    RIGHTS_KEYS.manageImplant,
                    RIGHTS_KEYS.manageAllImplants,
                ]) && (
                    <Modal
                        info={{
                            title: 'Archive Implant',
                            message: `Are you sure you want to archive ${
                                defaultResults.filter(
                                    (i) => i.info._id === archiveId
                                )[0].info[SETTING_KEYS.model]
                            }? This means it will nog longer be visible to the health care professionals.`,
                        }}
                        updateView={() => {
                            setArchiveId('');
                        }}
                    >
                        <div className={styles.wrapper}>
                            <form>
                                <Button
                                    key={'Save'}
                                    type={ButtonType.cancel}
                                    onClick={async () => {
                                        await archiveImplant(archiveId);
                                        setArchiveId('');
                                    }}
                                >
                                    Archive
                                </Button>
                                <Button
                                    key={'Cancel'}
                                    type={ButtonType.tietary}
                                    onClick={() => {
                                        setArchiveId('');
                                    }}
                                >
                                    Cancel
                                </Button>
                            </form>
                        </div>
                    </Modal>
                )}
            {verified && (
                <Modal
                    info={{
                        title: '',
                        message: '',
                    }}
                    timed={true}
                >
                    <div className={styles.verified}>
                        <div className={styles['verified-icon']}>
                            <FontAwesomeIcon icon={faCircleCheck} />
                        </div>
                        <h1>Implant Verified</h1>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default Results;

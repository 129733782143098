import { ChangeEvent, useContext, useEffect, useState } from 'react';
import FormInput from '../../general-components/FormInput';
import { useCPTReportContext } from '../../../store/cptReport-context';
import AuthContext from '../../../store/auth-context';
import Select from '../../general-components/Select';
import styles from '../ReportWizard.module.css';
import { autofill } from '../../../services/cptAutofillService';
import TimeLog from './TimeLog';

const Assessment = ({ implant }: { implant?: any }) => {
    const {
        report,
        error,
        getHandleEventChange,
        getHandleValueChange,
        getHandleSelectChange,
        fetchUserInfo,
    } = useCPTReportContext();

    useEffect(() => {
        fetchUserInfo();
    }, []);

    return (
        <div className={styles.form}>
            <div className={styles.row}>
                <FormInput
                    defaultValue={
                        report.info?.dateOfAssessment
                            .toISOString()
                            .split('T')[0]
                    }
                    label="Date of Assessment"
                    type="date"
                    name="dateOfAssessment"
                    onChange={(e) =>
                        getHandleValueChange('info.dateOfAssessment')(
                            new Date(e.target.value)
                        )
                    }
                />
                <div className={styles.row}>
                    <FormInput
                        defaultValue={
                            report.info?.dateOfService
                                .toISOString()
                                .split('T')[0]
                        }
                        label="Date of Service(DOS)"
                        type="date"
                        name="dateOfService"
                        onChange={(e) =>
                            getHandleValueChange('info.dateOfService')(
                                new Date(e.target.value)
                            )
                        }
                    />
                </div>
            </div>
            <div className={styles.row}>
                <FormInput
                    label="Name"
                    type="text"
                    name="name"
                    defaultValue={report?.info?.name}
                    onChange={getHandleEventChange('info.name')}
                />
                <FormInput
                    label="Facility Name"
                    type="text"
                    name="facilityName"
                    defaultValue={report?.info?.facilityName}
                    onChange={getHandleEventChange('info.facilityName')}
                />
            </div>
            <div className={styles.row}>
                <div className={styles.row}>
                    <div>
                        <h5>Reason for Assessment</h5>
                        <Select
                            name="type"
                            type="radio"
                            defaultChecked={[report.info?.purpose]}
                            options={['Implant', 'Foreign Body']}
                            changeSelected={getHandleValueChange(
                                'info.purpose'
                            )}
                            isRequired={true}
                            errorMessage={
                                error?.[
                                    'mriSafetyDecisionInfo.assessment.details.purpose'
                                ]
                            }
                        />
                    </div>
                    <div>
                        <h5>Time spent in total</h5>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                            }}
                        >
                            <FormInput
                                disabled={true}
                                type="number"
                                name="timeSpent"
                                defaultValue={
                                    report.summary?.totalTimeCPTCodes || null
                                }
                                onChange={getHandleEventChange(
                                    'summary.totalTimeCPTCodes'
                                )}
                            />
                            Minutes
                        </div>
                        <TimeLog />
                    </div>
                </div>
            </div>
            <div className={styles.row}>
                <div>
                    <h5>Which Sources were used to determine the implant?</h5>
                    <Select
                        defaultChecked={report.info.sources}
                        type="checkbox"
                        name="source"
                        options={Object.keys(autofill.info.sources)}
                        changeSelected={getHandleSelectChange(
                            'info.sources',
                            'multi',
                            'info.notes'
                        )}
                    />
                </div>
            </div>
            <div className={styles.row}>
                <FormInput
                    label="Verification Methods Used"
                    tag="textArea"
                    name="notes"
                    defaultValue={report.info.notes}
                    onChange={getHandleEventChange('info.notes')}
                />
            </div>
        </div>
    );
};

export default Assessment;

import React, { FC, useContext, useEffect, useState } from 'react';
import AuthContext, { StoredContext } from '../../store/auth-context';
import RIGHTS_KEYS from '../../constants/rights-keys.js';
import { GuardedElement } from './../general-components/authentication';
import AppMenuItem from './../navigation/AppMenuItem';
import styles from './Navigation.module.css';
import logoWhite from '../../images/LogohvidFinal.png';
import logoBlack from '../../images/Logoblack.png';
import logo_white from '../../images/Logo_white_no_money.png';
import { getUnreadNotifications } from '../../services/apiService';

import {
    Update,
    Map,
    Search,
    Bookmarks,
    ReceiptLong,
    Storage,
    ListAlt,
    Assignment,
    Groups,
    CorporateFare,
    LiveTv,
    Settings,
    Logout,
    Verified,
    AccountCircle,
    AddCircle,
    Troubleshoot,
    AssignmentLate,
    Article,
    AllInclusive,
    Dehaze,
    MapOutlined,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import SearchIssue from '../search-issues/SearchIssue';
import { AlignJustify } from 'lucide-react';
import OrganizationToggle from '../orgnization/OrganizationToggle';

type NavigationProps = {
    isOpen: boolean;
    toggleMenu: () => void;
};

const Navigation: FC<NavigationProps> = ({ isOpen, toggleMenu }) => {
    const context = useContext(AuthContext);
    let ctx: StoredContext = context.getContext();

    const [notifications, setNotifications] = useState({
        news: false,
    });
    const [isLoading, setIsLoading] = useState(true);
    const [userInfo, setUserInfo] = useState<any>([]);

    const getNotifications = async () => {
        const notifs = await getUnreadNotifications();
        setNotifications(notifs);
    };

    const getUserData = async () => {
        const info = await context.getAllInfo();
        setUserInfo(info);
        setIsLoading(false);
    };

    useEffect(() => {
        getNotifications();
        getUserData();
    }, []);

    return (
        <GuardedElement auth={ctx.isLoggedIn}>
            <div>
                <div className={styles.hamburger}>
                    <div className="flex bg-[#212121] h-full items-center justify-center">
                        <div onClick={toggleMenu} className="absolute left-4">
                            <AlignJustify strokeWidth={1} />
                        </div>
                        <div className="flex items-center">
                            <div className=" w-44">
                                <a href="/">
                                    <img
                                        src={
                                            context.theme === 'dark'
                                                ? logo_white
                                                : logoBlack
                                        }
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <nav
                    className={`${styles.wrapper} ${isOpen ? styles.open : ''}`}
                >
                    <div className={styles.logo}>
                        <a href="/">
                            <img
                                src={
                                    context.theme === 'dark'
                                        ? logoWhite
                                        : logoBlack
                                }
                            />
                        </a>
                    </div>
                    <ul className={styles['app-menu']} onClick={toggleMenu}>
                        <AppMenuItem
                            path="/guide"
                            auth={context.orRights([RIGHTS_KEYS.deptTools])}
                            content={
                                <div>
                                    <MapOutlined /> Guide
                                </div>
                            }
                        ></AppMenuItem>
                        <AppMenuItem
                            hasNotification={notifications.news}
                            path="/news"
                            auth={false}
                            content={
                                <div>
                                    <ReceiptLong />
                                    News
                                </div>
                            }
                        ></AppMenuItem>
                        <AppMenuItem
                            path="/search"
                            isHomeScreen={true}
                            auth={context.orRights([RIGHTS_KEYS.dbSearch])}
                            content={
                                <div>
                                    <Search />
                                    Search
                                </div>
                            }
                        >
                            <ul className={styles['sub-menu']}>
                                <AppMenuItem
                                    path="/history"
                                    auth={true}
                                    type="sub"
                                    content={'History'}
                                ></AppMenuItem>
                            </ul>
                        </AppMenuItem>
                        <AppMenuItem
                            path="/all"
                            auth={context.orRights([RIGHTS_KEYS.deptTools])}
                            content={
                                <div>
                                    <AllInclusive /> All Implants
                                </div>
                            }
                        ></AppMenuItem>
                        <AppMenuItem
                            path="/bookmarks"
                            auth={context.orRights([
                                RIGHTS_KEYS.advancedSearch,
                            ])}
                            content={
                                <div>
                                    <Bookmarks /> Bookmarks
                                </div>
                            }
                        ></AppMenuItem>
                        <AppMenuItem
                            path="/approved"
                            teaser={ctx.actor?.role === 'PremiumUser'}
                            auth={
                                context.orRights([RIGHTS_KEYS.deptTools]) &&
                                ctx.department !== undefined
                            }
                            content={
                                <div>
                                    <Verified /> Approved
                                </div>
                            }
                        ></AppMenuItem>
                        <AppMenuItem
                            teaser={ctx.actor?.role === 'PremiumUser'}
                            path="/cpt-report/manage"
                            auth={context.orRights([RIGHTS_KEYS.cptReporting])}
                            content={
                                <div>
                                    <ReceiptLong /> CPT Reports
                                </div>
                            }
                        ></AppMenuItem>
                        <AppMenuItem
                            path="/implants"
                            auth={context.orRights([
                                RIGHTS_KEYS.manageImplant,
                                RIGHTS_KEYS.manageAllImplants,
                            ])}
                            content={
                                <div>
                                    <Storage /> Implants
                                </div>
                            }
                        >
                            <div className={styles['sub-menu']}>
                                <ul>
                                    <AppMenuItem
                                        path="/reverification"
                                        auth={context.orRights([
                                            RIGHTS_KEYS.reverifyImplants,
                                        ])}
                                        type="sub"
                                        content={'Reverification'}
                                    ></AppMenuItem>
                                    <AppMenuItem
                                        path="/list-source-files"
                                        auth={true}
                                        type="sub"
                                        content={'All PDFs'}
                                    ></AppMenuItem>
                                </ul>
                            </div>
                        </AppMenuItem>
                        <AppMenuItem
                            path="/add-implant"
                            auth={context.orRights([
                                RIGHTS_KEYS.manageImplant,
                                RIGHTS_KEYS.manageAllImplants,
                            ])}
                            content={
                                <div>
                                    <AddCircle />
                                    Add Implant
                                </div>
                            }
                        ></AppMenuItem>
                        <AppMenuItem
                            path="/searches"
                            auth={context.orRights([
                                RIGHTS_KEYS.manageImplant,
                                RIGHTS_KEYS.manageAllImplants,
                            ])}
                            content={
                                <div>
                                    <Map />
                                    Searches
                                </div>
                            }
                        ></AppMenuItem>
                        <AppMenuItem
                            path="/search-issues"
                            isHomeScreen={false}
                            auth={context.orRights([
                                RIGHTS_KEYS.manageAllImplants,
                            ])}
                            content={
                                <div>
                                    <Troubleshoot />
                                    Search Issue
                                </div>
                            }
                        ></AppMenuItem>
                        <AppMenuItem
                            path="/recent-implants"
                            auth={context.orRights([
                                RIGHTS_KEYS.verifyImplants,
                            ])}
                            content={
                                <div>
                                    <Update />
                                    Recent Implants
                                </div>
                            }
                        ></AppMenuItem>
                        <AppMenuItem
                            path="/predefined/manage"
                            auth={context.orRights([
                                RIGHTS_KEYS.managePredefined,
                            ])}
                            content={
                                <div>
                                    <ListAlt /> WC
                                </div>
                            }
                        ></AppMenuItem>
                        <AppMenuItem
                            path="/room-overview"
                            auth={
                                context.orRights([
                                    RIGHTS_KEYS.viewMRIOverview,
                                ]) && ctx.department?.name !== undefined
                            }
                            content={
                                <div>
                                    <Assignment /> Room Overview
                                </div>
                            }
                        ></AppMenuItem>
                        <AppMenuItem
                            path="/users"
                            auth={context.orRights([
                                RIGHTS_KEYS.manageEmployees,
                                RIGHTS_KEYS.manageAllUsers,
                            ])}
                            content={
                                <div>
                                    <Groups /> Users
                                </div>
                            }
                        ></AppMenuItem>
                        <AppMenuItem
                            path="/organizations"
                            auth={context.orRights([
                                RIGHTS_KEYS.manageAllUsers,
                            ])}
                            content={
                                <div>
                                    <CorporateFare /> Organizations
                                </div>
                            }
                        ></AppMenuItem>

                        {/* <AppMenuItem
                            path="/protocols"
                            auth={context.orRights([RIGHTS_KEYS.deptTools])}
                            teaser={ctx.actor?.role === 'PremiumUser'}
                            content={
                                <div>
                                    <Article /> Protocols
                                </div>
                            }
                        >
                            {' '}
                            {ctx.deptPermissions?.includes(
                                'Manage Protocols'
                            ) && (
                                <div className={styles['sub-menu']}>
                                    <ul>
                                        <AppMenuItem
                                            path="/protocols/manage"
                                            type="sub"
                                            auth={true}
                                            content={
                                                <div>Manage Protocols</div>
                                            }
                                        ></AppMenuItem>
                                    </ul>
                                </div>
                            )}
                        </AppMenuItem> */}

                        <AppMenuItem
                            teaser={ctx.actor?.role === 'PremiumUser'}
                            path="/protocols"
                            auth={context.orRights([RIGHTS_KEYS.deptTools])}
                            content={
                                <div>
                                    <Article /> Protocols
                                </div>
                            }
                        ></AppMenuItem>
                        <AppMenuItem
                            path="/video-overview"
                            auth={context.orRights([RIGHTS_KEYS.deptTools])}
                            content={
                                <div>
                                    <LiveTv /> Insights
                                </div>
                            }
                        ></AppMenuItem>
                        <AppMenuItem
                            path="/admin"
                            auth={context.orRights([
                                RIGHTS_KEYS.manageActors,
                                RIGHTS_KEYS.createDeptNews,
                                RIGHTS_KEYS.createNews,
                            ])}
                            content={
                                <div>
                                    <Settings /> Settings
                                </div>
                            }
                        ></AppMenuItem>
                    </ul>
                    <div className={styles.userMenu} onClick={toggleMenu}>
                        {ctx.hasGuestAccess && <OrganizationToggle />}
                        {isLoading && <></>}
                        {!isLoading && (
                            <Link to="/user-profile">
                                <div className={styles.userLink}>
                                    <AccountCircle />
                                    {userInfo.user.name !== ''
                                        ? userInfo.user.name
                                        : 'Your Profile'}
                                </div>
                            </Link>
                        )}
                        <button
                            className={styles.logout}
                            onClick={context.onLogout}
                        >
                            <Logout />
                            Log out
                        </button>
                    </div>
                </nav>
            </div>
        </GuardedElement>
    );
};

export default Navigation;

import React, { useState } from 'react';
import Modal, { ModalInfo } from '../../organizations/Modal';
import Button, { ButtonType } from '../../general-components/Button';
import styles from './ConfirmActionButton.module.css';
interface ConfirmActionButtonProps {
    buttonText: string;
    modalInfo: ModalInfo;
    onConfirm: () => void;
    buttonType?: ButtonType;
    buttonClassName?: string;
    disabled?: boolean;
    children?: React.ReactNode;
}

const ConfirmActionButton: React.FC<ConfirmActionButtonProps> = ({
    buttonText,
    modalInfo,
    onConfirm,
    buttonType = ButtonType.action,
    buttonClassName = '',
    disabled = false,
    children,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleButtonClick = () => {
        setIsModalOpen(true);
    };

    const handleModalSubmit = () => {
        onConfirm();
        setIsSubmitted(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setIsSubmitted(false);
    };

    return (
        <>
            <Button
                small
                type={buttonType}
                onClick={handleButtonClick}
                className={`${buttonClassName} ${styles.button}`}
                disabled={disabled}
            >
                {buttonText}
            </Button>
            {isModalOpen && (
                <Modal
                    info={{
                        ...modalInfo,
                        isSubmitted: isSubmitted,
                    }}
                    updateView={handleModalClose}
                    submitModal={handleModalSubmit}
                >
                    {children}
                </Modal>
            )}
        </>
    );
};

export default ConfirmActionButton;

import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import styles from './Home.module.css';
import AuthContext, { StoredContext } from '../store/auth-context';
import Modal from './manage-user/Modal';
import Button, { ButtonType } from './general-components/Button';
import SuggestionSearch from './search/suggestion/SuggestionSearch';
import GroupsOverview from './group/GroupsOverview';
import CPTReportButton from './cpt-report/CPTReportButton';
import ReportModal from './cpt-report/ReportModal';
import RIGHTS_KEYS from '../constants/rights-keys';
import { useMediaQuery } from '../hooks/useMediaQuery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowUpRightFromSquare,
    faClockRotateLeft,
} from '@fortawesome/free-solid-svg-icons';

const Home = () => {
    const [hasResult, setHasResult] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [semanticEnabled, setSemanticEnabled] = useState(false);
    const matches = useMediaQuery('(min-width: 768px)');

    const context = useContext(AuthContext);
    let ctx: StoredContext = context.getContext();

    const [showTrialPrompt, setShowTrialPrompt] = useState(
        context.showTrialPrompt()
    );

    const closeTrialPrompt = () => {
        setShowTrialPrompt(false);
        context.setShowTrialPrompt(false);
    };

    const search = (term: string, s: boolean) => {
        setSearchTerm(term);
        setSemanticEnabled(s);
    };

    useEffect(() => {
        if (searchTerm.length > 0) {
            setHasResult(true);
        }
    }, [searchTerm]);

    return (
        <React.Fragment>
            {showTrialPrompt && (
                <Modal
                    info={{
                        title: '',
                        message: '',
                    }}
                    updateView={closeTrialPrompt}
                >
                    <div className={styles.trialBox}>
                        {ctx.actor.role === 'LiteUser' && (
                            <h1>Your subscription has expired!</h1>
                        )}
                        <h4
                            style={{
                                marginBottom: '30px',
                            }}
                        >
                            Please upgrade your account to continue using all
                            premium features of Nordinsight.
                        </h4>
                        <Button
                            className={styles.trialBtn}
                            onClick={() => {
                                closeTrialPrompt();
                                window.location.href = '/user-profile';
                            }}
                            type={ButtonType.submit}
                        >
                            Manage subscription
                        </Button>
                    </div>
                </Modal>
            )}
            {!hasResult && (
                <div className={styles.wrapper}>
                    {context.orRights([RIGHTS_KEYS.cptReporting]) &&
                        matches && (
                            <>
                                <ReportModal />
                                <div className={styles.cptReportButton}>
                                    <CPTReportButton type="new" isPage />
                                </div>
                            </>
                        )}
                    <h2 className={styles.welcome}>
                        Welcome, Search for Implants
                    </h2>
                    <div className={styles.search}>
                        {/* <Search
                            hasQuickResult={true}
                            onSearch={(term: string, s: boolean) =>
                                search(term, s)
                            }
                        /> */}
                        <SuggestionSearch
                            onSearch={(term: string, s: boolean) =>
                                search(term, s)
                            }
                        />
                    </div>
                    {/* <FrontpagePredefinedList /> */}
                    {context.orRights([RIGHTS_KEYS.manageAllImplants]) && (
                        <Button
                            type={ButtonType.tietary}
                            onClick={() => (window.location.href = '/all')}
                        >
                            See all implants
                        </Button>
                    )}
                    {ctx.actor.role === 'PremiumUser' && (
                        <>
                            <Button
                                small
                                className={styles.historyButton}
                                type={ButtonType.primary}
                                onClick={() =>
                                    (window.location.href = '/history')
                                }
                            >
                                <FontAwesomeIcon icon={faClockRotateLeft} />
                                History
                            </Button>
                            <div>
                                <h4 className={styles.actionsTitle}>
                                    Report feedback or missing implants
                                </h4>
                                <div className={styles.actions}>
                                    <Button
                                        small
                                        type={ButtonType.action}
                                        onClick={() => {
                                            window.open(
                                                process.env
                                                    .REACT_APP_FEEDBACK_FORM,
                                                '_blank'
                                            );
                                        }}
                                    >
                                        Feedback
                                        <FontAwesomeIcon
                                            icon={faArrowUpRightFromSquare}
                                        />
                                    </Button>
                                    <Button
                                        small
                                        type={ButtonType.action}
                                        onClick={() => {
                                            window.open(
                                                process.env
                                                    .REACT_APP_MISSING_FORM,
                                                '_blank'
                                            );
                                        }}
                                    >
                                        Missing implants
                                        <FontAwesomeIcon
                                            icon={faArrowUpRightFromSquare}
                                        />
                                    </Button>
                                </div>
                            </div>
                        </>
                    )}
                    <GroupsOverview />
                </div>
            )}
            {hasResult && (
                <Navigate
                    to={
                        '/search/query?term=' +
                        searchTerm +
                        (semanticEnabled
                            ? '&useSemantic=' + semanticEnabled
                            : '')
                    }
                />
            )}
        </React.Fragment>
    );
};

export default Home;

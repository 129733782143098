import { Plus, Search } from 'lucide-react';
import { Button } from '../ui/button';
import {
    getAllMRIMachines,
    getAllMRIMachinesByDepartment,
} from '../../services/apiService';
import { useEffect, useState } from 'react';
import LoadingRing from '../ui-components/LoadingRing';
import { AddMachine } from './AddMachine';

function AddRoomPage() {
    return (
        <div className="flex-1 flex justify-center items-center">
            <div className="w-[100%] md:w-3/4 flex  justify-center items-center ">
                <div className="flex flex-col w-[90%]  md:w-3/4 justify-center">
                    <div className="flex flex-row justify-between my-3 items-center">
                        <h2 className="text-3xl md:text-4xl">Create Room</h2>
                    </div>

                    <AddMachine />
                </div>
            </div>
        </div>
    );
}

export default AddRoomPage;

import RoomTable from '../testTables/RoomTable';
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from './accordion';

export function RoomOverviewAccordion({
    machine,
    setMachineIdToEdit,
    onDelete,
}: {
    machine: any;
    setMachineIdToEdit: any;
    onDelete: any;
}) {
    return (
        <Accordion type="single" collapsible className="w-full">
            <AccordionItem className="mb-4 w-full" value="item-1">
                <AccordionTrigger className="w-full flex justify-center items-center p-0 m-0">
                    <div className="flex flex-row justify-center items-center gap-4 px-2 rounded-lg py-2 w-max">
                        <div className="h-16 w-20 bg-slate-500 rounded-xl"></div>
                        <div>
                            <h4>{machine?.Name}</h4>
                            <p>Created on 24/04/24 - Expires on 01/01/25</p>
                        </div>
                    </div>
                </AccordionTrigger>
                <AccordionContent>
                    <RoomTable
                        machine={machine}
                        setMachineIdToEdit={setMachineIdToEdit}
                        onDelete={onDelete}
                    />
                </AccordionContent>
            </AccordionItem>
        </Accordion>
    );
}

import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { deleteGroup, getGroupById } from '../../services/apiService';
import styles from './GroupPage.module.css';
import CardComponent from '../general/Cards/CardComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLines, faMobileScreen } from '@fortawesome/free-solid-svg-icons';
import { Article } from '@mui/icons-material';
import Status from '../general-components/Status';
import SETTING_KEYS from '../../constants/setting-keys';
import CreateGroupButton from './CreateGroupButton';
import AuthContext from '../../store/auth-context';
import Button, { ButtonType } from '../general-components/Button';
import ConfirmationModal from '../general/modal/ConfirmationModal';
import TimedAlert from '../general/Alerts/TimedAlert';

const GroupPage = () => {
    const { id } = useParams();

    const ctx = useContext(AuthContext);

    const [group, setGroup] = useState<any>();

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const getGroup = async () => {
        const res = await getGroupById(id);
        console.log(res);
        setGroup(res);
    };

    const deleteAlert = async () => {
        setAlertMessage('Group deleted successfully');
        await deleteGroup(id);
        setTimeout(() => {
            window.location.href = '/groups';
        }, 1500);
    };

    useEffect(() => {
        getGroup();
    }, []);

    return (
        <div className={styles.wrapper}>
            <TimedAlert type="success" message={alertMessage} duration={2000} />
            {showDeleteModal && (
                <ConfirmationModal
                    isDelete={true}
                    title="Delete Folder"
                    message="Folder has been deleted"
                    onClose={() => setShowDeleteModal(false)}
                    onConfirm={deleteAlert}
                    confirmText="Delete"
                    cancelText="Cancel"
                >
                    This cannot be undone.
                </ConfirmationModal>
            )}
            <div className={styles.header}>
                <h1>{group?.title}</h1>
                {ctx.hasPermission('Manage Groups') && (
                    <div className={styles.buttons}>
                        <CreateGroupButton
                            defaultTitle={group?.title}
                            isEdit={true}
                            id={id}
                            onGroupCreated={(id, title) =>
                                setGroup({ ...group, title })
                            }
                        />
                        <Button
                            onClick={() => setShowDeleteModal(true)}
                            type={ButtonType.cancel}
                        >
                            Delete Folder
                        </Button>
                    </div>
                )}
            </div>
            <div className={styles.types}>
                {group?.implants.length > 0 && (
                    <div className={styles.type}>
                        <h2>Implants</h2>
                        <ul>
                            {group?.implants.map((implant: any) => (
                                <li>
                                    <CardComponent
                                        key={implant._id}
                                        title={implant.Model}
                                        description={implant.Type}
                                        link={`/implant/${implant._id}`}
                                        preview={
                                            <div
                                                style={{
                                                    padding: '5px 0 5px 15px',
                                                    height: '50px',
                                                    width: '50px',
                                                }}
                                            >
                                                <Status
                                                    status={
                                                        implant[
                                                            SETTING_KEYS
                                                                .mriStatus
                                                        ]
                                                    }
                                                />
                                            </div>
                                        }
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                {group?.protocols.length > 0 && (
                    <div className={styles.type}>
                        <h2>Protocols</h2>
                        {group?.protocols.map((protocol: any) => (
                            <CardComponent
                                key={protocol._id}
                                title={protocol.Title}
                                description={protocol.updatedAt}
                                link={`/protocols/${protocol._id}`}
                                preview={<FontAwesomeIcon icon={faFileLines} />}
                            />
                        ))}
                    </div>
                )}
                {group?.protocols.length === 0 &&
                    group?.implants.length === 0 && <h3>Folder is empty</h3>}
            </div>
        </div>
    );
};

export default GroupPage;

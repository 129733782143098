import React, { useState, ChangeEvent, LegacyRef, FC, useEffect } from 'react';
import styles from './FormInput.module.css';
import sanitizeHtml from 'sanitize-html';
import { Visibility, VisibilityOff, Error } from '@mui/icons-material';
import RoleSelector from '../manage-user/RoleSelector';
import DepartmentSelector from '../manage-user/DepartmentSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

const FormInput: FC<{
    children?: any;
    defaultValue?: any;
    inputRef?: LegacyRef<HTMLInputElement>;
    className?: string;
    type?: string;
    name?: string;
    onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onSelect?: (e: ChangeEvent<HTMLSelectElement>) => void;
    placeholder?: string;
    errorMsg?: string;
    info?: string;
    label?: string;
    disabled?: boolean;
    tag?: string;
    isSignup?: boolean;
    focusColor?: string;
}> = ({
    children,
    inputRef,
    className = '',
    type,
    name,
    onChange,
    onSelect,
    placeholder,
    errorMsg,
    info,
    defaultValue,
    label,
    disabled,
    tag = 'input',
    isSignup = false,
    focusColor,
}) => {
    const [value, setValue] = useState(defaultValue || '');

    useEffect(() => {
        setValue(defaultValue || '');
    }, [defaultValue]);

    // inputType state used for a tag input with type password for toggling to type text and back, to show and hide content.
    const [inputType, setInputType] = useState(type);

    // isInputFocused state used for emphasize with styling a focused input in a form
    const [isInputFocused, setInputFocused] = useState(false);

    // Add a ref for the textarea
    const textareaRef = React.useRef<HTMLTextAreaElement>(null);

    // Add an effect to handle auto-resizing
    useEffect(() => {
        const textarea = textareaRef.current;
        if (textarea && tag === 'textArea') {
            const adjustHeight = () => {
                textarea.style.height = 'auto';
                if (textarea.scrollHeight > 200) {
                    textarea.style.height = `${textarea.scrollHeight}px`;
                } else {
                    textarea.style.height = '200px';
                }
            };

            adjustHeight();
            // Adjust on value change
            textarea.addEventListener('input', adjustHeight);

            return () => {
                textarea.removeEventListener('input', adjustHeight);
            };
        }
    }, [value, tag]);

    const handleInputFocus = () => {
        setInputFocused(true);
    };

    const handleInputBlur = () => {
        setInputFocused(false);
    };

    const togglePasswordVisibility = () => {
        inputType === 'password' && setInputType('text');
        inputType === 'text' && setInputType('password');
    };

    // combining an permanent class with conditional which could be passed by props from parent component
    const permanentClass = styles.wrapper;
    const conditionalClass = className ? `${styles[className]}` : '';
    const combinedClass = `${permanentClass} ${conditionalClass}`;

    return (
        <div className={combinedClass}>
            <label
                className={isInputFocused ? `${styles.focused}` : ''}
                style={{ color: focusColor }}
                htmlFor={name}
            >
                {label}
            </label>
            <div
                className={`${styles['input-wrapper']} ${
                    tag !== 'textArea' ? styles['fixed'] : ''
                }`}
            >
                {type === 'password' && (
                    <button
                        type="button"
                        className={errorMsg ? styles['hidden'] : styles['']}
                        onClick={togglePasswordVisibility}
                    >
                        {inputType === 'password' ? (
                            <VisibilityOff htmlColor="grey" />
                        ) : (
                            <Visibility htmlColor="grey" />
                        )}
                    </button>
                )}
                {tag === 'input' && (
                    <input
                        id={name}
                        ref={inputRef}
                        className={`${disabled && styles['disabled']} ${
                            errorMsg ? styles['input-error'] : styles['input']
                        }`}
                        type={inputType}
                        name={name}
                        placeholder={placeholder}
                        value={value}
                        onChange={(e) => {
                            setValue(e.currentTarget.value);
                            onChange(e);
                        }}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        disabled={disabled}
                    />
                )}
                {tag === 'textArea' && (
                    <textarea
                        ref={textareaRef}
                        id={name}
                        className={`${disabled && styles['disabled']} ${
                            errorMsg ? styles['input-error'] : styles['input']
                        }`}
                        name={name}
                        placeholder={placeholder}
                        value={value}
                        onChange={(e) => {
                            setValue(e.currentTarget.value);
                            onChange(e);
                        }}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        disabled={disabled}
                        rows={1} // Start with 1 row
                        style={{ overflow: 'hidden' }} // Hide scrollbar
                    />
                )}
                {tag === 'select' && (
                    <select
                        id={name}
                        className={`${disabled && styles['disabled']} ${
                            errorMsg ? styles['input-error'] : styles['input']
                        }`}
                        style={{
                            border: `3px solid ${focusColor}`,
                        }}
                        name={name}
                        // placeholder={placeholder}
                        value={value}
                        onChange={(e) => {
                            setValue(e.currentTarget.value);
                            onSelect(e);
                        }}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        disabled={disabled}
                    >
                        {children}
                    </select>
                )}
                {tag === 'selectRole' && (
                    <RoleSelector
                        id={name}
                        defaultValue={value}
                        onChange={onSelect}
                        handleInputFocus={handleInputFocus}
                        handleInputBlur={handleInputBlur}
                        isSignup={isSignup}
                    />
                )}
                {tag === 'selectDepartment' && (
                    <DepartmentSelector
                        id={name}
                        defaultValue={value}
                        onChange={onSelect}
                        handleInputFocus={handleInputFocus}
                        handleInputBlur={handleInputBlur}
                    />
                )}
                {errorMsg && (
                    <span className={styles['warning-icon']}>
                        <Error />
                    </span>
                )}
            </div>

            <span
                className={`${styles['supporting-text']} ${
                    isInputFocused ? styles['focused'] : styles['error']
                }`}
                aria-live="polite"
                dangerouslySetInnerHTML={{ __html: sanitizeHtml(errorMsg) }}
            ></span>
            {info && (
                <span
                    className={styles.info}
                    aria-live="polite"
                    dangerouslySetInnerHTML={{ __html: sanitizeHtml(info) }}
                ></span>
            )}
        </div>
    );
};

export default FormInput;

import { useState } from 'react';
import Modal from './Modal';
import Button, { ButtonType } from '../general-components/Button';
import {
    deactivateDepartmentsLisense,
    updateDepartment,
} from '../../services/apiService';
import styles from './Organizations.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import Select from '../general-components/Select';
import FEATURE_KEYS from '../../constants/feature-keys';

const OrganizationRow: React.FC<{
    department: any;
    refreshData: Function;
}> = ({ department, refreshData }) => {
    const [showModal, setShowModal] = useState('');
    const [isSaved, setIsSaved] = useState(false);

    const d = department.end_date?.split('T')[0];

    const [newExpiry, setNewExpiry] = useState(null);
    const [selectedFeatures, setSelectedFeatures] = useState<string[]>(
        department.featureAccess || []
    );

    let daysLeft =
        new Date(department.end_date).getTime() - new Date().getTime();
    daysLeft = Math.ceil(daysLeft / (1000 * 3600 * 24));

    const diactivateLicenses = async () => {
        const success = await deactivateDepartmentsLisense(department._id);
        if (success) {
            setIsSaved(true);
            refreshData();
        }
    };

    const updateDepartmentLicense = async () => {
        const success = await updateDepartment(department._id, {
            end_date: newExpiry ?? new Date(d),
            featureAccess: selectedFeatures,
        });
        if (success) {
            setIsSaved(true);
            refreshData();
        }
    };

    const handleFeatureChange = (feature: string) => {
        setSelectedFeatures((prev) => {
            if (prev.includes(feature)) {
                return prev.filter((f) => f !== feature);
            }
            return [...prev, feature];
        });
    };

    return (
        <>
            {showModal === 'deactivate' && (
                <Modal
                    info={{
                        title: 'Dieactivate license',
                        submitButtonText: 'Deactivate',
                        message: 'Deactivate license',
                        submitButtonDanger: true,
                        isSubmitted: isSaved,
                    }}
                    updateView={() => {
                        setShowModal('');
                    }}
                    submitModal={diactivateLicenses}
                >
                    <p>
                        The "End Date" for {department.name} license will be set
                        to today.
                    </p>
                </Modal>
            )}
            {showModal === 'edit' && (
                <Modal
                    info={{
                        title: 'Edit Department License expiry',
                        submitButtonText: 'Update',
                        message: `Update the end date for ${department.name}`,
                        submitButtonDanger: true,
                        isSubmitted: isSaved,
                    }}
                    updateView={() => {
                        setShowModal('');
                    }}
                    submitModal={updateDepartmentLicense}
                >
                    <div>
                        <input
                            style={{ color: 'black' }}
                            defaultValue={d}
                            type="date"
                            onChange={(e) => {
                                const date = new Date(e.target.value);
                                setNewExpiry(date);
                            }}
                        />
                        <div style={{ marginTop: '20px' }}>
                            <h4>Feature Access</h4>
                            <Select
                                type="checkbox"
                                name="features"
                                options={Object.values(FEATURE_KEYS)}
                                defaultChecked={selectedFeatures}
                                changeSelected={handleFeatureChange}
                            />
                        </div>
                    </div>
                </Modal>
            )}
            <tr>
                <td>{department.name}</td>
                <td>{department.licenses}</td>
                <td
                    className={
                        department.active_licenses > 5
                            ? styles.licensesGreen
                            : styles.licensesOrange
                    }
                >
                    <span>{department.active_licenses}</span>
                </td>
                <td>{department.users[0].email}</td>
                <td>{new Date(department.start_date).toLocaleDateString()}</td>
                <td className={styles.endDate}>
                    {new Date(department.end_date).toLocaleDateString()}
                    <Button
                        type={ButtonType.tietary}
                        onClick={() => {
                            setShowModal('edit');
                        }}
                    >
                        <FontAwesomeIcon icon={faPenToSquare} />{' '}
                    </Button>
                </td>
                <td>{daysLeft > 0 ? daysLeft : 0}</td>
                <td>
                    <Button
                        type={ButtonType.cancel}
                        onClick={() => {
                            setShowModal('deactivate');
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faTrashCan}
                            color="var(--main-white)"
                        />
                    </Button>
                </td>
            </tr>
        </>
    );
};

export default OrganizationRow;

import ResultFilter from '../../search/ResultFilters';
import {
    Sheet,
    SheetClose,
    SheetContent,
    SheetDescription,
    SheetFooter,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from '../sheet';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

type FilterSearchSheetProps = {
    results: any;
    currentTerm: string;
    defaultActiveFilters: { [key: string]: string[] };
    setActiveFilters: (filter: { [key: string]: string[] }) => void;
    search: (
        term: string,
        fullview?: boolean,
        filters?: { [key: string]: string[] }
    ) => void;
};

export function FilterSearchSheet({
    results,
    currentTerm,
    defaultActiveFilters,
    setActiveFilters,
    search,
}: FilterSearchSheetProps) {
    return (
        <Sheet>
            <SheetTrigger asChild>
                <button className="border border-white px-2 rounded-md">
                    Filter Search
                    <KeyboardArrowDownIcon fontSize="large" className="ml-2" />
                </button>
            </SheetTrigger>
            <SheetContent className="bg-[#212121] text-white overflow-auto">
                {/* <SheetHeader>
                    <SheetTitle>Filter your search results</SheetTitle>
                    <SheetDescription>
                        Check all the options that you want to be in the search
                        result
                    </SheetDescription>
                </SheetHeader> */}
                <div className="">
                    <div className="">
                        {results.length > 0 && (
                            <div className="flex justify-center">
                                <div
                                // className={styles.filters}
                                >
                                    <ResultFilter
                                        term={currentTerm}
                                        defaultActiveFilters={
                                            defaultActiveFilters
                                        }
                                        onChange={(filter) => {
                                            setActiveFilters(filter);
                                            search(currentTerm, false, filter);
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {/* <SheetFooter>
                    <SheetClose asChild>
                        <button type="submit">Close</button>
                    </SheetClose>
                </SheetFooter> */}
            </SheetContent>
        </Sheet>
    );
}

import { instance, GETREQUESTOPTIONS } from '../apiService';

export const validateSubscription = async (userId: string) => {
    const res = await instance.get('/api/subscription/validate/' + userId);
    return res.data;
};

export const getAllVariants = async () => {
    const res = await instance.get('/api/subscription/variants/');
    return res.data;
};

export interface SubscriptionInfo {
    status: string;
    billingDate: string;
    endDate: string;
    variantName: string;
    variantPrice: number;
    variantInterval: string;
    description: string;
    portalUrl: string;
}

export const getSubscriptionInfo = async (): Promise<SubscriptionInfo> => {
    const res = await instance.get(
        '/api/subscription/info/',
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const createContact = async (email: string) => {
    const res = await instance.post('/api/subscription/create-contact/', {
        email,
    });
    return res.data;
};

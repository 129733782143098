import React from 'react';
import { useCPTReportContext } from '../../../store/cptReport-context';
import FormInput from '../../general-components/FormInput';
import SETTING_KEYS from '../../../constants/setting-keys';
import Select from '../../general-components/Select';
import styles from '../ReportWizard.module.css';
import RichText from '../../general-components/RichText';
import { toTitleCase } from '../../../utils/utils';

const ImplantDetails: React.FC<{ implant?: any }> = ({ implant }) => {
    const { report, getHandleEventChange, getHandleValueChange } =
        useCPTReportContext();

    function convertStatusToText(status: number): string {
        switch (status) {
            case 0:
                return 'Unknown';
            case 1:
                return 'MR Unsafe';
            case 2:
                return 'MR Conditional';
            case 3:
                return 'MR Safe';
        }
    }

    return (
        <div className={styles.form}>
            <div className={styles.row}>
                <FormInput
                    label="Model"
                    type="text"
                    name="model"
                    defaultValue={
                        report.mriSafetyDecisionInfo.implantDetails.model
                    }
                    onChange={getHandleEventChange(
                        'mriSafetyDecisionInfo.implantDetails.model'
                    )}
                />
                <FormInput
                    label="Manufacturer"
                    type="text"
                    name="manufacturer"
                    defaultValue={
                        report.mriSafetyDecisionInfo.implantDetails.manufacturer
                    }
                    onChange={getHandleEventChange(
                        'mriSafetyDecisionInfo.implantDetails.manufacturer'
                    )}
                />
            </div>
            <div className={styles.row}>
                <FormInput
                    label="Type"
                    type="text"
                    name="type"
                    defaultValue={
                        report.mriSafetyDecisionInfo.implantDetails.type
                    }
                    onChange={getHandleEventChange(
                        'mriSafetyDecisionInfo.implantDetails.type'
                    )}
                />
                {implant?.info[SETTING_KEYS.catalogNumbers] && (
                    <FormInput
                        label="Catalog Number"
                        tag="select"
                        name="catalogNumber"
                        defaultValue={
                            report.mriSafetyDecisionInfo.implantDetails
                                .catalogNumber
                                ? report.mriSafetyDecisionInfo.implantDetails
                                      .catalogNumber
                                : ''
                        }
                        onSelect={getHandleEventChange(
                            'mriSafetyDecisionInfo.implantDetails.catalogNumber'
                        )}
                    >
                        <option value="" disabled>
                            Select Catalog Number
                        </option>
                        {implant.info[SETTING_KEYS.catalogNumbers].map(
                            (catalog: any) => (
                                <option value={catalog.value}>
                                    {catalog.value}
                                </option>
                            )
                        )}
                    </FormInput>
                )}
            </div>
            <div className={styles.row}>
                <FormInput
                    label="MRI Status"
                    tag="select"
                    name="mriStatus"
                    defaultValue={report.conditionalsAnalysis.mriStatus}
                    onSelect={getHandleEventChange(
                        'conditionalsAnalysis.mriStatus'
                    )}
                >
                    {[0, 1, 2, 3].map((status) => (
                        <option value={status}>
                            {convertStatusToText(status)}
                        </option>
                    ))}
                </FormInput>
            </div>
            <div className={styles.row}>
                <RichText
                    description={report.conditionalsAnalysis.restrictions}
                    onChange={getHandleValueChange(
                        'conditionalsAnalysis.restrictions'
                    )}
                />
            </div>
            <div className={styles.row}>
                <div>
                    <h5>Risk Level</h5>
                    <Select
                        name="riskLevel"
                        type="radio"
                        options={['Low', 'Medium', 'High']}
                        defaultChecked={[
                            toTitleCase(report.conditionalsAnalysis.riskLevel),
                        ]}
                        changeSelected={getHandleValueChange(
                            'conditionalsAnalysis.riskLevel'
                        )}
                    />
                </div>
            </div>
        </div>
    );
};

export default ImplantDetails;

import React, { useEffect, useState, useContext } from 'react';
import AuthContext from '../../store/auth-context';
import DepartmentContext from '../../store/department-context';
import { ReactComponent as AddUserSvg } from '../../icons/add-user.svg';
import { ManageUserContextProvider } from '../../store/manage-user-context';
import UserRow from './../manage-user/UserRow';
import RIGHTS_KEYS from '../../constants/rights-keys';
import styles from './ManageUsers.module.css';
import {
    getAllUsers,
    getInvitations,
    getUsersByDepartment,
    Invitation,
} from '../../services/apiService';
import AddUser from './AddUser';
import ContextualUserRow from './ContextualUserRow';
import LoadingRing from '../ui-components/LoadingRing';
import SendReminders from './SendReminders';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToolbox, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import Button, { ButtonType } from '../general-components/Button';
import DepartmentGuests from '../orgnization/DepartmentGuests';
import FEATURE_KEYS from '../../constants/feature-keys';

document.addEventListener(
    'click',
    function (event) {
        const popupElm = document.getElementById('popup');
        if (
            popupElm &&
            popupElm.style.display !== 'none' &&
            !(event.target as HTMLElement).closest('.popup-box') &&
            !(event.target as HTMLElement).closest('.deactivate-button') &&
            !(event.target as HTMLElement).closest('.expiry-button')
        ) {
            popupElm.style.display = 'none';
        }
    },
    false
);

const ManageUsers = () => {
    const context = useContext(AuthContext);
    const departmentContext = useContext(DepartmentContext);
    const [modal, setModal] = useState('');

    const [users, setUsers] = useState<any[]>([]);
    const [invites, setInvites] = useState<Invitation[]>([]);

    const [usersLoading, setUsersLoading] = useState(true);
    const [invitesLoading, setInvitesLoading] = useState(true);

    const getData = async () => {
        if (context.orRights([RIGHTS_KEYS.manageAllUsers])) {
            const users = await getAllUsers();
            setUsersLoading(false);
            setUsers(users);
            const invitations = await getInvitations();
            setInvitesLoading(false);
            setInvites(invitations);
        } else if (context.orRights([RIGHTS_KEYS.manageEmployees])) {
            const users = await getUsersByDepartment();
            setUsersLoading(false);
            setUsers(users);
            departmentContext.updateActiveUsers(users);
            const invitations = await getInvitations();
            setInvitesLoading(false);
            setInvites(invitations);
        }
    };

    const loadInvites = async () => {
        setInvitesLoading(true);
        const invitations = await getInvitations();
        setInvitesLoading(false);
        setInvites(invitations);
    };

    const updateUser = (user: any, remove?: boolean) => {
        if (remove) {
            setUsers(users.filter((u: any) => u.id !== user.id));
        }
    };

    const updateInvite = (inv: any, remove?: boolean) => {
        if (remove) {
            setInvites(invites.filter((i: any) => i._id !== inv._id));
        }
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <React.Fragment>
            {(modal === 'addUser' || modal === 'addAdmin') && (
                <AddUser
                    isAdmin={modal === 'addAdmin'}
                    close={() => {
                        setModal('');
                    }}
                    onSubmit={loadInvites}
                />
            )}

            <div className={styles.wrapper}>
                {context.orRights([RIGHTS_KEYS.manageEmployees]) && (
                    <div className={styles['access-info']}>
                        <div className={styles.manageuser}>
                            <p className={styles.title}>Access expires on</p>
                            {departmentContext.endDate.toLocaleDateString(
                                'en-uk',
                                {
                                    year: 'numeric',
                                    day: '2-digit',
                                    month: 'short',
                                }
                            )}
                        </div>
                        <div className={styles.manageuser}>
                            <p className={styles.title}>
                                Active users / Total licenses
                            </p>
                            <div className={styles.licenses}>
                                {departmentContext.activeUsers.length} /{' '}
                                {departmentContext.deptLicenses}
                                <div className={styles.progressbar}>
                                    <span
                                        className={styles.progressbarBefore}
                                    ></span>
                                    <span
                                        className={styles.progressbarAfter}
                                        style={{
                                            width:
                                                (departmentContext.activeUsers
                                                    .length /
                                                    departmentContext.deptLicenses) *
                                                    200 +
                                                'px',
                                        }}
                                    ></span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <h2>Users</h2>
                {!usersLoading && users.length === 0 && (
                    <div className={styles.loading}>
                        <h3>No colleagues signed up yet</h3>
                    </div>
                )}
                {usersLoading && (
                    <div className={styles.loading}>
                        <LoadingRing size="large" />
                    </div>
                )}
                {!usersLoading && users.length > 0 && (
                    <div className={styles.userlist}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Status</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>User type</th>
                                    {context.orRights([
                                        RIGHTS_KEYS.manageAllUsers,
                                        RIGHTS_KEYS.manageEmployees,
                                    ]) && <th>Permissions</th>}
                                    <th>Expiry date</th>
                                    {context.orRights([
                                        RIGHTS_KEYS.manageAllUsers,
                                        RIGHTS_KEYS.manageEmployees,
                                    ]) && <th>Edit</th>}
                                    <th>Revoke</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((u) => (
                                    <ManageUserContextProvider
                                        key={u.id}
                                        user={u}
                                        userDeleted={updateUser}
                                    >
                                        <ContextualUserRow
                                            onChange={updateUser}
                                            deptLicenses={
                                                departmentContext.deptLicenses
                                            }
                                        />
                                    </ManageUserContextProvider>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

                <h2>Awaiting Invitations</h2>
                <div className={styles.actions}>
                    <Button
                        type={ButtonType.submit}
                        onClick={() => {
                            setModal('addUser');
                        }}
                    >
                        <FontAwesomeIcon icon={faUserPlus} />
                        Invite users
                    </Button>
                    <Button
                        type={ButtonType.primary}
                        onClick={() => {
                            setModal('addAdmin');
                        }}
                    >
                        <FontAwesomeIcon icon={faToolbox} />
                        Invite Administrators
                    </Button>
                    {invites.length > 0 && (
                        <SendReminders
                            infos={invites.map((i) => {
                                return { email: i.email, id: i._id };
                            })}
                        >
                            <FontAwesomeIcon icon={faBell} />
                            Send reminders to all
                        </SendReminders>
                    )}
                </div>
                {!invitesLoading && invites.length === 0 && (
                    <div className={styles.loading}>
                        <h4>No Pending invitations</h4>
                    </div>
                )}
                {invitesLoading && (
                    <div className={styles.loading}>
                        <LoadingRing size="large" />
                    </div>
                )}
                {!invitesLoading && invites.length > 0 && (
                    <div className={styles.invitelist}>
                        <table>
                            <thead>
                                <tr>
                                    <th colSpan={5}>Email</th>
                                    {/* <th>User type</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {invites.map((i) => (
                                    <UserRow
                                        key={i.email}
                                        userInfo={{
                                            id: i._id,
                                            email: i.email,
                                            isAdmin: i.isAdmin,
                                        }}
                                        isInvite={true}
                                        invite={i}
                                        onChange={updateInvite}
                                        deptLicenses={
                                            departmentContext.deptLicenses
                                        }
                                    />
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
                {context.orRights([FEATURE_KEYS.guestAccess]) && (
                    <DepartmentGuests />
                )}
            </div>
        </React.Fragment>
    );
};

export default ManageUsers;

const RIGHTS_KEYS = {
    manageAllUsers: 'ManageAllUsers',
    manageActors: 'ManageActors',
    manageEmployees: 'ManageEmployees',
    manageEmails: 'ManageEmails',
    manageDepartments: 'ManageDepartments',
    manageImplant: 'ManageManufacturerImplants',
    manageAllImplants: 'ManageAllImplants',
    dbSearch: 'DBSearch',
    addImplant: 'AddImplant',
    createNews: 'CreateNews',
    createDeptNews: 'CreateDepartmentNews',
    manageLicenses: 'ManageLicenses',
    manageMRIOverview: 'ManageMRIOverview',
    viewMRIOverview: 'ViewMRIOverview',
    manageVideoUpload: 'ManageVideoUpload',
    verifyImplants: 'VerifyImplants',
    reverifyImplants: 'ReverifyImplants',
    advancedSearch: 'AdvancedSearch',
    detailedInfo: 'DetailedInfo',
    viewSource: 'ViewSource',
    deptTools: 'DepartmentTools',
    managePredefined: 'ManagePredefinedConditionals',
    cptReporting: 'CPTReporting',
    internalInfo: 'Internal Info',
    guestAccess: 'GuestAccess',
};

export default RIGHTS_KEYS;

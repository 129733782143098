import { useState } from 'react';
import Button, { ButtonType } from '../general-components/Button';
import FormInput from '../general-components/FormInput';
import ConfirmationModal from '../general/modal/ConfirmationModal';
import { createGroup, updateGroup } from '../../services/apiService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderPlus } from '@fortawesome/free-solid-svg-icons';

const CreateGroupButton: React.FC<{
    onGroupCreated?: (id: string, title: string) => void;
    isEdit?: boolean;
    id?: string;
    defaultTitle?: string;
}> = ({ onGroupCreated, isEdit, id, defaultTitle }) => {
    const [showCreateGroup, setShowCreateGroup] = useState(false);
    const [title, setTitle] = useState('');

    const saveGroup = async () => {
        let groupId: string = id;
        if (isEdit) {
            const res = await updateGroup(id, title);
        } else {
            const res = await createGroup(title);
            groupId = res.id;
        }
        setShowCreateGroup(false);
        onGroupCreated(groupId, title);
        setTitle('');
    };

    return (
        <div>
            {showCreateGroup && (
                <ConfirmationModal
                    title={isEdit ? 'Edit Folder' : 'Create Folder'}
                    message="Please enter title of the folder"
                    onConfirm={saveGroup}
                    onClose={() => setShowCreateGroup(false)}
                    confirmText={isEdit ? 'Update' : 'Create'}
                    cancelText="Cancel"
                >
                    <FormInput
                        defaultValue={defaultTitle}
                        onChange={(e) => setTitle(e.target.value)}
                        label="Folder Name"
                    />
                </ConfirmationModal>
            )}
            <Button
                small
                className="text-nowrap"
                onClick={() => setShowCreateGroup(true)}
                type={ButtonType.primary}
            >
                {!isEdit ? (
                    <>
                        <FontAwesomeIcon icon={faFolderPlus} />
                        New Folder
                    </>
                ) : (
                    'Edit Folder'
                )}
            </Button>
        </div>
    );
};

export default CreateGroupButton;

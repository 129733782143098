import { GETREQUESTOPTIONS, instance } from '../apiService';

export const updateCurrentDepartment = async (departmentId: string) => {
    const res = await instance.put(
        '/api/organization/department/current',
        { departmentId },
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const getUserDepartments = async () => {
    const res = await instance.get(
        '/api/organization/departments',
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const getGuests = async () => {
    const res = await instance.get(
        `/api/organization/department/guests`,
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const addGuestToDepartment = async (
    email: string,
    role: string,
    permissions: string[]
) => {
    const res = await instance.put(
        `/api/organization/department/guest`,
        { email, role, permissions },
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const removeGuestDepartment = async (email: string) => {
    const res = await instance.delete(
        `/api/organization/department/guest/${email}`,
        GETREQUESTOPTIONS()
    );
    return res.data;
};

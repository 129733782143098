export const autofill = {
    info: {
        name: '',
        facilityName: '',
        dateOfAssessment: '',
        dateOfService: '',
        purpose: '',
        sources: {
            'Imaging Reports / x-ray / CT-scans': `Reviewed imaging reports, to accurately identify the presence of an implant/foreign body`,
            'EHR Report': `Researched the EHR report to correctly identify the implant/foreign body in the patient.`,
            'Surgical Reports': `Reviewed surgical reports to confirm the implant/foreign body`,
            'Vendor Consultation': `Consulted the vendor for accurate identification of the implant/foreign body`,
        },
        notes: '',
    },
    safetyWork: {
        clinicalIndication: '',
        purpose: {
            Implant: `A pre-scan assessment has been conducted to review the presence of implants, ensuring their MR conditionality or safety status is verified to minimize risks during the MRI procedure.`,
            'Foreign Body': `A pre-scan assessment has been conducted to review the presence of foreign bodies, ensuring their MR conditionality or safety status is verified to minimize risks during the MRI procedure.`,
        },
        objectives: '',
    },
    mriSafetyDecisionInfo: {
        implantDetails: {
            model: '',
            catalogNumber: '',
            type: '',
            manufacturer: '',
        },
        foreignBodyDetails: {
            type: '',
            location: '',
            description: '',
            material: '',
            dimensions: '',
            hazards: {
                'Magnetic Force/Migration Risk':
                    'The foreign body presents a potential risk of displacement or migration due to translational forces exerted by the strong magnetic field of the MRI scanner. This could result in movement of the foreign body, leading to tissue damage or functional compromise of nearby structures.',
                'RF-Induced Heating':
                    'The foreign body may absorb radiofrequency energy during the MRI scan, leading to localized heating. This heating effect can cause thermal injury to adjacent tissues, particularly for metallic or conductive materials.',
                'Image Artifacts/Distortions':
                    'The presence of the foreign body may cause significant image artifacts or distortions due to its material composition, shape, or location. This can degrade diagnostic image quality, particularly in regions near or surrounding the foreign body.',
                'Local Tissue Damage (Non-Thermal)':
                    'The foreign body poses a risk of localized tissue damage unrelated to thermal effects, such as mechanical irritation or pressure effects, which may be exacerbated during the MRI procedure.',
                'Electrical Conductivity/Induced Currents':
                    'The conductive nature of the foreign body increases the risk of induced electrical currents caused by the MRI’s rapidly changing gradient magnetic fields. These currents could result in unintended stimulation or functional interference with nearby tissues or devices.',
                'Unknown Material Properties (Safety Uncertainty)':
                    'The safety of the foreign body within the MR environment cannot be determined due to incomplete or unknown material properties. This uncertainty poses a potential risk for adverse effects, including migration, heating, or artifact generation.',
            },
            hazardExplanation: '',
        },
        safetyAnalysis: {
            risk: '',
            mitigation: '',
            limitSar: '',
            sequenceMod: '',
            additionalMod: {
                'Pulse Oximetry':
                    'Continuous monitoring of peripheral oxygen saturation (SpO₂) has been determined essential during the MRI scan to ensure adequate oxygenation and early detection of hypoxemia in the patient.',
                ECG: 'Electrocardiographic monitoring has been deemed critical during the MRI procedure to assess cardiac rhythm, detect arrhythmias, and ensure patient safety, particularly for those with cardiac conditions or implantable devices.',
                'Blood Pressure Monitoring':
                    'Non-invasive or invasive blood pressure monitoring will be conducted during the MRI procedure to assess hemodynamic stability, particularly in patients with cardiovascular risk factors or critical care requirements.',
                'Temperature Monitoring':
                    'Temperature monitoring is essential to ensure thermoregulatory stability during the MRI scan, especially in patients prone to hyperthermia or hypothermia, as well as to detect potential heating effects from the MR environment.',
                'Respiratory Monitoring':
                    'Continuous respiratory rate and effort monitoring has been recommended to assess pulmonary function and detect signs of respiratory distress, ensuring optimal ventilation during the MRI procedure.',
                'Camera Systems':
                    'Video monitoring using camera systems will provide direct visualization of the patient, enhancing safety and enabling immediate detection of patient distress, motion, or positional adjustments required during the scan.',
                'Window Observation':
                    'Window observation will be utilized to maintain a direct line of sight on the patient during the MRI scan, ensuring prompt recognition of adverse events or discomfort without interrupting the procedure.',
                'End-Tidal CO₂ Monitoring':
                    'Capnographic monitoring of end-tidal carbon dioxide (ETCO₂) is indicated to evaluate ventilation adequacy and detect hypoventilation or airway compromise during the MRI procedure, especially in sedated or anesthetized patients.',
                'Specialized MR-Conditional Anesthesia Equipment':
                    'MR-conditional anesthesia equipment will be deployed to safely administer and monitor sedation or anesthesia in compliance with MR safety guidelines, minimizing risks associated with electromagnetic interference.',
                'Motion Detection Systems':
                    'Motion detection systems will be employed to track and mitigate patient movement during the scan, ensuring optimal image quality and reducing the need for repeat imaging.',
                'Comfort Measures':
                    'Patient comfort measures, including padding and positioning aids, will be implemented to reduce anxiety, ensure immobilization, and enhance tolerance of the MRI scan duration.',
                'Ear Protection':
                    'Hearing protection will be provided to mitigate acoustic noise exposure from the MRI scanner, reducing the risk of auditory damage or discomfort during the procedure.',
                'Fetal Monitoring':
                    'Fetal monitoring, including heart rate and movement tracking, will be conducted during the MRI procedure to ensure the safety of both the fetus and the pregnant patient.',
                'Neonatal and Pediatric Monitoring':
                    'Specialized neonatal and pediatric monitoring systems will be utilized to provide continuous assessment of vital signs, ensuring the safety and comfort of the patient during the MRI scan, given their unique physiological vulnerabilities.',
            },
            staffNeeded: {
                MRSO: 'The presence of an MR Safety Officer is required to oversee all safety aspects of the MRI procedure, ensuring compliance with established safety protocols, managing risks related to implants or foreign bodies, and addressing any safety concerns during the scan.',
                'MRI Physicist':
                    'An MRI Physicist is necessary to provide expertise in the customization of imaging protocols, assessment of MR system performance, and mitigation of risks associated with complex implants or challenging patient scenarios.',
                'Radiologist with Subspecialty Training':
                    'A radiologist with subspecialty training is required to interpret complex imaging studies in real time, provide guidance on imaging protocols, and make informed decisions about clinical and safety considerations during the scan.',
                'MR Safety Expert / Consultant':
                    'An MR Safety Expert or Consultant is required for cases involving unusual safety challenges, such as the evaluation of unknown implants, MR-unsafe conditions, or unique clinical scenarios requiring expert judgment and tailored risk management strategies.',
                'Anesthesia or Sedation Specialist':
                    'An anesthesia or sedation specialist is essential for managing sedation, monitoring patient vitals, and ensuring airway protection during MRI scans for patients who require sedation or anesthesia to tolerate the procedure.',
                'Infection Control or Sterility Specialist':
                    'An infection control or sterility specialist is required to manage sterility protocols for procedures involving invasive devices or patients with compromised immune systems, ensuring compliance with infection prevention standards during the MRI scan.',
                'Biomedical Engineer or Device Specialist':
                    'A biomedical engineer or device specialist is required to provide technical expertise in the evaluation and safe operation of active implants or devices, ensuring proper function and compatibility in the MR environment.',
                'MRI Research Scientist':
                    'An MRI Research Scientist is necessary for research studies requiring advanced imaging techniques, protocol development, or experimental scans that demand expertise beyond standard clinical practice.',
                'MR-Guided Interventional Radiologist':
                    'An MR-guided interventional radiologist is required for procedures involving real-time MRI guidance, such as biopsies or ablations, to ensure accurate targeting and safe execution of interventional techniques.',
                'Advanced MRI Technologist (MRIT)':
                    'An advanced MRI technologist is essential for operating the scanner, troubleshooting complex technical issues, and implementing customized imaging protocols in cases that require specialized expertise.',
                'Vendor Application Specialist':
                    'A vendor application specialist is needed to provide expert support on the MRI system’s capabilities, software updates, or advanced applications, particularly during complex scans or the introduction of new imaging protocols.',
            },
        },
    },
    conditionalsAnalysis: {
        mriStatus: '',
        restrictions: '',
        riskLevel: 'low',
        benefits: {
            benefits: '',
            conclusion: {
                'Proceed with modifications':
                    'The MRI scan is deemed safe to proceed with the specified modifications in place. The proposed adjustments to the scanning protocol, patient positioning, or equipment usage are expected to mitigate identified risks, ensuring patient safety and diagnostic quality.',
                'Defer MRI until additional safety measures are available':
                    'The MRI scan is deferred at this time due to the presence of unresolved safety concerns. Additional measures, such as further implant verification, alternative patient preparation, or specialized equipment, are required to ensure the procedure can be performed safely.',
                'Alternative imaging recommended.':
                    'MRI is not recommended for this patient due to contraindications or safety concerns that cannot be mitigated. Alternative imaging modalities, such as CT, ultrasound, or X-ray, are advised to achieve the diagnostic objectives while ensuring patient safety.',
            },
            explanation: '',
        },
    },
    guidance: {
        sopName: '',
        sources: {
            "NordInsight's Implant Database":
                "Used NordInsight's implant database as the primary reference for determining the MRI safety guidance.",
            'Manufacturer IFU':
                "Referenced the manufacturer's Instructions For Use (IFU) to provide MRI safety guidance.",
            'Internal SOP':
                'Followed internal Standard Operating Procedures (SOP) for determining MRI compatibility.',
            Other: 'Consulted alternative sources to establish MRI guidance.',
        },
        explanation: '',
        recommendation: {
            'Proceed with conditions':
                'MRI can be performed under specific conditions outlined in the guidance provided.',
            'Do not proceed':
                'MRI should not be performed due to safety concerns identified in the evaluation.',
            'Protocol Modifications and Risk/Benefit Analysis needed (CPT 76016)':
                'Protocol Modifications and Risk/Benefit Analysis needed (CPT 76016)',
        },
        notes: '',
    },
    scannerSettings: {
        scannerModel: '',
        technique: '',
        patientPosition: '',
    },
    summary: {
        codes: [] as string[],
        totalTimeCPTCodes: 0,
        notes: '',
    },
    department_id: '',
    approval: {
        approved: false,
        date: new Date(),
        by: {
            name: '',
        },
        notes: '',
    },
};

export function setNestedValue(obj: any, path: string, value: any) {
    const keys = path.replace(/\[(.*?)\]/g, '.$1').split('.'); // Convert path to dot notation
    let current = obj;

    for (let i = 0; i < keys.length - 1; i++) {
        const key = keys[i];
        if (!(key in current)) {
            current[key] = {}; // Ensure intermediate objects exist
        }
        current = current[key];
    }

    current[keys[keys.length - 1]] = value; // Set the final value
}

export function getNestedValue(obj: any, path: string) {
    const keys = path.replace(/\[(.*?)\]/g, '.$1').split('.'); // Convert path to dot notation
    let current = obj;

    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (!(key in current)) {
            return ''; // Return undefined if the key does not exist
        }
        current = current[key];
    }
    return current; // Return the final value
}

export const getUpdatedListAndText = (
    key: string,
    value: string,
    list: string[],
    notes: string,
    isRichText?: boolean
) => {
    const textMap = getNestedValue(autofill, key);
    let newlist: string[];
    let newNotes: string;
    console.log(key, value, list, notes);
    if (list.includes(value)) {
        newlist = list.filter((s: string) => s !== value);
        newNotes = notes.replace(
            textMap[value] + (isRichText ? '<br><br>' : '\n\n'),
            ''
        );
    } else {
        newlist = [...list, value];
        newNotes = notes + textMap[value] + (isRichText ? '<br><br>' : '\n\n');
    }
    return { newlist, newNotes };
};

export const getUpdatedText = (key: string, value: string, notes: string) => {
    const textMap = getNestedValue(autofill, key);
    for (const key in textMap) {
        notes = notes.replace(textMap[key] + '\n', '');
    }
    notes = textMap[value] + '\n' + notes;
    return notes;
};

import SignupLayout from '../ui-components/SignupLayout';
import { useParams, useSearchParams } from 'react-router-dom';
import SignupForm from './SignupForm';
import React, { useContext, useEffect, useState } from 'react';
import styles from './SignupPage.module.css';
import Button, { ButtonType } from '../general-components/Button';
import SignupContext from '../../store/signup-context';
import { CircleCheck } from 'lucide-react';

const DisabledSignup = () => (
    <React.Fragment>
        <h3 style={{ width: '400px' }}>
            We are currently only open for registration through invitation.{' '}
            <br />
            <br /> Contact us on info@nordinsight.com to get invited.
        </h3>
    </React.Fragment>
);

const WrongInvitationSignup = () => (
    <React.Fragment>
        <h3 style={{ width: '400px' }}>
            Sorry, your invitation has either expired or you are trying to
            access an invalid link.
            <br />
            <br /> Contact the manager who invited you or us on
            info@nordinsight.com to help resolve this issue.
        </h3>
    </React.Fragment>
);

export const ConfirmedSignup = () => (
    <React.Fragment>
        <CircleCheck size={'75px'} strokeWidth={'0.75px'} />
        <h3>You are now registered.</h3>
        <p>
            Go to the login page and use the credentials that you just provided.
        </p>
        <Button
            small
            className={styles.button}
            type={ButtonType.primary}
            onClick={() => {
                window.location.href = '/';
            }}
        >
            Return to login page
        </Button>
    </React.Fragment>
);

const PaymentMissing = () => {
    return (
        <>
            <h3>Continue your sign up</h3>
            <p>
                Go to the payment form to finalize your sign up to access
                NordInsight.
            </p>
            <Button
                className={styles.button}
                type={ButtonType.primary}
                onClick={() => (window.location.href = '/payment')}
            >
                Go to payment
            </Button>
        </>
    );
};

const SignupPage = () => {
    const {
        fetchSignupData,
        setDefaultEmail,
        setId,
        currentElement,
        id,
        setFormData,
        defaultEmail,
    } = useContext(SignupContext);

    let { code } = useParams<{ code: string }>();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (id !== '' && defaultEmail !== '') {
            fetchSignupData();
        }
    }, [id, defaultEmail]);

    useEffect(() => {
        const email = searchParams.get('email');
        setDefaultEmail(email);
        setFormData({
            type: 'update',
            name: 'email',
            value: email,
        });
        setId(searchParams.get('invite') || code);
    }, []);

    return (
        <SignupLayout>
            {currentElement === 'signup' && <SignupForm />}
            {currentElement === 'disabled' && <DisabledSignup />}
            {currentElement === 'wrong' && <WrongInvitationSignup />}
            {currentElement === 'confirm' && code !== 'trial' && (
                <ConfirmedSignup />
            )}
            {currentElement === 'payment-missing' && <PaymentMissing />}
        </SignupLayout>
    );
};

export default SignupPage;

import SearchField from './SearchField';
import styles from './SuggestionSearch.module.css';
import SuggestionWrapper from './SuggestionWrapper';
import { useState } from 'react';

const SuggestionSearch: React.FC<{
    defaultValue?: string;
    onSearch: Function;
}> = ({ defaultValue, onSearch }) => {
    const [searchTerm, setSearchTerm] = useState<string>('');

    const search = () => {
        onSearch(searchTerm, false);
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            search();
        }
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    return (
        <div className={styles.wrapper}>
            {/* <SearchBar
                search={search}
                setSearchTerm={setSearchTerm}
                searchTerm={searchTerm}
                handleKeyDown={handleKeyDown}
                handleSearch={handleSearch}
            /> */}
            <div className={styles.input}>
                <SearchField
                    search={search}
                    setSearchTerm={setSearchTerm}
                    searchTerm={searchTerm}
                    onKeyDown={handleKeyDown}
                    onChange={handleSearch}
                />
            </div>
            {searchTerm.length >= 3 && (
                <div className={styles.suggestions}>
                    <SuggestionWrapper term={searchTerm} />
                </div>
            )}
        </div>
    );
};

export default SuggestionSearch;

import { Check, ChevronsUpDown, Plus, SquarePlus } from 'lucide-react';
import { useState, useEffect } from 'react';
import {
    getAllMRIMachines,
    getAllMRIMachinesByDepartment,
    MRIMachineDTO,
} from '../../services/apiService';

import { cn } from '../../lib/utils';
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from '../ui/command';
import { Button } from '../ui/button';
import LoadingRing from '../ui-components/LoadingRing';

export function AddMachine() {
    const [value, setValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [machinesList, setMachinesList] = useState([]);

    const getData = async () => {
        setIsLoading(true);
        const res = await getAllMRIMachines();
        setMachinesList(res);
        setIsLoading(false);
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <Command
            disablePointerSelection={false}
            className="bg-[--nav-background]"
        >
            <CommandInput
                placeholder="Search MRI machine model..."
                className="h-9"
            />

            <CommandList className="">
                <CommandGroup
                    heading={`Create Room from existing MRI machine templates (${machinesList.length})`}
                >
                    {!isLoading && machinesList.length === 0 && (
                        <h3>No MRIMachines found</h3>
                    )}
                    {isLoading ? (
                        <div className="flex justify-center items-center">
                            <LoadingRing size="large" />
                        </div>
                    ) : (
                        <>
                            {[...machinesList]?.map((machine) => (
                                <a
                                    key={machine?._id}
                                    className="text-inherit "
                                    href={`/room-overview/create-room/${machine._id}`}
                                >
                                    <CommandItem
                                        className="text-white"
                                        value={machine?.Model}
                                        onSelect={(currentValue) => {
                                            setValue(
                                                currentValue === value
                                                    ? ''
                                                    : currentValue
                                            );
                                        }}
                                    >
                                        {machine?.Model}
                                    </CommandItem>
                                </a>
                            ))}
                        </>
                    )}
                </CommandGroup>
            </CommandList>
            <div className="flex flex-col gap-3 p-2">
                <h6 className="text-white">OR add new MRI Machine</h6>
                <div className="w-full">
                    <Button
                        onClick={() =>
                            (window.location.href =
                                '/room-overview/create-room/create-new-modal')
                        }
                    >
                        Add New MRI Model <Plus />{' '}
                    </Button>
                </div>
            </div>
        </Command>
    );
}

import React from 'react';
import { Button } from '../ui/button';
import {
    Check,
    CircleCheck,
    CircleCheckBig,
    CircleCheckBigIcon,
    CircleCheckIcon,
    PlusCircle,
} from 'lucide-react';
import { ArrowBack } from '@mui/icons-material';

const SuccessPage = () => {
    return (
        <div className="h-screen flex flex-col justify-center items-center ">
            <div className="rounded-xl p-10 flex justify-center flex-col items-center space-y-10">
                <div className=" text-xl md:text-3xl text-green-900">
                    Room saved successfully!
                </div>
                <CircleCheck size={'75px'} strokeWidth={'0.75px'} />
                <div className="mt-6 space-x-10 flex flex-row ">
                    <Button
                        size="lg"
                        onClick={() =>
                            (window.location.href = '/room-overview')
                        }
                    >
                        <ArrowBack />
                        Go to Rooms
                    </Button>
                    <Button
                        size="lg"
                        onClick={() =>
                            (window.location.href =
                                '/room-overview/create-room')
                        }
                    >
                        <PlusCircle className="mr-0" />
                        <span>Create</span>
                        <span className="hidden md:inline">
                            new MRI Machine
                        </span>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default SuccessPage;

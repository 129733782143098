import MRIMachineForm from './MRIMachineForm';
import { SpatialGradientDTO, MaxSARDTO } from '../../models/MRIMachineModels';
import { useParams } from 'react-router-dom';
import { getMRIMachineById } from '../../services/apiService';
import { useEffect, useState } from 'react';
import LoadingRing from '../ui-components/LoadingRing';

export interface IMRIMachine {
    _id: string;
    department_id: any | string;
    Model: string;
    Name: string;
    FieldStrength: number;
    SpatialGradient: SpatialGradientDTO;
    SlewRatePerAxis: number;
    MaxSlewRate: number;
    MaxSAR: MaxSARDTO;
    MaxB1rms: string;
    TRCoils: string;
}

const AddMRIMachine = () => {
    const [machine, setMachine] = useState<IMRIMachine | null>();
    const [isLoading, setIsLoading] = useState(true);

    let { id } = useParams<{ id: string }>();

    const isValidObjectId = (id: string) => /^[0-9a-fA-F]{24}$/.test(id);

    const getData = async () => {
        if (!id || id === 'create-new-modal' || !isValidObjectId(id)) {
            setIsLoading(false);
            return;
        }

        try {
            const machine = await getMRIMachineById(id);
            setMachine(machine);
        } catch (error) {
            console.error('Error fetching MRI Machine:', error);
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        getData();
    }, []);
    if (!machine && isLoading)
        return (
            <div className="h-full flex justify-center items-center">
                <LoadingRing size="large" />
            </div>
        );
    const Mrimachine = {
        Name: '',
        Model: machine?.Model || '',
        FieldStrength: machine?.FieldStrength || 1.5,
        SpatialGradient:
            machine?.SpatialGradient || ([] as SpatialGradientDTO[]),
        SlewRatePerAxis: machine?.SlewRatePerAxis || 0,
        MaxSlewRate: machine?.MaxSlewRate || '',
        MaxSAR: machine?.MaxSAR || ([] as MaxSARDTO[]),
        MaxB1rms: machine?.MaxB1rms || '',
        TRCoils: machine?.TRCoils || '',
    };

    return (
        <div className="w-[100%] md:w-3/4 flex  justify-center items-center ">
            <div className="flex flex-col w-[90%]  md:w-3/4 justify-center">
                <h1>Create Room</h1>
                {(machine || id === 'create-new-modal') && (
                    <MRIMachineForm MRImachine={Mrimachine} isNew={true} />
                )}
            </div>
        </div>
    );
};

export default AddMRIMachine;

import LoadingRing from '../ui-components/LoadingRing';
import styles from './Button.module.css';

export enum ButtonType {
    action = 'action',
    dept = 'dept',
    submit = 'submit',
    primary = 'primary',
    secondary = 'secondary',
    tietary = 'tietary',
    cancel = 'cancel',
}

const Button: React.FC<{
    value?: any;
    onClick: Function;
    className?: string;
    type: ButtonType;
    small?: boolean;
    loading?: boolean;
    children?: React.ReactNode;
    disabled?: boolean;
    inline?: boolean;
}> = ({
    value,
    onClick,
    children,
    className,
    type,
    small,
    loading = false,
    disabled = false,
    inline = false,
}) => {
    // adjustButton is an optional property used to adjust the button to have a certain position in a form where only one field and a submit button have to be aligned horizontally
    const click = (e: any, value: any) => {
        e.preventDefault();
        onClick(e, value);
    };

    let allStyle = styles.all;

    if (small) {
        allStyle = styles.small;
    }

    return (
        <button
            className={`${className} ${allStyle} ${styles[type]} ${
                loading ? styles.loading : ''
            } ${loading ? styles.loading : ''} ${
                inline ? styles.inlineButton : ''
            } ${disabled ? styles.disabled : ''}`}
            onClick={(e) => click(e, value)}
            disabled={disabled}
        >
            {loading && <LoadingRing size="small" />}
            {!loading && children}
        </button>
    );
};

export default Button;

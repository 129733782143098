import { Label } from '../../../components/ui/label';
import { useCPTReportContext } from '../../../store/cptReport-context';
import { v4 as uuidv4 } from 'uuid';
import styles from './TimeLog.module.css';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TimeLogItem from './TimeLogItem';
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '../../../components/ui/dialog';
import { Button } from '../../ui/button';
import { useEffect, useState } from 'react';

const TimeLog = () => {
    const { report, dispatchReport } = useCPTReportContext();

    const handleAddAction = () => {
        dispatchReport({
            type: 'UPDATE',
            payload: {
                name: 'timeLog',
                value: [
                    ...(report.timeLog || []),
                    {
                        id: uuidv4(),
                        task: '',
                        time: null,
                    },
                ],
            },
        });
    };

    useEffect(() => {
        setError([]);
        setErrMessages([]);
    }, [report.timeLog]);

    const [error, setError] = useState([]);
    const [errMessages, setErrMessages] = useState([]);

    const handleSave = () => {
        const errors = [];
        const errMesgs: string[] = [];
        for (let log of report.timeLog) {
            if (log.time === null) {
                errors.push(log.id);
                if (
                    !errMesgs.includes(
                        'Please fill in all fields or delete empty ones.'
                    )
                ) {
                    errMesgs.push(
                        'Please fill in all fields or delete empty ones.'
                    );
                }
            }
            if (log.task === '' || log.task === 'Task') {
                errors.push(log.id);
                if (!errMesgs.includes('Please select a task.')) {
                    errMesgs.push('Please select a task.');
                }
            }
        }
        if (errors.length === 0) {
            setOpen(false);
        }
        setError(errors);
        setErrMessages(errMesgs);
    };

    const [open, setOpen] = useState(false);

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger>
                <Button>Log Time</Button>
            </DialogTrigger>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Time Log</DialogTitle>
                    <DialogDescription>
                        Log the time spent on exact tasks during the assessment.
                    </DialogDescription>
                </DialogHeader>
                <div className={styles.actions}>
                    <Label htmlFor="action">Action</Label>
                    {report.timeLog?.map((t) => (
                        <TimeLogItem key={t.id} {...t} />
                    ))}
                    <div className={styles.add}>
                        <Button onClick={handleAddAction}>
                            <FontAwesomeIcon icon={faAdd} /> Add Action
                        </Button>
                    </div>
                </div>
                <Label className="text-red-400">
                    {error.length > 0 &&
                        errMessages.map((message, index) => (
                            <p key={index}>{message}</p>
                        ))}
                </Label>
                <DialogFooter>
                    <Button onClick={handleSave}>Save</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default TimeLog;

import { useContext, useState } from 'react';
import SETTING_KEYS from '../../../constants/setting-keys';
import AuthContext from '../../../store/auth-context';
import RIGHTS_KEYS from '../../../constants/rights-keys';
import Status from '../../general-components/Status';
import styles from './ImplantInfoComponent.module.css';
import NewImplantBadge from '../new-implant-badge/NewImplantBadge';
import { toTitleCase } from '../../../utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowUpRightFromSquare,
    faCircleCheck as solidCheck,
} from '@fortawesome/free-solid-svg-icons';
import MRICode from '../MriCode';
import BookmarkButton from '../BookmarkButton';
import Button, { ButtonType } from '../../general-components/Button';
import { EVENT_KEYS, trackClick } from '../../../services/api/trackingService';
import CPTReportButton from '../../cpt-report/CPTReportButton';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
// import { Button } from '../../ui/Button';

interface HistoryVersion {
    id: string;
    version: string;
    info: any;
    date?: Date;
}

const ImplantInfoComponent: React.FC<{
    info: any;
    isPreview?: boolean;
    historyVersions: HistoryVersion[];
    selectedVersion: any;
    handleChange: any;
    mriCode: any;
}> = ({
    info,
    isPreview = false,
    historyVersions,
    selectedVersion,
    handleChange,
    mriCode,
}) => {
    const ctx = useContext(AuthContext);

    const openIfu = () => {
        trackClick(EVENT_KEYS.clickOpenIFU, {
            implantModel: info[SETTING_KEYS.model],
        });
        window.open(
            'https://files.nordinsight.com/source/' + info['Filename'],
            '_blank'
        );
    };
    const matches = useMediaQuery('(min-width: 968px)');

    return (
        <>
            {info?.title && (
                <div className={styles.info}>
                    <div className={styles.model}>
                        <h2>{info.title}</h2>
                        <Status status={info[SETTING_KEYS.mriStatus]} />
                    </div>
                </div>
            )}
            {info[SETTING_KEYS.model] && (
                <div className={styles.wrapper}>
                    <div className={styles.info}>
                        <div className={styles.newBadge}>
                            <NewImplantBadge
                                createdAt={info[SETTING_KEYS.createdAt]}
                            />
                        </div>
                        <div className={styles.model}>
                            <h2>{info[SETTING_KEYS.model]}</h2>
                            <div className={styles.status}>
                                <Status status={info[SETTING_KEYS.mriStatus]} />
                            </div>
                        </div>
                        <h3>{toTitleCase(info[SETTING_KEYS.manufacturer])}</h3>
                        <h4>
                            {info[SETTING_KEYS.type]?.split(',').join(' |')}
                        </h4>
                    </div>
                    <div className={styles.versions}>
                        {ctx.orRights([RIGHTS_KEYS.detailedInfo]) &&
                            historyVersions.length > 0 && (
                                <div className={styles.versionDropdown}>
                                    <span>Last Version:</span>
                                    <select
                                        className={styles.select}
                                        value={
                                            selectedVersion
                                                ? selectedVersion.id
                                                : ''
                                        }
                                        onChange={handleChange}
                                    >
                                        <option value="">
                                            {
                                                // TODO: 0th or last index for newest versiondate
                                                historyVersions[0].date
                                                    ? new Date(
                                                          historyVersions[0].date
                                                      )?.toLocaleDateString()
                                                    : new Date(
                                                          info.updatedAt
                                                      )?.toLocaleDateString()
                                            }
                                        </option>
                                        {historyVersions.map((version) => (
                                            <option
                                                key={version.id}
                                                value={version.id}
                                            >
                                                {version.version}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}
                        {info[SETTING_KEYS.verifiedByNI] && (
                            <div style={{ color: '#4BC17E' }}>
                                <FontAwesomeIcon icon={solidCheck} />{' '}
                                <span className="">
                                    Last checked:{' '}
                                    {new Date(
                                        info[SETTING_KEYS.verifiedByNI].date
                                    ).toLocaleDateString()}
                                </span>
                            </div>
                        )}
                    </div>
                    <div className={styles.actions}>
                        {info['Filename'] &&
                            (ctx.orRights([RIGHTS_KEYS.viewSource]) ||
                                ctx.getContext().actor?.role ===
                                    'PremiumUser') && (
                                <Button
                                    disabled={
                                        ctx.getContext().actor?.role ===
                                        'PremiumUser'
                                    }
                                    type={ButtonType.action}
                                    onClick={openIfu}
                                >
                                    <FontAwesomeIcon
                                        icon={faArrowUpRightFromSquare}
                                    />
                                    {matches ? 'eIFU ' : ''}
                                </Button>
                            )}

                        {mriCode &&
                            ctx.orRights([RIGHTS_KEYS.advancedSearch]) && (
                                <MRICode code={mriCode} />
                            )}
                        {!isPreview &&
                            ctx.orRights([RIGHTS_KEYS.advancedSearch]) && (
                                <BookmarkButton id={info._id} />
                            )}
                        {ctx.orRights([RIGHTS_KEYS.cptReporting]) && (
                            <CPTReportButton type="new" />
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default ImplantInfoComponent;

import { useState, useEffect } from 'react';
import ConditionalsTable from '../implant/conditionals/ConditionalsTable';
import Button, { ButtonType } from '../general-components/Button';
import Modal from '../organizations/Modal';
import styles from './DepartmentGuests.module.css';
import {
    addGuestToDepartment,
    getGuests,
    removeGuestDepartment,
} from '../../services/api/organizationService';
import FormInput from '../general-components/FormInput';
import Select from '../general-components/Select';
import ConfirmActionButton from '../general/confirmation/ConfirmActionButton';

interface Guest {
    name?: string;
    email: string;
    role: string;
    permissions: string[];
    addedOn?: string;
}

const DepartmentGuests: React.FC<{}> = () => {
    // State for guests list
    const [guests, setGuests] = useState<Guest[]>([]);
    // State for modal visibility
    const [showModal, setShowModal] = useState(false);
    // State for form submission status
    const [isSubmitted, setIsSubmitted] = useState(false);
    // State for loading and error handling
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    // State for new guest with role and permissions
    const [newGuest, setNewGuest] = useState({
        email: '',
        role: '',
        permissions: [] as string[],
    });

    const permLib: { [key: string]: string } = {
        'Can Approve Implants': 'Approve',
        'Can Manage Protocols': 'Manage Protocols',
        'Can Manage Folders': 'Manage Groups',
        'Can Approve CPT Reports': 'Approve CPT Report',
    };

    useEffect(() => {
        const fetchGuests = async () => {
            const guests = await getGuests();
            setGuests(guests);
        };
        fetchGuests();
    }, []);

    // Function to handle adding a new guest
    const handleAddGuest = async () => {
        try {
            setIsLoading(true);
            setError(null);

            // Call the API to add the guest
            const guest = await addGuestToDepartment(
                newGuest.email,
                newGuest.role,
                newGuest.permissions
            );

            setGuests([...guests, newGuest]);
            setIsSubmitted(true);

            // Reset form
            setNewGuest({
                email: '',
                role: '',
                permissions: [],
            });
        } catch (err) {
            setError('Failed to add guest. Please try again.');
            console.error('Error adding guest:', err);
        } finally {
            setIsLoading(false);
        }
    };

    // Function to remove a guest
    const handleRemoveGuest = async (email: string) => {
        try {
            setIsLoading(true);
            setError(null);

            // Call the API to remove the guest
            await removeGuestDepartment(email);

            // Update local state
            setGuests(guests.filter((guest) => guest.email !== email));
        } catch (err) {
            setError('Failed to remove guest. Please try again.');
            console.error('Error removing guest:', err);
        } finally {
            setIsLoading(false);
        }
    };

    // Function to handle permission changes
    const handlePermissionChange = (permission: string) => {
        const permValue = permLib[permission];
        const currentPerms = [...newGuest.permissions];

        if (currentPerms.includes(permValue)) {
            setNewGuest({
                ...newGuest,
                permissions: currentPerms.filter((p) => p !== permValue),
            });
        } else {
            setNewGuest({
                ...newGuest,
                permissions: [...currentPerms, permValue],
            });
        }
    };

    // Function to close modal and reset state
    const closeModal = () => {
        setShowModal(false);
        setIsSubmitted(false);
    };

    // Table headers for the guests list
    const headers = [
        'Name',
        'Email',
        'Role',
        'Permissions',
        'Added On',
        'Actions',
    ];

    // Convert guests to the format expected by ConditionalsTable
    const fields = guests.map((guest) => [
        guest.name,
        guest.email,
        guest.role,
        guest.permissions?.join(', '),
        guest.addedOn,
        <ConfirmActionButton
            buttonText="Remove"
            buttonType={ButtonType.cancel}
            modalInfo={{
                title: 'Remove Guest?',
                message: 'Guest has been removed.',
                submitButtonText: 'Remove',
                submitButtonDanger: true,
            }}
            onConfirm={() => handleRemoveGuest(guest.email)}
        >
            User will no longer have guest access to this department.
        </ConfirmActionButton>,
    ]);

    // Modal configuration
    const modalInfo = {
        title: 'Add Guest to Department',
        message: 'Guest has been added successfully!',
        submitButtonText: 'Add Guest',
        isSubmitted: isSubmitted,
        className: 'add-guest-modal',
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h2>Department Guests</h2>
                <Button
                    type={ButtonType.primary}
                    onClick={() => setShowModal(true)}
                >
                    Add Guest
                </Button>
            </div>

            {guests.length > 0 ? (
                <ConditionalsTable
                    headers={headers}
                    fields={fields}
                    title={`Guests`}
                    style="default"
                />
            ) : (
                <div className={styles.emptyState}>
                    <p>No guests have been added to this department yet.</p>
                </div>
            )}

            {error && <div className={styles.error}>{error}</div>}

            {showModal && (
                <Modal
                    info={{
                        ...modalInfo,
                        submitButtonText: isLoading ? 'Adding...' : 'Add Guest',
                    }}
                    updateView={closeModal}
                    submitModal={handleAddGuest}
                >
                    <>
                        <div className={styles.formGroup}>
                            <FormInput
                                type="email"
                                name="email"
                                label="Email"
                                defaultValue={newGuest.email}
                                onChange={(e) =>
                                    setNewGuest({
                                        ...newGuest,
                                        email: e.target.value,
                                    })
                                }
                            />

                            <FormInput
                                tag="selectRole"
                                name="role"
                                label="Role"
                                defaultValue="default"
                                onSelect={(e) =>
                                    setNewGuest({
                                        ...newGuest,
                                        role: e.target.value,
                                    })
                                }
                            />

                            <div className={styles.permissions}>
                                <label>Permissions</label>
                                <Select
                                    type="checkbox"
                                    name="permissions"
                                    options={Object.keys(permLib)}
                                    defaultChecked={newGuest.permissions.map(
                                        (p) =>
                                            Object.entries(permLib).find(
                                                ([k, v]) => v === p
                                            )?.[0] || ''
                                    )}
                                    changeSelected={handlePermissionChange}
                                />
                            </div>
                        </div>
                    </>
                </Modal>
            )}
        </div>
    );
};

export default DepartmentGuests;

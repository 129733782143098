import { ReactNode } from 'react';
import styles from './Badge.module.css';

const Badge: React.FC<{
    children: ReactNode;
    color: 'white' | 'light-grey' | 'green' | 'red';
}> = ({ children, color }) => {
    return (
        <div className={`${styles.wrapper} ${styles[color]}`}>{children}</div>
    );
};

export default Badge;

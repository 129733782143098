import React from 'react';
import { useCPTReportContext } from '../../store/cptReport-context';
import Button, { ButtonType } from '../general-components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheck,
    faEdit,
    faEye,
    faFileSignature,
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import styles from './CPTReportButtons.module.css';
import { useMediaQuery } from '../../hooks/useMediaQuery';

const CPTReportButton: React.FC<{
    reportId?: string;
    type: 'new' | 'edit' | 'approval' | 'preview';
    isPage?: boolean;
    id?: string;
}> = ({ type, isPage = false, id, reportId }) => {
    const {
        report,
        isReportModuleOpen,
        setReportModuleOpen,
        setReportById,
        initNewReport,
        setViewState,
    } = useCPTReportContext();
    const navigate = useNavigate();

    const matches = useMediaQuery('(min-width: 968px)');

    const handleClick = async () => {
        switch (type) {
            case 'new':
                initNewReport();
        }
        if (isPage) {
            navigate('/cpt-report/new');
        } else {
            setReportModuleOpen(!isReportModuleOpen);
        }
        if (id) {
            await setReportById(id);
        }
        setViewState(type);
    };

    const buttonStyleFromName = (name: string) => {
        switch (name) {
            case 'approval':
                return ButtonType.dept;
            case 'edit':
                return ButtonType.action;
            case 'preview':
                return ButtonType.tietary;
            default:
                return ButtonType.action;
        }
    };

    return (
        <Button
            className={type === 'preview' ? styles.preview : ''}
            small={type !== 'preview' && type !== 'new'}
            onClick={handleClick}
            type={buttonStyleFromName(type)}
        >
            {type === 'new' && (
                <>
                    <FontAwesomeIcon icon={faFileSignature} />
                    {matches ? 'Start New Report' : 'New Report'}
                </>
            )}
            {type === 'approval' && (
                <>
                    <FontAwesomeIcon icon={faCheck} />
                    Approve
                </>
            )}
            {type === 'preview' && <>{reportId}</>}
            {type === 'edit' && (
                <>
                    <FontAwesomeIcon icon={faEdit} />
                    Edit
                </>
            )}
        </Button>
    );
};

export default CPTReportButton;

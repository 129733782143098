import React, { useEffect, useState } from 'react';
import { useCPTReportContext } from '../../../store/cptReport-context';
import styles from './ReportProgress.module.css';

const ReportProgress: React.FC = () => {
    const { progress, report, setProgress, dispatchReport } =
        useCPTReportContext();

    const [totalTimeCPTCodes, setTotalTimeCPTCodes] = useState(
        report.summary?.totalTimeCPTCodes || null
    );
    useEffect(() => {
        setTotalTimeCPTCodes(report.summary?.totalTimeCPTCodes || null);
    }, [report]);

    useEffect(() => {
        if (totalTimeCPTCodes > 15) {
            setProgress({
                ...progress,
                76015: 'In Progress',
            });
            let count15 = Math.ceil((totalTimeCPTCodes - 15) / 30);
            if (count15 > 3) {
                count15 = 3;
            }
            dispatchReport({
                type: 'UPDATE',
                payload: {
                    name: 'summary.count15',
                    value: count15,
                },
            });
        } else {
            setProgress({
                ...progress,
                76015: 'Not Started',
            });

            dispatchReport({
                type: 'UPDATE',
                payload: {
                    name: 'summary.count15',
                    value: null,
                },
            });
        }
    }, [totalTimeCPTCodes]);

    const getStatusEmoji = (status: string) => {
        switch (status) {
            case 'Not Started':
                return '🤷🏼‍♂️ ';
            case 'In Progress':
                return '🔄 ';
            case 'Finished':
                return '✅ ';
            default:
                return '';
        }
    };

    return (
        <div className={styles.container}>
            <h4>Progress</h4>

            {Object.entries(progress).map(([key, status]) => {
                let statusClass = '';
                switch (status) {
                    case 'Not Started':
                        statusClass = styles.notStarted;
                        break;
                    case 'In Progress':
                        statusClass = styles.inProgress;
                        break;
                    case 'Finished':
                        statusClass = styles.finished;
                        break;
                    default:
                        break;
                }
                return (
                    <div key={key} className={`${styles.item} ${statusClass}`}>
                        <span>{getStatusEmoji(status)}</span>
                        <span>{key}</span>
                        {key === '76015' && report.summary.count15 > 0 && (
                            <span>: {report.summary.count15}</span>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default ReportProgress;

import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import styles from './ConditionalsTable.module.css';
import ConditionsTip from './ConditionsTip';
import SeeMoreHoverCard from '../../ui/hover-card/SeeMoreHoverCard';

const ConditionalsTable: React.FC<{
    style?: 'info' | 'default' | 'critical';
    sizes?: number[];
    title?: string;
    headers: (string | ReactNode)[];
    children?: ReactNode;
    fields?: (string | ReactElement)[][];
    hasSeeMore?: boolean;
    isInfo?: boolean;
}> = ({
    headers,
    title,
    children,
    fields,
    sizes,
    style = 'default',
    hasSeeMore = true,
    isInfo,
}) => {
    const sizeSum =
        sizes !== undefined ? sizes.reduce((a, c) => a + c, 0) : headers.length;

    const width = (i: number) => {
        // Default to even width
        const s = sizes !== undefined ? sizes[i] : sizeSum;
        return {
            width: s * (100 / sizeSum) + '%',
        };
    };

    const [showReadMore, setShowReadMore] = useState(false);

    const pRef = useRef<HTMLParagraphElement>(null);

    useEffect(() => {
        if (pRef.current) {
            const lineHeight = parseInt(
                window.getComputedStyle(pRef.current).lineHeight
            );
            const height = pRef.current.offsetHeight;

            setShowReadMore(height > 4 * lineHeight);
        }
    }, []);

    return (
        <div className={styles[style]}>
            <div className={styles.wrapper}>
                <div className={styles.headers}>
                    {headers.map((h, index) => (
                        <div className={styles.headerCell} style={width(index)}>
                            {h}
                        </div>
                    ))}
                </div>
                <div className={styles.fields}>
                    {fields.map((row) => (
                        <div className={styles.row}>
                            {row.map((cell, index) => {
                                if (
                                    hasSeeMore &&
                                    row.length > 1 &&
                                    index === row.length - 1 &&
                                    cell?.toString().length > 100 / row.length
                                ) {
                                    return (
                                        <div
                                            className={styles.cell}
                                            style={width(index)}
                                        >
                                            {/* <SeeMoreHoverCard
                                                title={headers[index] as string}
                                                content={{
                                                    [headers[index] as string]:
                                                        cell,
                                                }}
                                            /> */}
                                            <SeeMoreHoverCard
                                                title={title}
                                                content={row.reduce<object>(
                                                    (obj, c, idx) => {
                                                        return {
                                                            ...obj,
                                                            [(
                                                                headers as string[]
                                                            )[idx]]: c,
                                                        };
                                                    },
                                                    {}
                                                )}
                                            />
                                            {/* <ConditionsTip
                                                placeholder="See more"
                                                title={title}
                                                content={row.reduce<object>(
                                                    (obj, c, idx) => {
                                                        return {
                                                            ...obj,
                                                            [(
                                                                headers as string[]
                                                            )[idx]]: c,
                                                        };
                                                    },
                                                    {}
                                                )}
                                            /> */}
                                        </div>
                                    );
                                } else if (typeof cell === 'string') {
                                    return (
                                        <div
                                            className={styles.cell}
                                            style={width(index)}
                                            dangerouslySetInnerHTML={{
                                                __html: cell,
                                            }}
                                        ></div>
                                    );
                                } else {
                                    return (
                                        <div
                                            className={styles.cell}
                                            style={width(index)}
                                        >
                                            {isInfo && showReadMore && (
                                                <input
                                                    className={
                                                        styles.trancateInput
                                                    }
                                                    type="checkbox"
                                                    id={`trancate-${index}`}
                                                    tabIndex={-1}
                                                    aria-hidden="true"
                                                />
                                            )}
                                            <div
                                                className={
                                                    showReadMore
                                                        ? styles.trancateTextWrapper
                                                        : ''
                                                }
                                            >
                                                <p ref={pRef}>{cell}</p>
                                            </div>
                                            {isInfo && showReadMore && (
                                                <label
                                                    className={
                                                        styles.trancateLabel
                                                    }
                                                    htmlFor={`trancate-${index}`}
                                                ></label>
                                            )}
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ConditionalsTable;

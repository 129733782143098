import { Environments, initializePaddle, Paddle } from '@paddle/paddle-js';
import { useEffect, useRef, useState } from 'react';
import Button, { ButtonType } from '../general-components/Button';

export default function PaddleCheckout({
    priceId,
    children,
    onClose,
    beforeOpen,
}: {
    priceId: string;
    children: React.ReactNode;
    beforeOpen: () => Promise<{
        canOpen: boolean;
        customerId?: string;
        email?: string;
    }>;
    onClose: (paddleStatus: string) => void;
}) {
    // Create a local state to store Paddle instance
    const paddleRef = useRef<Paddle>();
    const [isLoading, setIsLoading] = useState(false);
    // Download and initialize Paddle instance from CDN
    useEffect(() => {
        setIsLoading(true);
        initializePaddle({
            environment: process.env.REACT_APP_PADDLE_ENV as Environments,
            token: process.env.REACT_APP_PADDLE_TOKEN as string,
            eventCallback: function (data) {
                if (data.name == 'checkout.completed') {
                    setTimeout(() => {
                        paddleRef.current?.Checkout.close();
                        onClose('completed');
                        setIsLoading(false);
                    }, 2000);
                }
                if (data.name == 'checkout.closed') {
                    onClose('closed');
                    setIsLoading(false);
                }
            },
        }).then((paddleInstance: Paddle | undefined) => {
            if (paddleInstance) {
                paddleRef.current = paddleInstance;
                setIsLoading(false);
            }
        });
    }, []);

    // Callback to open a checkout
    const openCheckout = async () => {
        setIsLoading(true);
        const { canOpen, customerId, email } = await beforeOpen();
        if (canOpen) {
            paddleRef.current?.Checkout.open({
                customer:
                    customerId !== ''
                        ? {
                              id: customerId,
                          }
                        : {
                              email,
                          },
                items: [{ priceId, quantity: 1 }],
            });
        } else {
            setIsLoading(false);
        }
    };

    return (
        <Button
            loading={isLoading}
            small
            onClick={openCheckout}
            type={ButtonType.primary}
        >
            {children}
        </Button>
    );
}

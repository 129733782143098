import React, { useState, useEffect } from 'react';
import SearchFilter from './SearchFilter';
import { getSearchFilters } from '../../services/apiService';
import SETTING_KEYS from '../../constants/setting-keys';
import styles from './ResultFilters.module.css';

const ResultFilter: React.FC<{
    term: string;
    defaultActiveFilters: { [key: string]: string[] };
    onChange: (filt: { [key: string]: string[] }) => void;
}> = ({ term, defaultActiveFilters, onChange }) => {
    const [manufacturers, setManufacturers] = useState([]);
    const [selectedManufacturers, setSelectedManufacturers] = useState(
        defaultActiveFilters[SETTING_KEYS.manufacturer] || []
    );

    const [types, setTypes] = useState([]);
    const [selectedTypes, setSelectedTypes] = useState(
        defaultActiveFilters[SETTING_KEYS.type] || []
    );

    const [bodyPlacement, setBodyPlacement] = useState([]);
    const [selectedBodyPlacement, setSelectedBodyPlacement] = useState(
        defaultActiveFilters[SETTING_KEYS.bodyPlacement] || []
    );

    const fetchFilters = async () => {
        const filters = await getSearchFilters(term, {
            [SETTING_KEYS.type]: selectedTypes,
            [SETTING_KEYS.manufacturer]: selectedManufacturers,
            [SETTING_KEYS.bodyPlacement]: selectedBodyPlacement,
        });

        setManufacturers(filters[SETTING_KEYS.manufacturer]);
        setTypes(filters[SETTING_KEYS.type]);
        setBodyPlacement(filters[SETTING_KEYS.bodyPlacement]);
    };

    useEffect(() => {
        fetchFilters();
    }, [term, selectedManufacturers, selectedTypes, selectedBodyPlacement]);

    const handleManufacturerChange = (value: string[]) => {
        onChange({
            [SETTING_KEYS.bodyPlacement]: selectedBodyPlacement,
            [SETTING_KEYS.type]: selectedTypes,
            [SETTING_KEYS.manufacturer]: value,
        });
        setSelectedManufacturers(value);
    };

    const handleTypeChange = (value: string[]) => {
        onChange({
            [SETTING_KEYS.type]: value,
            [SETTING_KEYS.manufacturer]: selectedManufacturers,
            [SETTING_KEYS.bodyPlacement]: selectedBodyPlacement,
        });
        setSelectedTypes(value);
    };

    const handleBodyPlacementChange = (value: string[]) => {
        onChange({
            [SETTING_KEYS.bodyPlacement]: value,
            [SETTING_KEYS.manufacturer]: selectedManufacturers,
            [SETTING_KEYS.type]: selectedTypes,
        });
        setSelectedBodyPlacement(value);
    };

    return (
        <div className={styles.wrapper}>
            <h2>Filter Search</h2>
            <p>
                Filter your search by selecting one or more types and
                manufactures
            </p>
            <SearchFilter
                title="Manufacturers"
                values={manufacturers}
                defaultSelected={selectedManufacturers}
                onChange={handleManufacturerChange}
            />
            <SearchFilter
                title="Types"
                values={types}
                defaultSelected={selectedTypes}
                onChange={handleTypeChange}
            />
            <SearchFilter
                title="Body Placement"
                values={bodyPlacement}
                defaultSelected={selectedBodyPlacement}
                onChange={handleBodyPlacementChange}
            />
        </div>
    );
};

export default ResultFilter;

import { useEffect, useState, useContext } from 'react';
import {
    deleteMRIMachine,
    getAllMRIMachinesByDepartment,
} from '../../services/apiService';
import AuthContext from '../../store/auth-context';
import RIGHTS_KEYS from '../../constants/rights-keys';
import LoadingRing from '../ui-components/LoadingRing';
import OverlayWindow from '../ui-components/OverlayWindow';
import AddMRIMachine from './AddMRIMachine';
import MRIMachine from './MRIMachine';
import Button, { ButtonType } from '../general-components/Button';
import DeleteMRIMachine from './DeleteMRIMachine';
import styles from './RoomOverview.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import RoomTable from '../ui/testTables/RoomTable';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { RoomOverviewAccordion } from '../ui/accordion/RoomOverviewAccordion';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { AddMachine } from './AddMachine';
import ResponsiveModal from '../ui/responsive-modal';
import { Button as UIButton } from '../ui/button';
import { PlusSquare } from 'lucide-react';

const RoomOverview = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [machinesList, setMachinesList] = useState([]);
    const [machineIdToEdit, setMachineIdToEdit] = useState('');
    const context = useContext(AuthContext);
    const matches = useMediaQuery('(min-width: 768px)');

    const getData = async () => {
        setIsLoading(true);
        const res = await getAllMRIMachinesByDepartment();
        setMachinesList(res);
        setIsLoading(false);
    };

    const handleDeleteMachine = async (machineId: string) => {
        const res = await deleteMRIMachine(machineId);
        console.log(res);
        getData();
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className={styles.wrapper}>
            <div className={styles.titleWrapper}>
                <h2>Room overview</h2>
                {context.orRights([RIGHTS_KEYS.manageMRIOverview]) && (
                    <>
                        {/* <AddMachine /> */}
                        <UIButton
                            onClick={() =>
                                (window.location.href =
                                    '/room-overview/create-room')
                            }
                        >
                            <PlusSquare />
                            {matches ? 'Add MRIMachine' : 'Add'}
                        </UIButton>
                    </>
                )}
            </div>

            {machineIdToEdit !== '' && (
                <OverlayWindow
                    close={() => {
                        setMachineIdToEdit('');
                        getData();
                    }}
                >
                    <MRIMachine machineId={machineIdToEdit} />
                </OverlayWindow>
            )}
            {!isLoading && machinesList.length === 0 && (
                <h3>No MRIMachines found</h3>
            )}
            {isLoading && (
                <div className={styles.loading}>
                    <LoadingRing size="large" />
                </div>
            )}
            {machinesList?.map((machine) => (
                <div className={styles.roomItem} key={machine._id}>
                    {matches ? (
                        <>
                            <RoomTable
                                machine={machine}
                                setMachineIdToEdit={setMachineIdToEdit}
                                onDelete={handleDeleteMachine}
                            />
                        </>
                    ) : (
                        <>
                            <RoomOverviewAccordion
                                machine={machine}
                                setMachineIdToEdit={setMachineIdToEdit}
                                onDelete={handleDeleteMachine}
                            />
                        </>
                    )}
                </div>
            ))}
        </div>
    );
};

export default RoomOverview;

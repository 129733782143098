import { instance, GETREQUESTOPTIONS } from '../apiService';

export interface ReportInfo {
    name: string;
    facilityName: string;
    dateOfAssessment: Date;
    dateOfService: Date;
    purpose: string;
    sources: string[];
    notes?: string;
}

export interface SafetyWork {
    clinicalIndication?: string;
    purpose: string;
    objectives: string;
}

export interface MRISafetyDecisionInfo {
    implantDetails: {
        model: string;
        catalogNumber: string;
        type: string;
        manufacturer: string;
    };
    foreignBodyDetails: {
        type: string;
        location: string;
        description: string;
        material: string;
        hazards: string[];
        hazardExplanation: string;
        dimensions: string;
    };
    safetyAnalysis: {
        risk: string;
        mitigation: string;
        limitSar: string;
        sequenceMod: string;
        additionalMod: string[];
        staffNeeded: string[];
    };
}

export interface ConditionalsAnalysis {
    mriStatus: string;
    restrictions: string;
    riskLevel: 'low' | 'medium' | 'high' | '';
    benefits: {
        benefits: string;
        conclusion: string;
        explanation: string;
    };
}

export interface Guidance {
    sopName: string;
    sopInfo: {
        Title: string;
        Author: string;
        Date: Date;
    };
    sources: string[];
    explanation: string;
    recommendation: string;
    notes: string;
}

export interface ScannerSettings {
    scannerModel: string;
    technique: string;
    patientPosition: string;
}

export interface Summary {
    codes: string[];
    count15?: number;
    totalTimeCPTCodes: number;
    notes: string;
}

export interface TimeLog {
    id: string;
    task: string;
    time: number;
}

export interface CPTReportDTO {
    _id?: string;
    reportId?: string;
    status: string;
    info: ReportInfo;
    safetyWork: SafetyWork;
    mriSafetyDecisionInfo: MRISafetyDecisionInfo;
    conditionalsAnalysis: ConditionalsAnalysis;
    guidance: Guidance;
    scannerSettings: ScannerSettings;
    summary: Summary;
    department_id: string;
    approval?: {
        approved: boolean;
        date: Date;
        by: {
            name: string;
        };
        notes: string;
    };
    timeLog: TimeLog[];
}

export const createCPTReport = async (data: any) => {
    return (await instance.post('/api/cpt-report', data, GETREQUESTOPTIONS()))
        .data;
};

export const getCPTReportById = async (id: string): Promise<CPTReportDTO> => {
    const res = await instance.get(
        `/api/cpt-report/${id}`,
        GETREQUESTOPTIONS()
    );
    console.log(typeof res.data.createdAt);
    return res.data;
};

export const updateCPTReport = async (id: string, data: any) => {
    return instance.put(`/api/cpt-report/${id}`, data, GETREQUESTOPTIONS());
};

export const approveCPTReport = async (id: string, notes: string) => {
    return instance.put(
        `/api/cpt-report/approve/${id}`,
        { notes },
        GETREQUESTOPTIONS()
    );
};

export const deleteCPTReport = async (id: string) => {
    return instance.delete(`/api/cpt-report/${id}`, GETREQUESTOPTIONS());
};

export const getDeptCPTReports = async () => {
    const res = await instance.get('/api/cpt-report', GETREQUESTOPTIONS());
    return res.data;
};

export const getCPTReportsByDepartment = async (departmentId: string) => {
    return instance.get(
        `/api/cpt-report/department/${departmentId}`,
        GETREQUESTOPTIONS()
    );
};

export const getBenefitsFromClinicalIndication = async (indication: string) => {
    const res = await instance.post(
        `/api/cpt-report/benefits`,
        { indication },
        GETREQUESTOPTIONS()
    );
    if (res.status === 200) {
        return res.data;
    } else {
        return '';
    }
};

export const getRiskFromDetails = async (details: string) => {
    const res = await instance.post(
        `/api/cpt-report/risk`,
        { details },
        GETREQUESTOPTIONS()
    );
    if (res.status === 200) {
        return res.data;
    } else {
        return '';
    }
};

import SignupLayout from '../ui-components/SignupLayout';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { validateSubscription } from '../../services/api/subscriptionService';
import { ConfirmedSignup } from './SignupPage';
import Cookies from 'js-cookie';

const SignupSuccess = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [success, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const user = searchParams.get('user');

        if (user === null) {
            window.location.href = '/';
        } else {
            validate(user);
        }
    }, [searchParams]);

    const validate = async (userId: string) => {
        const { status } = await validateSubscription(userId);
        if (status === 'active') {
            Cookies.remove('checkout');
            setSuccess(true);
        }
        setIsLoading(false);
    };

    return (
        <>
            {!isLoading && (
                <SignupLayout>
                    {success && (
                        <div>
                            <ConfirmedSignup />
                        </div>
                    )}
                    {!success && (
                        <h3 style={{ width: '400px' }}>
                            Sorry, something went wrong during your payment.
                            <br />
                            <br /> Please contact us at info@nordinsight.com to
                            help resolve the issue.
                        </h3>
                    )}
                </SignupLayout>
            )}
        </>
    );
};

export default SignupSuccess;

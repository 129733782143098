import FormInput from '../../general-components/FormInput';
import Select from '../../general-components/Select';
import { useCPTReportContext } from '../../../store/cptReport-context';
import styles from '../ReportWizard.module.css';
import { autofill, getNestedValue } from '../../../services/cptAutofillService';
import Button, { ButtonType } from '../../general-components/Button';

const Benefits = () => {
    const {
        report,
        getHandleSelectChange,
        getHandleEventChange,
        fetchBenefits,
        isFetchingBenefits,
        canFetchBenefits,
    } = useCPTReportContext();

    return (
        <div className={styles.form}>
            <div className={styles.row}>
                <div className={styles.formField}>
                    <div className={styles.formFieldHeader}>
                        <label>Benefits</label>
                        <Button
                            small
                            type={ButtonType.primary}
                            onClick={fetchBenefits}
                            loading={isFetchingBenefits}
                            disabled={!canFetchBenefits()}
                        >
                            Generate with AI
                        </Button>
                        {!canFetchBenefits() &&
                            report.conditionalsAnalysis.benefits.benefits ===
                                '' && (
                                <span
                                    style={{
                                        fontSize: '12px',
                                        opacity: 0.8,
                                    }}
                                >
                                    (Need to fill "Clinical Indication" on
                                    previous page)
                                </span>
                            )}
                    </div>
                    <FormInput
                        tag="textArea"
                        name="benefits"
                        defaultValue={
                            report.conditionalsAnalysis.benefits.benefits
                        }
                        onChange={getHandleEventChange(
                            'conditionalsAnalysis.benefits.benefits'
                        )}
                    />
                </div>
            </div>
            <div className={styles.row}>
                <div>
                    <h5>Conclusion</h5>
                    <Select
                        name="conclusion"
                        type="radio"
                        defaultChecked={[
                            report.conditionalsAnalysis.benefits.conclusion,
                        ]}
                        options={Object.keys(
                            getNestedValue(
                                autofill,
                                'conditionalsAnalysis.benefits.conclusion'
                            )
                        )}
                        changeSelected={getHandleSelectChange(
                            'conditionalsAnalysis.benefits.conclusion',
                            'single',
                            'conditionalsAnalysis.benefits.explanation'
                        )}
                    />
                </div>
            </div>
            <div className={styles.row}>
                <FormInput
                    label="Explanation"
                    tag="textArea"
                    name="explanation"
                    defaultValue={
                        report.conditionalsAnalysis.benefits.explanation
                    }
                    onChange={getHandleEventChange(
                        'conditionalsAnalysis.benefits.explanation'
                    )}
                />
            </div>
        </div>
    );
};

export default Benefits;
